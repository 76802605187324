import React from "react";
import { useLoading } from "../../hooks/LoadingContext";

const LoadingOverlay = () => {
  const { loading } = useLoading();

  return (
    <div
      className={`fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center transition-opacity duration-300 ${
        loading ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div className="w-16 h-16 border-4 border-t-4 border-t-blue-500 rounded-full animate-spin"></div>
    </div>
  );
};

export default LoadingOverlay;
