import React from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import Profile from "../components/Profile";
import { useAuth } from "../hooks/useAuth";
import { useForm } from "react-hook-form";
import CakraInput from "../components/CakraInput";
import { HELPDESK, TA } from "../lib/constant";
import ShiftForm from "../components/ShiftForm";

const Pengaturan = () => {
  const { user } = useAuth();
  const { register, handleSubmit } = useForm();

  return (
    <div className="min-h-screen">
      <div className="border-b-2 flex justify-between px-5">
        <div className="py-5">
          <h1>Pengaturan Akun</h1>
        </div>
      </div>
      <div className="p-5">
        <Tabs>
          <TabList>
            <Tab>Profil Saya</Tab>
            {user?.role === TA ? <Tab>Alamat</Tab> : null}
            {user?.role === HELPDESK ? <Tab>Shift</Tab> : null}
          </TabList>

          <TabPanels>
            <TabPanel>
              <Profile />
            </TabPanel>
            {user?.role === TA ? (
              <TabPanel>
                <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                  <GridItem w="100%" colSpan={2} h="100%">
                    <CakraInput
                      label="Alamat"
                      placeholder="alamat"
                      {...register("address")}
                    />
                    <CakraInput
                      label="Kota"
                      placeholder="kota"
                      {...register("city")}
                    />
                    <CakraInput
                      label="Provinsi"
                      placeholder="provinsi"
                      {...register("province")}
                    />
                    <CakraInput
                      label="Kode pos"
                      placeholder="kode pos"
                      {...register("postalCode")}
                    />
                  </GridItem>
                </Grid>
              </TabPanel>
            ) : null}

            {user?.role === HELPDESK ? (
              <TabPanel>
                <ShiftForm />
              </TabPanel>
            ) : null}
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default Pengaturan;
