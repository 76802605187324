import React from "react";
import Login from "../pages/Login";
import AdminPanel from "../pages/Layout/AdminPanel";
import DashboardHelpdesk from "../pages/helpdesk/Dashboard";
import TaTiket from "../pages/travelAgent/TaTiket";
import TaFormTiket from "../pages/travelAgent/TaFormTiket";
import FormPanel from "../pages/Layout/FormPanel";
import Pengaturan from "../pages/Pengaturan";
import HdFormTiket from "../pages/helpdesk/PembelianTiket";
import RegisterTa from "../pages/sales/RegisterTa";
import SalesDashboard from "../pages/sales/Dashboard";
import SalesTravelAgent from "../pages/sales/TravelAgent";
import SalesRateCard from "../pages/sales/RateCard";
import TaDetail from "../pages/sales/TaDetail";
import TaPartnership from "../pages/travelAgent/Partnership";
import SalesTicket from "../pages/sales/PembelianTicket";
import ReservasiHelpdesk from "../pages/helpdesk/ReservasiHelpdesk";
import ScanQr from "../pages/helpdesk/ScanQr";
import NotificationPage from "../pages/NotificationPage";
import Report from "../pages/Report";
import MasterAccounts from "../pages/admin/MasterAccounts";
import MasterSkuPrice from "../pages/admin/MasterSkuPrice";
import Notes from "../pages/helpdesk/Notes";
import AccountingReport from "../pages/accounting/AccountingReport";
import MasterApprover from "../pages/admin/MasterApprover";

const routesConfig = [
  {
    path: "/travel-agent",
    element: <AdminPanel />,
    roles: ["travelAgent"],
    children: [
      {
        path: "pembelian-tiket",
        element: <TaTiket />,
      },
      {
        path: "pembelian-tiket/form/:uuid",
        element: (
          <FormPanel
            linkTo="/travel-agent/pembelian-tiket"
            title={"Buat Pemesanan Lounge"}
          >
            <TaFormTiket />
          </FormPanel>
        ),
      },
      {
        path: "partnership",
        element: <TaPartnership />,
      },
      {
        path: "laporan",
        element: <Report />,
      },
      {
        path: "pengaturan",
        element: <Pengaturan />,
      },
      // Add more admin routes here
    ],
  },
  {
    path: "/helpdesk",
    element: <AdminPanel />,
    roles: ["helpdesk"],
    children: [
      { path: "dashboard", element: <DashboardHelpdesk /> },
      {
        path: "scanQr",
        element: <ScanQr />,
      },
      {
        path: "pembelian-tiket",
        element: (
          <FormPanel title={"Buat Pemesanan Lounge"}>
            <HdFormTiket />
          </FormPanel>
        ),
      },
      {
        path: "reservation-check",
        element: <ReservasiHelpdesk />,
      },
      {
        path: "notes",
        element: (
          <FormPanel title={"Catatan Front Office"}>
            <Notes />
          </FormPanel>
        ),
      },
      {
        path: "pengaturan",
        element: <Pengaturan />,
      },
      {
        path: "laporan",
        element: <Report />,
      },
      // Add more editor routes here
    ],
  },
  {
    path: "/tour-leader",
    element: <AdminPanel />,
    roles: ["tourLeader"],
    children: [
      { path: "dashboard", element: <div>user dashboard</div> },
      {
        path: "pembelian-tiket",
        element: <div>Pembelian Ticket Dashboard</div>,
      },
      // Add more user routes here
    ],
  },
  {
    path: "/sales",
    element: <AdminPanel />,
    roles: ["sales"],
    children: [
      { path: "dashboard", element: <SalesDashboard /> },
      { path: "pembelian-tiket", element: <SalesTicket /> },
      { path: "travel-agent", element: <SalesTravelAgent /> },
      {
        path: "travel-agent/register",
        element: (
          <FormPanel>
            <RegisterTa />
          </FormPanel>
        ),
      },
      {
        path: "travel-agent/detail/:id",
        element: (
          <FormPanel>
            <TaDetail />
          </FormPanel>
        ),
      },
      // { path: "rate-code", element: <SalesRateCard /> },
      { path: "laporan", element: <Report /> },
      { path: "pengaturan", element: <Pengaturan /> },
      // Add more user routes here
    ],
  },
  {
    path: "/accounting",
    element: <AdminPanel />,
    roles: ["accounting"],
    children: [
      { path: "laporan", element: <AccountingReport /> },
      { path: "pengaturan", element: <Pengaturan /> },
    ],
  },
  {
    path: "/admin",
    element: <AdminPanel />,
    roles: ["admin"],
    children: [
      { path: "dashboard", element: <div>Dashboard</div> },
      { path: "master-account", element: <MasterAccounts /> },
      { path: "master-ticket-price", element: <MasterSkuPrice /> },
      { path: "master-approver", element: <MasterApprover /> },
    ],
  },
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/unauthorized",
    element: <div>Unauthorized</div>,
  },
  {
    path: "/notification",
    element: <NotificationPage />,
  },
];

export default routesConfig;
