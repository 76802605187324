import React, { useEffect, useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import _ from "lodash";

import { CardDetail } from "./CardDetail";
import { IcPencil } from "../../assets/icons/icon";
import { dateFormat, numberToIDR } from "../../lib/utils";
import skeletonImage from "../../assets/skeleteon-img.svg";
import LogTable from "./LogTable";
import {
  ColumnsLogActivity,
  ColumnsLogDeposit,
  ColumnsLogLedger,
  ColumnsLogTransaksi,
} from "./Columns";
import { useParams } from "react-router-dom";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";
import AssignRateCardModal from "./AssignRateCardModal";
import TopupDepositModal from "./TopupDepositModal";
import SearchBar from "../../components/SearchBar";
import moment from "moment";

const LIMIT = 5;

const TaDetail = () => {
  const params = useParams();

  const [transactionQuery, setTransactionQuery] = useState();
  const [transactionDate, setTransactionDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [transactionParams, setTransactionParams] = useState({
    travel_agent_id: params.id,
    limit: LIMIT,
    query: transactionQuery,
    start_date: transactionDate.startDate
      ? dateFormat(transactionDate.startDate)
      : "",
    end_date: transactionDate.endDate
      ? dateFormat(transactionDate.endDate)
      : "",
  });

  const [ledgerQuery, setLedgerQuery] = useState();
  const [ledgerDate, setLedgerDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [ledgerParams, setLedgerParams] = useState({
    travel_agent_id: params.id,
    limit: LIMIT,
    query: ledgerQuery,
    start_date: ledgerDate.startDate ? dateFormat(ledgerDate.startDate) : "",
    end_date: ledgerDate.endDate ? dateFormat(ledgerDate.endDate) : "",
  });

  const [depositQuery, setDepositQuery] = useState();
  const [depositDate, setDepositDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [depositParams, setDepositParams] = useState({
    travel_agent_id: params.id,
    limit: LIMIT,
    query: depositQuery,
    start_date: depositDate.startDate ? dateFormat(depositDate.startDate) : "",
    end_date: depositDate.endDate ? dateFormat(depositDate.endDate) : "",
  });

  const [activityQuery, setActivityQuery] = useState();
  const [activityDate, setActivityDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [activityParams, setActivityParams] = useState({
    travel_agent_id: params.id,
    limit: LIMIT,
    query: activityQuery,
    start_date: activityDate.startDate
      ? dateFormat(activityDate.startDate)
      : "",
    end_date: activityDate.endDate ? dateFormat(activityDate.endDate) : "",
  });

  const { onOpen, isOpen, onClose } = useDisclosure(false);
  const {
    onOpen: onOpenTopup,
    isOpen: isOpenTopup,
    onClose: onCloseTopup,
  } = useDisclosure(false);

  const { loading, data, error, refetch } = useFetchDataDashboard(
    "/api/sales-travel-agents",
    {
      travel_agent_id: params.id,
    }
  );

  useEffect(() => {
    setTransactionParams({
      travel_agent_id: params.id,
      limit: LIMIT,
      query: transactionQuery,
      start_date: transactionDate.startDate
        ? dateFormat(transactionDate.startDate)
        : "",
      end_date: transactionDate.endDate
        ? dateFormat(transactionDate.endDate)
        : "",
    });
  }, [transactionQuery, transactionDate]);

  useEffect(() => {
    setLedgerParams({
      travel_agent_id: params.id,
      limit: LIMIT,
      query: ledgerQuery,
      start_date: ledgerDate.startDate ? dateFormat(ledgerDate.startDate) : "",
      end_date: ledgerDate.endDate ? dateFormat(ledgerDate.endDate) : "",
    });
  }, [ledgerQuery, ledgerDate]);

  useEffect(() => {
    setDepositParams({
      travel_agent_id: params.id,
      limit: LIMIT,
      query: depositQuery,
      start_date: depositDate.startDate
        ? dateFormat(depositDate.startDate)
        : "",
      end_date: depositDate.endDate ? dateFormat(depositDate.endDate) : "",
    });
  }, [depositQuery, depositDate]);

  useEffect(() => {
    setActivityParams({
      travel_agent_id: params.id,
      limit: LIMIT,
      query: activityQuery,
      start_date: activityDate.startDate
        ? dateFormat(activityDate.startDate)
        : "",
      end_date: activityDate.endDate ? dateFormat(activityDate.endDate) : "",
    });
  }, [activityQuery, activityDate]);

  const onToggleModal = () => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  const onToggleTopupModal = () => {
    if (isOpenTopup) {
      onCloseTopup();
    } else {
      onOpenTopup();
    }
  };

  const handleTransaksiDateChange = (date) => {
    setTransactionDate(date);
  };

  const handleLedgerDateChange = (date) => {
    setLedgerDate(date);
  };

  const handleDepositDateChange = (date) => {
    setDepositDate(date);
  };

  const handleActivityDateChange = (date) => {
    setActivityDate(date);
  };

  return (
    <div className="min-h-screen">
      <div className="border-b-2 flex justify-between px-5">
        <div className="py-5">
          <h1>Detail Partnership Travel Agent</h1>
        </div>
      </div>
      <div className="p-5">
        <div className="flex flex-row gap-2 mb-4">
          {/* improvement loading data */}
          <CardDetail
            title={!loading && data?.data[0].name}
            badge={{
              label: !loading && data?.data[0].rate_card,
              colorScheme: "gray",
            }}
            img={skeletonImage}
            button={
              <Button
                size={"xs"}
                variant={"ghost"}
                onClick={(e) => {
                  e.preventDefault();
                  onOpen();
                }}
                px={0}
              >
                <IcPencil className="text-blue-500" size={"24px"} />
              </Button>
            }
          />

          <CardDetail
            title={
              <>
                {`Saldo:`}{" "}
                <span className="text-base font-normal">
                  {!loading && numberToIDR(data.data[0].balance || 0)}
                </span>
              </>
            }
            // badge="silver"
            // handleOnClick={() => {}}
            button={
              <Button
                size={"xs"}
                variant={"solid"}
                colorScheme="green"
                onClick={() => {
                  onOpenTopup();
                }}
                // isDisabled
              >
                Top up deposit
              </Button>
            }
          />
        </div>
        <Tabs isLazy>
          <TabList>
            <Tab>Log Transaksi</Tab>
            <Tab>Log Ledger</Tab>
            <Tab>Log Deposit</Tab>
            <Tab>Rate Code Activity</Tab>
          </TabList>

          <TabPanels>
            <TabPanel p={0} py={2}>
              <div className="flex w-full justify-between my-3">
                <SearchBar
                  query={transactionQuery}
                  setQuery={setTransactionQuery}
                  paramDate={transactionDate}
                  handleDateChange={handleTransaksiDateChange}
                  isSingleDate={false}
                  outerClassName="basis-4/5"
                  innerClassName="basis-3/5"
                  searchPlaceholder="Cari Transaksi"
                  datepickerPlaceholder="Cari Date Transaksi"
                />
              </div>
              <LogTable
                api="/api/report-log-transactions"
                params={transactionParams}
                columns={ColumnsLogTransaksi}
              />
            </TabPanel>
            <TabPanel p={0} py={2}>
              <div className="flex w-full justify-between my-3">
                <SearchBar
                  query={ledgerQuery}
                  setQuery={setLedgerQuery}
                  paramDate={ledgerDate}
                  handleDateChange={handleLedgerDateChange}
                  isSingleDate={false}
                  outerClassName="basis-4/5"
                  innerClassName="basis-3/5"
                  searchPlaceholder="Cari Ledger"
                  datepickerPlaceholder="Cari Date Ledger"
                />
              </div>
              <LogTable
                api="/api/log-ledger"
                columns={ColumnsLogLedger}
                params={ledgerParams}
              />
            </TabPanel>
            <TabPanel p={0} py={2}>
              <div className="flex w-full justify-between my-3">
                <SearchBar
                  query={depositQuery}
                  setQuery={setDepositQuery}
                  paramDate={depositDate}
                  handleDateChange={handleDepositDateChange}
                  isSingleDate={false}
                  outerClassName="basis-4/5"
                  innerClassName="basis-3/5"
                  searchPlaceholder="Cari Deposit"
                  datepickerPlaceholder="Cari Date Deposit"
                />
              </div>
              <LogTable
                api="/api/log-deposit"
                columns={ColumnsLogDeposit}
                params={depositParams}
              />
            </TabPanel>
            <TabPanel p={0} py={2}>
              <div className="flex w-full justify-between my-3">
                <SearchBar
                  query={activityQuery}
                  setQuery={setActivityQuery}
                  paramDate={activityDate}
                  handleDateChange={handleActivityDateChange}
                  isSingleDate={false}
                  outerClassName="basis-4/5"
                  innerClassName="basis-3/5"
                  searchPlaceholder="Cari Activity"
                  withDate={false}
                />
              </div>
              <LogTable
                api="/api/rate-card-activity"
                columns={ColumnsLogActivity}
                params={depositParams}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
      {!loading && data !== null ? (
        <>
          <AssignRateCardModal
            isOpen={isOpen}
            data={data?.data[0] || []}
            onToggleModal={onToggleModal}
            refetch={refetch}
          />
          <TopupDepositModal
            isOpen={isOpenTopup}
            data={data?.data[0] || []}
            onToggleModal={onToggleTopupModal}
            refetch={refetch}
          />
        </>
      ) : null}
    </div>
  );
};

export default TaDetail;
