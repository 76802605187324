import React, { useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { ColumnsReservationHd } from "./Columns";
import DataTable from "../../components/ui/dataTable/DataTable";
import { Input } from "../../components/ui/Input";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";
import { useApiPost } from "../../hooks/useApiPost";
import HdModal from "./HdModal";
import HdModalForm from "./HdModalForm";
import { Link } from "react-router-dom";
import { dateFormat, dateTimeFormat } from "../../lib/utils";
import { useFetchData } from "../../hooks/useFetchData";
import { Clock } from "lucide-react";
import Datepicker from "react-tailwindcss-datepicker";

function ReservasiHelpdesk() {
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [query, setQuery] = React.useState("");
  const [paramDate, setParamDate] = React.useState({
    startDate: moment().format("DD MMM YYYY"),
    endDate: moment().format("DD MMM YYYY"),
  });
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [dataUser, setDataUser] = React.useState([]);
  const [modalDetail, setModalDetail] = React.useState();
  const [modalForm, setModalForm] = React.useState();

  const { loading, data, error, setParam, refetch } = useFetchDataDashboard(
    "/api/helpdesk-reservation-check",
    {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
      date: paramDate.startDate,
    }
  );

  const {
    loading: reservationTicketLoading,
    data: reservationTicketData,
    error: reservationTicketError,
  } = useFetchData("/api/reservation-tickets");

  const toast = useToast();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: formOnOpen,
    isOpen: formIsOpen,
    onClose: formOnClose,
  } = useDisclosure();

  useEffect(() => {
    if (!loading && data) {
      setDataUser(data.data || []);
    }
  }, [data]);

  useEffect(() => {
    setParam({
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
      date: !_.isEmpty(paramDate.startDate)
        ? moment(paramDate.startDate).format("DD MMM YYYY")
        : "",
    });
  }, [pagination, query, paramDate]);

  const onToggleModalDetail = ({ row } = {}) => {
    if (isOpen) {
      setModalDetail(null);
      onClose();
    } else {
      setModalDetail(row);
      onOpen();
    }
  };

  const onToggleModalForm = ({ row } = {}) => {
    if (formIsOpen) {
      setModalForm(null);
      formOnClose();
    } else {
      setModalForm(row);
      formOnOpen();
    }
  };

  const handleDateChange = (date) => {
    setParamDate(date);
  };

  const table = useReactTable({
    data: dataUser,
    columns: ColumnsReservationHd,
    rowCount: data?.total_data || 0, // Set the total rows based on
    // pageCount: Math.ceil(data?.total / pagination.pageSize) || 0, // Set the total pages based on your data
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    onToggleModalDetail: onToggleModalDetail,
    onToggleModalForm: onToggleModalForm,
    state: {
      columnFilters,
      rowSelection,
      pagination,
    },
  });

  return (
    <div className="min-h-screen">
      <div className="border-b-2 flex justify-between px-5">
        <div className="py-5">
          <h1 className="font-medium">
            Selamat datang di Lounge Umroh Terminal 2F
          </h1>
          <p className="flex flex-row text-base items-center gap-1">
            <Clock size={24} /> {dateTimeFormat(moment()) + " WIB"}
          </p>
        </div>
        <div className="flex flex-row space-x-4 text-center m-5">
          <div className="bg-green-100 rounded-lg px-4 py-5">
            <h1 className="font-bold text-4xl">
              {!reservationTicketLoading && reservationTicketData
                ? reservationTicketData.data.verified_ticket
                : "-"}
            </h1>
            <p>Tiket check in</p>
          </div>
          <div className="bg-gray-200 bg-opacity-25 rounded-lg px-4 py-5">
            <h1 className="text-black text-4xl font-bold">
              {!reservationTicketLoading && reservationTicketData
                ? reservationTicketData.data.valid_ticket
                : "-"}
            </h1>
            <p>Tiket untuk hari ini</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between p-5">
        <div className="flex flex-row gap-3 basis-3/5">
          <div className="relative w-full basis-4/5">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <Input
              type="text"
              placeholder="Pencarian nama kelompok, pelanggan"
              onChange={(e) => setQuery(e.target.value)}
              className="ps-10"
            />
          </div>
          <Datepicker
            useRange={false}
            asSingle={true}
            displayFormat="DD MMM YYYY"
            popoverDirection="down"
            inputClassName={
              "bg-inherit  text-black w-full border-inherit rounded-lg"
            }
            placeholder="search reservasi"
            value={paramDate}
            onChange={handleDateChange}
            containerClassName="relative w-full bg-white basis-2/5"
            // selected={field.value}
          />
        </div>
        <div className="flex justify-end gap-1 basis-1/5">
          <Link to={"/helpdesk/pembelian-tiket"}>
            <button
              type="button"
              className="flex w-full h-full gap-1 items-center px-5 border rounded-lg font-semibold bg-white border-tropicGreen500 text-tropicGreen500"
            >
              Beli tiket baru
            </button>
          </Link>
        </div>
      </div>
      <div id="content-table">
        <DataTable
          columns={ColumnsReservationHd}
          table={table}
          loading={loading}
        />
      </div>
      <HdModal
        onToggleModal={onToggleModalDetail}
        isOpen={isOpen}
        data={modalDetail}
      />
      <HdModalForm
        onToggleModal={onToggleModalForm}
        isOpen={formIsOpen}
        data={modalForm}
        refetch={() =>
          refetch({
            page: pagination.pageIndex + 1,
            limit: pagination.pageSize,
            query: query,
            date: !_.isEmpty(paramDate.startDate)
              ? moment(paramDate.startDate).format("DD MMM YYYY")
              : "",
          })
        }
      />
    </div>
  );
}

export default ReservasiHelpdesk;
