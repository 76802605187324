import React, { useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { Button, useDisclosure } from "@chakra-ui/react";
import { ColumnsDashboard, ColumnsSalesTravelAgent } from "./Columns";
import DataTable from "../../components/ui/dataTable/DataTable";
import { Input } from "../../components/ui/Input";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";
import { Link } from "react-router-dom";

const SalesTravelAgent = () => {
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [query, setQuery] = React.useState("");
  const [paramDate, setParamDate] = React.useState();
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [dataUser, setDataUser] = React.useState([]);

  const { loading, data, error, setParam, refetch } = useFetchDataDashboard(
    "/api/sales-travel-agents",
    {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
    }
  );

  const {
    onOpen: formOnOpen,
    isOpen: formIsOpen,
    onClose: formOnClose,
  } = useDisclosure();

  useEffect(() => {
    if (!loading && data) {
      setDataUser(data.data || []);
    }
  }, [data]);

  useEffect(() => {
    setParam({
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
    });
  }, [pagination, query]);

  const onToggleModalForm = ({ row } = {}) => {
    if (formIsOpen) {
      formOnClose();
    } else {
      formOnOpen();
    }
  };

  const onHandleDetail = ({ row } = {}) => {};

  const handleDateChange = (date) => {
    setParamDate(date);
  };

  const table = useReactTable({
    data: dataUser,
    columns: ColumnsSalesTravelAgent,
    rowCount: data?.total_data || 0, // Set the total rows based on
    // pageCount: Math.ceil(data?.total / pagination.pageSize) || 0, // Set the total pages based on your data
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    onHandleDetail: onHandleDetail,
    onToggleModalForm: onToggleModalForm,
    state: {
      columnFilters,
      rowSelection,
      pagination,
    },
  });

  return (
    <div className="min-h-screen">
      <div className="border-b-2 flex justify-between px-5">
        <div className="py-5">
          <h1 className="font-medium">Data Travel Agent</h1>
        </div>
      </div>
      <div className="flex justify-between p-5">
        <div className="flex flex-row gap-3 basis-3/5">
          <div className="relative w-full basis-4/5">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <Input
              type="text"
              placeholder="Pencarian nama kelompok, pelanggan"
              onChange={(e) => setQuery(e.target.value)}
              className="ps-10"
            />
          </div>
        </div>
        <div className="flex justify-end gap-1 basis-1/5">
          <Link to={"/sales/travel-agent/register"}>
            <Button
              type="button"
              colorScheme="green"
              variant="outline"
              bgColor="white"
              className="flex w-full h-full gap-1 items-center px-5 border font-semibold rounded-lg !border-tropicGreen500 !text-tropicGreen500  "
            >
              Registrasi Baru
            </Button>
          </Link>
        </div>
      </div>
      <div id="content-table">
        <DataTable
          columns={ColumnsSalesTravelAgent}
          table={table}
          loading={loading}
        />
      </div>
      {/* <HdModal
        onToggleModal={onToggleModalDetail}
        isOpen={isOpen}
        data={modalDetail}
      />
      <HdModalForm
        onToggleModal={onToggleModalForm}
        isOpen={formIsOpen}
        data={modalForm}
        refetch={() =>
          refetch({
            page: pagination.pageIndex + 1,
            limit: pagination.pageSize,
            query: query,
          })
        }
      /> */}
    </div>
  );
};

export default SalesTravelAgent;
