import React, { useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { Button, useDisclosure, useToast } from "@chakra-ui/react";
import { ColumnsDashboard } from "./Columns";
import DataTable from "../../components/ui/dataTable/DataTable";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";
import { Link, useNavigate } from "react-router-dom";
import { dateTimeFormat } from "../../lib/utils";
import { Clock } from "lucide-react";
import HdModal from "../helpdesk/HdModal";
import SearchBar from "../../components/SearchBar";
import { useSnap } from "../../hooks/useSnap";
import { useApiPost } from "../../hooks/useApiPost";
import ModalConfirmation from "../../components/ModalConfirmation";
import toastHandler from "../../components/toastHandler";
import { CANCELLED } from "../../lib/constant";

const SalesDashboard = () => {
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [query, setQuery] = React.useState("");
  const [paramDate, setParamDate] = React.useState({
    startDate: moment().format("DD MMM YYYY"),
    endDate: moment().format("DD MMM YYYY"),
  });
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [dataUser, setDataUser] = React.useState([]);
  const [modalDetail, setModalDetail] = React.useState();
  const [modalForm, setModalForm] = React.useState();
  const [modalChangeStatus, setModalChangeStatus] = React.useState(null);

  const { loading, data, error, setParam, refetch } = useFetchDataDashboard(
    "/api/sales-transactions",
    {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
      date: paramDate.startDate,
    }
  );

  const {
    handlePost: handlePostChangeStatus,
    loading: loadingChangeStatus,
    data: dataChangeStatus,
    error: errorChangeStatus,
  } = useApiPost();

  const { snap, snapPopup } = useSnap();
  const navigate = useNavigate();

  const toast = useToast();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: formOnOpen,
    isOpen: formIsOpen,
    onClose: formOnClose,
  } = useDisclosure();
  const {
    onOpen: onOpenChangeStatus,
    isOpen: isOpenChangeStatus,
    onClose: onCloseChangeStatus,
  } = useDisclosure();

  useEffect(() => {
    if (!loading && data) {
      setDataUser(data.data || []);
    }
  }, [data]);

  useEffect(() => {
    setParam({
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
      date: paramDate.startDate
        ? moment(paramDate.startDate).format("DD MMM YYYY")
        : "",
    });
  }, [pagination, query, paramDate]);

  useEffect(() => {
    if (!loadingChangeStatus && dataChangeStatus && !errorChangeStatus) {
      onCloseChangeStatus();
    }
    toastHandler({
      data: dataChangeStatus,
      error: errorChangeStatus,
      loading: loadingChangeStatus,
      toast: toast,
      title: "Berhasil refund/cancel transaksi",
    });
  }, [loadingChangeStatus, dataChangeStatus, errorChangeStatus]);

  const onToggleModalDetail = ({ row } = {}) => {
    if (isOpen) {
      setModalDetail(null);
      onClose();
    } else {
      setModalDetail(row);
      onOpen();
    }
  };

  const onToggleModalForm = ({ row } = {}) => {
    if (formIsOpen) {
      setModalForm(null);
      formOnClose();
    } else {
      setModalForm(row);
      formOnOpen();
    }
  };

  const handleDateChange = (date) => {
    setParamDate(date);
  };

  const handleSnapShow = (snapToken) => {
    snapPopup(snapToken, {
      onSuccess: function (result) {
        snap.hide();
        console.log("success", JSON.stringify(result));
      },
      onPending: function (result) {
        snap.hide();
        console.log("pending dashboard", JSON.stringify(result));
      },
      onError: function (result) {
        console.log("error", JSON.stringify(result));
        // snap.hide();
      },
      onClose: function () {
        console.log("customer closed the popup without finishing the payment");
      },
    });
  };

  const handleChangePaymentStatus = () => {
    const { reservationId, typeChangeStatus } = modalChangeStatus || {};

    console.log(modalChangeStatus);
    const payload = {
      reservation_id: reservationId,
      status: typeChangeStatus,
    };

    handlePostChangeStatus("/api/update-status-transaction", payload);
  };

  const onToggleModalChangeStatus = (row) => {
    if (isOpenChangeStatus) {
      setModalChangeStatus(null);
      onCloseChangeStatus();
    } else {
      setModalChangeStatus(row);
      onOpenChangeStatus();
    }
  };

  const table = useReactTable({
    data: dataUser,
    columns: ColumnsDashboard,
    rowCount: data?.total_data || 0, // Set the total rows based on
    // pageCount: Math.ceil(data?.total / pagination.pageSize) || 0, // Set the total pages based on your data
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    onToggleModalDetail: onToggleModalDetail,
    onToggleModalForm: onToggleModalForm,
    handleSnapShow: handleSnapShow,
    handleChangePaymentStatus: handleChangePaymentStatus,
    onToggleModalChangeStatus: onToggleModalChangeStatus,
    state: {
      columnFilters,
      rowSelection,
      pagination,
    },
  });

  return (
    <div className="min-h-screen">
      <div className="border-b-2 flex justify-between px-5">
        <div className="py-5">
          <h1 className="font-medium">
            Selamat datang di Lounge Umroh Terminal 2F
          </h1>
          <p className="flex flex-row text-base items-center gap-1">
            <Clock size={24} /> {dateTimeFormat(moment()) + " WIB"}
          </p>
        </div>
      </div>
      <div className="flex justify-between p-5">
        <SearchBar
          query={query}
          setQuery={setQuery}
          paramDate={paramDate}
          handleDateChange={handleDateChange}
          isRangeDate={false}
        />
        <div className="flex justify-end gap-1 basis-1/5">
          <Link to={"/sales/pembelian-tiket"}>
            <Button
              type="button"
              colorScheme="green"
              variant="outline"
              bgColor="white"
              className="flex w-full h-full gap-1 items-center px-5 border font-semibold !border-tropicGreen500 !text-tropicGreen500 rounded-lg bg-white"
            >
              Pesan lounge
            </Button>
          </Link>
        </div>
      </div>
      <div id="content-table">
        <DataTable columns={ColumnsDashboard} table={table} loading={loading} />
      </div>
      <HdModal
        onToggleModal={onToggleModalDetail}
        isOpen={isOpen}
        data={modalDetail}
      />
      <ModalConfirmation
        onToggleModal={onToggleModalChangeStatus}
        isOpen={isOpenChangeStatus}
        title="Transaksi"
        desc={`Apakah anda yakin ingin ${
          modalChangeStatus?.typeChangeStatus === CANCELLED
            ? "batalkan"
            : "refund"
        } transaksi ini <mark className="bg-yellow-200 text-gray-700">${
          modalChangeStatus?.reservationId
        }</mark> ?`}
        buttonSubmit={
          <Button
            id="pay"
            type="submit"
            colorScheme="bg-green-600"
            size="md"
            className="!text-base !font-semibold text-white bg-green-600"
            onClick={handleChangePaymentStatus}
          >
            {loadingChangeStatus ? "Loading..." : "Setuju"}
          </Button>
        }
      />
    </div>
  );
};

export default SalesDashboard;
