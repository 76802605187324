import { useEffect, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import axios from "axios";
import { useAuth } from "./useAuth";
import { handleError } from "../lib/utils";

export const useFetchData = (url, isNeedFirstLoad = true) => {
  const { user } = useAuth();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { logout } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        if (!res.status === 200) {
          setError({ errMsg: "something when wrong!!" });
        }

        setData(res.data);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          logout();
        }
        setError(handleError(error));
      }
      setLoading(false);
    };

    if (isNeedFirstLoad) {
      fetchData();
    }
  }, []);

  return {
    data,
    loading,
    error,
  };
};

useFetchData.propTypes = {
  url: PropTypes.string.isRequired, // You can adjust the PropTypes according to the type of params you expect
};
