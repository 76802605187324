import React from "react";
import {
  Box,
  Grid,
  GridItem,
  Image,
  Badge,
  Button,
  Stack,
} from "@chakra-ui/react";
import { Card, CardContent } from "../../components/ui/Card";
import skeletonImage from "../../assets/skeleteon-img.svg";
import { Pencil } from "lucide-react";

export const CardDetail = ({
  title,
  badge = null,
  handleOnClick,
  button,
  img = null,
}) => {
  return (
    <Card x-chunk="dashboard-07-chunk-3" className="bg-green-100 w-72 p-4">
      <CardContent className="p-0">
        <Grid
          templateRows="repeat(2, 1fr, 20px)"
          templateColumns="repeat(4, 1fr)"
          gap={4}
        >
          {img ? (
            <GridItem
              rowSpan={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
                position="relative"
              >
                <Image borderRadius="full" src={img} alt="logo" />
              </Box>
            </GridItem>
          ) : null}
          <GridItem colSpan={3}>
            <div className="capitalize font-bold">{title}</div>
          </GridItem>
          <GridItem colSpan={3}>
            <Stack direction="row" className="items-center">
              {badge ? (
                <Badge
                  colorScheme={badge.colorScheme || ""}
                  size={"sm"}
                  variant="solid"
                >
                  {badge.label}
                </Badge>
              ) : null}
              {button}
            </Stack>
          </GridItem>
        </Grid>
      </CardContent>
    </Card>
  );
};
