import React, { createContext, useContext } from "react";

const EnvContext = createContext();

export const EnvProvider = ({ children }) => {
  const env = process.env.REACT_APP_ENV || "production";

  return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
};

export const useEnv = () => useContext(EnvContext);
