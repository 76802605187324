import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { ArrowLeftIcon } from "lucide-react";

const FormPanel = ({ children, title, linkTo = null }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };
  return (
    <>
      <Link to={linkTo ? linkTo : -1}>
        <Button
          leftIcon={<ArrowLeftIcon />}
          border={0}
          backgroundColor={"transparent"}
          textColor={"black"}
          _hover={{ opacity: "70%" }}
          mt={"1.25rem"}
          pl={"1rem"}
        >
          Kembali
        </Button>
      </Link>
      <div className="px-4 border-b-2 pb-3 mb-3 border-gray-200 text-2xl font-medium">
        {title}
      </div>
      {children}
    </>
  );
};

export default FormPanel;
