import React, { useEffect, useState } from "react";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormHelperText,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Datepicker from "react-tailwindcss-datepicker";
import { dateFormat, numberToIDR } from "../../lib/utils";
import { useApiPost } from "../../hooks/useApiPost";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/Card";
import { Label } from "../../components/ui/Label";
import LabelWrapper from "../../components/ui/LabelWrapper";
import { IcBaselineVerified } from "../../components/ui/icon";
import "moment/min/locales";
import CakraInput from "../../components/CakraInput";
import { useFetchPrice } from "../../hooks/useFetchPrice";
import { useAuth } from "../../hooks/useAuth";
import { HELPDESK, SALES, SKU_PREMIUM, TA } from "../../lib/constant";
import ModalConfirmation from "../../components/ModalConfirmation";
import { useFetchData } from "../../hooks/useFetchData";
import toastHandler from "../../components/toastHandler";
import PaymentTypeForm from "../PaymentTypeForm";
import { useSnap } from "../../hooks/useSnap";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";
import { AsyncSelect } from "chakra-react-select";

const SalesTicket = () => {
  const [vipTotalPrice, setVipTotalPrice] = useState(0);
  const [regularTotalPrice, setRegularTotalPrice] = useState(0);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isPaymentForm, setIsPaymentForm] = useState(false);
  const [snapShow, setSnapshow] = useState(false);
  const [taDataOptions, setTaDataOptions] = useState([]);

  const toast = useToast();
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    trigger,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const {
    loading: loadingPrice,
    data: dataPrice,
    error: errorPrice,
    setParam: setPriceParam,
  } = useFetchPrice();

  const {
    handlePost: submitPost,
    loading: submitLoading,
    data: submitData,
    error: submitError,
  } = useApiPost();

  const {
    handlePost: submitDepositPost,
    loading: submitDepositLoading,
    data: submitDepositData,
    error: submitDepositError,
  } = useApiPost();

  const {
    loading: saldoDepositLoading,
    data: saldoDepositData,
    refetch: saldoDepositRefetch,
    setParam: setSaldoDepositParam,
  } = useFetchDataDashboard("/api/deposit");

  const { user } = useAuth();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    loading: taLoading,
    data: taData,
    error: taError,
  } = useFetchData("/api/travel-agents");

  const { snap, snapPopup } = useSnap();
  const [paymentToken, setPaymentToken] = useLocalStorage("paymentToken", null);

  useEffect(() => {
    if (!taLoading && taData) {
      let options = [];
      taData.data.map((item) => {
        options.push({
          value: item.travel_agent_id,
          label:
            item?.name.toLowerCase() !== "umum"
              ? `${item.pic_name} - ${item.name}`
              : item.name,
        });
      });
      setTaDataOptions(options);
      setValue("selectTravelAgent", options[0]);
    }
  }, [taData, taLoading]);

  useEffect(() => {
    if (watch("vip") || watch("regular") || watch("selectTravelAgent")) {
      if (!loadingPrice && dataPrice) {
        displayPrice();
      }
    }
  }, [
    watch("vip"),
    watch("regular"),
    watch("selectTravelAgent"),
    loadingPrice,
    dataPrice,
  ]);

  useEffect(() => {
    const travel = watch("selectTravelAgent")?.value;
    setPriceParam({
      travel_agent_id: travel === "umum" ? null : travel,
    });
    setSaldoDepositParam({ travel_agent_id: travel });
  }, [watch("selectTravelAgent")]);

  useEffect(() => {
    if (!submitLoading && submitData) {
      if (submitData.data) {
        setSnapshow(true);
        setPaymentToken(submitData.data.token);
        reset();
        snap.pay(submitData.data.token, {
          onSuccess: function (result) {
            navigate(`/sales/dashboard`, {
              replace: true,
            });
            setPaymentToken(null);
            toastHandler({
              data: {
                success: true,
                data: `${result.status_message} - order id: ${result.order_id}`,
              },
              error: null,
              loading: false,
              toast: toast,
              title: "Transaksi berhasil",
            });
          },
          onPending: function (result) {
            console.log("pending", JSON.stringify(result));
            navigate(`/sales/dashboard`, {
              replace: true,
            });
          },
          onError: function (result) {
            console.log("error", JSON.stringify(result));
            setSnapshow(false);
          },
          onClose: function () {
            console.log(
              "customer closed the popup without finishing the payment"
            );
          },
        });
      }
    }
  }, [submitLoading, submitData]);

  useEffect(() => {
    if (!submitDepositLoading && submitDepositData) {
      if (submitDepositData) {
        saldoDepositRefetch();
        toastHandler({
          data: submitDepositData,
          error: null,
          loading: false,
          toast: toast,
          title: "Transaksi berhasil dengan Order Id",
        });
        setIsPaymentForm(false);
        reset();
      }
    } else {
      if (!submitDepositLoading && submitDepositError) {
        toastHandler({
          data: submitDepositError,
          error: null,
          loading: false,
          toast: toast,
          title: "Transaksi Gagal",
        });
      }
    }
  }, [submitDepositLoading, submitDepositData]);

  const onSubmit = (data) => {
    // WIP
    const {
      selectTravelAgent,
      namaLeader,
      phone,
      email,
      kodePenerbangan,
      namaGroupUmroh,
      vip,
      regular,
      tglPakai,
    } = data || {};

    const payload = {
      email,
      flight_number: kodePenerbangan,
      group_name: namaGroupUmroh,
      phone_number: phone,
      total: {
        regular: parseInt(regular),
        vip: parseInt(vip),
      },
      tour_leader_name: namaLeader,
      travel_agent_id: selectTravelAgent.value ? selectTravelAgent.value : "0",
      use_date: dateFormat(tglPakai.startDate),
    };

    submitPost(`/api/payment-sales`, payload);
  };

  const preventNegativeValue = (e) => {
    if (e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };

  const onVipChange = (e) => {
    if (e.target.value < 0) {
      setValue("vip", 0);
    }
    setValue("vip", e.target.value);
  };

  const onRegularChange = (e) => {
    if (e.target.value < 0) {
      setValue("regular", 0);
    }
    setValue("regular", e.target.value);
  };

  const onVipIncrease = () => {
    setValue("vip", getValues("vip") + 1);
  };

  const onVipDecrease = () => {
    setValue("vip", getValues("vip") > 0 ? getValues("vip") - 1 : 0);
  };

  const onRegularIncrease = () => {
    setValue("regular", getValues("regular") + 1);
  };

  const onRegularDecrease = () => {
    setValue(
      "regular",
      getValues("regular") > 0 ? getValues("regular") - 1 : 0
    );
  };

  const displayPrice = () => {
    const {
      vip_price,
      vip_discount_price,
      regular_price,
      regular_discount_price,
    } = dataPrice.data || {};

    const discount =
      Math.ceil(
        watch("vip") * vip_price +
          watch("regular") * regular_price -
          (watch("vip") * vip_discount_price +
            watch("regular") * regular_discount_price)
      ) || 0;

    const subTotal = Math.ceil(
      watch("vip") * vip_price + watch("regular") * regular_price
    );

    const total = Math.ceil(subTotal - discount);

    switch (user.role) {
      case TA:
        setVipTotalPrice(Math.ceil(watch("vip") * vip_discount_price));
        setRegularTotalPrice(
          Math.ceil(watch("regular") * regular_discount_price)
        );
        setDiscount(discount);
        setSubTotalPrice(subTotal);
        setTotalPrice(total);
        break;
      case HELPDESK:
        setVipTotalPrice(Math.ceil(watch("vip") * vip_price));
        setRegularTotalPrice(Math.ceil(watch("regular") * regular_price));
        setSubTotalPrice(subTotal);
        setTotalPrice(total);
        setDiscount(discount);
        break;
      case SALES:
        setVipTotalPrice(Math.ceil(watch("vip") * vip_price));
        setRegularTotalPrice(Math.ceil(watch("regular") * regular_price));
        setSubTotalPrice(subTotal);
        setTotalPrice(total);
        setDiscount(discount);
        break;
    }
  };

  const onToggleConfirmation = () => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  const handleCheckMidtransStatus = () => {
    snap.show();
    if (paymentToken !== null) {
      setSnapshow(true);
      snapPopup(paymentToken, {
        onSuccess: function (result) {
          navigate(`/sales/dashboard`, {
            replace: true,
          });
          console.log(result);
          setPaymentToken(null);
          toastHandler({
            data: {
              success: true,
              data: `${result.status_message} - order id: ${result.order_id}`,
            },
            error: null,
            loading: false,
            toast: toast,
            title: "Transaksi berhasil",
          });
          setSnapshow(false);
        },
        onPending: function (result) {
          navigate(`/sales/dashboard`, {
            replace: true,
          });
          console.log("pending", JSON.stringify(result));
        },
        onError: function (result) {
          console.log("error", JSON.stringify(result));
          // snap.hide();
          // setSnapshow(false);
        },
        onClose: function () {
          console.log(
            "customer closed the popup without finishing the payment"
          );
        },
      });
    }
  };

  const handleIsPayementType = async () => {
    const validation = await trigger();
    if (validation) {
      setIsPaymentForm(!isPaymentForm);
    }
  };

  const handleDepositPayment = (data) => {
    const {
      selectTravelAgent,
      namaLeader,
      phone,
      email,
      kodePenerbangan,
      namaGroupUmroh,
      vip,
      regular,
      tglPakai,
    } = data || {};

    const payload = {
      email,
      flight_number: kodePenerbangan,
      group_name: namaGroupUmroh,
      phone_number: phone,
      total: {
        regular: parseInt(regular),
        vip: parseInt(vip),
      },
      tour_leader_name: namaLeader,
      travel_agent_id: selectTravelAgent.value ? selectTravelAgent.value : "0",
      use_date: dateFormat(tglPakai.startDate),
    };

    submitDepositPost(`/api/payment-deposit`, payload);
  };

  const loadOptionsTa = (inputValue, callback) => {
    if (!taData) return [];
    const data = taData.data
      .filter(
        (item) =>
          item.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          item.pic_name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((item) => ({
        label:
          item?.name !== "umum" ? `${item.pic_name} - ${item.name}` : item.name,
        value: item.travel_agent_id,
      }));
    callback(data);
  };

  const onSubmitChangeForm = async () => {
    const validation = await trigger();
    if (parseInt(watch("vip")) === 0 && parseInt(watch("regular")) === 0) {
      setError("vip", {
        type: "manual",
        message: `${SKU_PREMIUM} dan Regular tidak boleh keduanya nol`,
      });
      setError("regular", {
        type: "manual",
        message: `${SKU_PREMIUM} dan Regular tidak boleh keduanya nol`,
      });
    } else if (validation) {
      setIsPaymentForm(true);
    }
  };

  return (
    <form>
      <div className="w-full grid grid-cols-3">
        <div id="form-1" className="col-span-2 mx-4">
          {!isPaymentForm ? (
            <>
              <div className="mb-4">
                <h1 className="text-2xl font-normal">Detail Pemesanan</h1>
                <h3 className="text-base font-normal text-systemGrey">
                  Isi formulir ini dengan benar karena e-tiket akan dikirim ke
                  WA / alamat email sesuai data leader.
                </h3>
              </div>

              <div className="flex flex-col gap-4">
                <CakraInput
                  label="Pilih travel"
                  labelHelp={`pilih "Umum" jika tidak terikat agent`}
                  error={errors.selectTravelAgent}
                >
                  <Controller
                    name="selectTravelAgent"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "pilih salah satu",
                      },
                    }}
                    render={({ field }) => (
                      <AsyncSelect
                        {...field}
                        isClearable
                        defaultOptions={taDataOptions}
                        cacheOptions
                        loadOptions={loadOptionsTa}
                      />
                    )}
                  />
                </CakraInput>
                <CakraInput
                  label={"Nama leader"}
                  labelHelp={"Nama lengkap sesuai KTP"}
                  {...register("namaLeader", { required: true })}
                  placeholder="Tulis nama leader umroh"
                  error={errors.namaLeader}
                />
                <CakraInput
                  label={"Nomor ponsel aktif WA"}
                  {...register("phone", {
                    required: true,
                    pattern: {
                      value: /^(?:\+62|62|0)8[1-9][0-9]{6,11}$/,
                      message: "tolong isikan nomor hp yang benar",
                    },
                  })}
                  error={errors.phone}
                  placeholder="Dimulai dengan 0 atau 62"
                />
                <CakraInput
                  label={"Alamat email"}
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "isi email yang benar",
                    },
                  })}
                  placeholder="Alamat email"
                  maxLength={50}
                  error={errors.email}
                />
                {/* <input maxLength={}/> */}
                <CakraInput
                  label={"Nomor penerbangan"}
                  {...register("kodePenerbangan", { required: true })}
                  placeholder="Isi nomor penerbangan pesawat umroh"
                  error={errors.kodePenerbangan}
                />
                <CakraInput
                  label={"Nama group umroh"}
                  {...register("namaGroupUmroh", { required: true })}
                  placeholder="Isi nama group jamaah umroh dengan unik"
                  error={errors.namaGroupUmroh}
                  max={255}
                />
                <CakraInput
                  label={"Waktu Penggunaan area Lounge"}
                  error={errors.tglPakai}
                >
                  <Controller
                    control={control}
                    name="tglPakai"
                    defaultValue={null}
                    rules={{
                      validate: (v) => {
                        if (
                          v === null ||
                          v.startDate === undefined ||
                          v.startDate === null
                        ) {
                          return "tolong isi tanggal dipakai";
                        }
                      },
                    }}
                    render={({ field }) => (
                      <Datepicker
                        useRange={false}
                        asSingle={true}
                        displayFormat="DD MMM YYYY"
                        popoverDirection="up"
                        minDate={new Date()}
                        inputClassName={
                          "bg-inherit text-black w-full border-inherit rounded-lg"
                        }
                        placeholder="Pilih tanggal penggunaan"
                        value={field.value}
                        onChange={(date) => field.onChange(date)}
                        readOnly={true}
                      />
                    )}
                  />
                </CakraInput>
                <Label className="border-b-2 pb-4 text-lg font-semibold">
                  Pilih tipe pesanan
                </Label>
                {/* <div className="flex"> */}
                <FormControl className="flex flex-row">
                  <div className="basis-1/2 flex flex-col gap-3 capitalize">
                    <FormHelperText mt={"1px"} mb={"0.5rem"}>
                      {`${SKU_PREMIUM} Normal `}{" "}
                      <span className="line-through">
                        {numberToIDR(dataPrice?.data?.vip_price || 0)}
                      </span>{" "}
                      {`/pax`}
                    </FormHelperText>
                    <div className="text-base font-semibold">
                      {`Paket ${SKU_PREMIUM} ${numberToIDR(
                        dataPrice?.data?.vip_discount_price ||
                          dataPrice?.data?.vip_price
                      )}/pax`}
                    </div>
                  </div>
                  <div className="basis-1/2">
                    <CakraInput label={"Jumlah tiket"} error={errors.vip}>
                      <NumberInput min={0}>
                        <NumberInputField
                          {...register("vip", {
                            value: 0,
                            min: 0,
                            onChange: onVipChange,
                          })}
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "e") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper onClick={onVipIncrease} />
                          <NumberDecrementStepper onClick={onVipDecrease} />
                        </NumberInputStepper>
                      </NumberInput>
                    </CakraInput>
                  </div>
                </FormControl>
                <FormControl className="flex flex-row mb-10">
                  <div className="basis-1/2 flex flex-col gap-3">
                    <FormHelperText mt={"1px"} mb={"0.5rem"}>
                      {`Regular Normal `}{" "}
                      <span className="line-through">
                        {numberToIDR(dataPrice?.data?.regular_price || 0)}
                      </span>{" "}
                      {`/pax`}
                    </FormHelperText>
                    <div className="text-base font-semibold">
                      {`Paket Regular ${numberToIDR(
                        dataPrice?.data?.regular_discount_price ||
                          dataPrice?.data?.regular_price
                      )}/pax`}
                    </div>
                  </div>
                  <div className="basis-1/2">
                    <CakraInput label={"Jumlah tiket"} error={errors.regular}>
                      <NumberInput min={0}>
                        <NumberInputField
                          {...register("regular", {
                            value: 0,
                            min: 0,
                            onChange: onRegularChange,
                          })}
                          onKeyDown={preventNegativeValue}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper onClick={onRegularIncrease} />
                          <NumberDecrementStepper onClick={onRegularDecrease} />
                        </NumberInputStepper>
                      </NumberInput>
                    </CakraInput>
                  </div>
                </FormControl>
                {/* </div> */}
              </div>
            </>
          ) : (
            <PaymentTypeForm
              handleDepositPayment={handleSubmit(handleDepositPayment)}
              handleOnlinePayment={handleSubmit(onSubmit)}
              onHandleBack={handleIsPayementType}
              saldoDeposit={
                !saldoDepositLoading &&
                saldoDepositData &&
                watch("selectTravelAgent")?.value !== ""
                  ? saldoDepositData
                  : 0
              }
              isDepositPayment={
                watch("selectTravelAgent") !== "" &&
                saldoDepositData >= totalPrice
              }
            />
          )}
        </div>
        <div id="fomr-2" className="mx-3">
          <Card x-chunk="dashboard-07-chunk-3" className="bg-green-100 ">
            <CardHeader className="pt-5 pr-0">
              <CardTitle className="flex items-center gap-2 text-lg">
                Preview Pesanan
              </CardTitle>
            </CardHeader>
            <CardContent className="p-0">
              <div className="flex flex-col sm:gap-6 p-6 pt-0 ">
                <LabelWrapper className="text-sm font-normal">
                  Nama Leader
                  <article className="text-pretty max-w-2xs">
                    <h3 className="capitalize">{watch("namaLeader") || "-"}</h3>
                  </article>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Nomor ponsel{" "}
                  <h3 className="capitalize">{watch("phone") || "-"}</h3>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal justify-between">
                  Email
                  <article className="text-pretty max-w-2xs">
                    <h3>{watch("email") || "-"}</h3>
                  </article>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Nama Group Umroh{" "}
                  <article className="text-pretty max-w-2xs">
                    <h3 className="capitalize">
                      {watch("namaGroupUmroh") || "-"}
                    </h3>
                  </article>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Waktu Penggunaan
                  <h3>
                    {(watch("tglPakai") &&
                      moment(watch("tglPakai").startDate).format(
                        "DD MMM YYYY"
                      )) ||
                      "-"}
                  </h3>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  {SKU_PREMIUM} x {watch("vip")} pax
                  <h3 className="capitalize">
                    {numberToIDR(vipTotalPrice || 0)}
                  </h3>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Regular x {watch("regular")} pax
                  <h3 className="capitalize">
                    {numberToIDR(regularTotalPrice || 0)}
                  </h3>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Sub total
                  <h3 className="capitalize">
                    {numberToIDR(subTotalPrice || 0)}
                  </h3>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Potongan khusus Agent
                  <h3 className="capitalize text-green-700">
                    {"-" + numberToIDR(discount || 0)}
                  </h3>
                </LabelWrapper>
              </div>
              <div>
                <LabelWrapper className="bg-yellow-200 px-6 py-4 mx-0">
                  Total Pembayaran{" "}
                  <h3 className="font-bold text-base">
                    {numberToIDR(totalPrice || 0)}
                  </h3>
                </LabelWrapper>
                <div className="px-6 pt-6 pb-4">
                  {!isPaymentForm ? (
                    <Button
                      id="pay"
                      type="button"
                      variant={"default"}
                      size="lg"
                      className="w-full !text-base !font-semibold text-white bg-green-600"
                      onClick={handleSubmit(onSubmitChangeForm)}
                    >
                      Lanjut Pembayaran
                    </Button>
                  ) : null}
                  {snapShow ? (
                    <Button
                      mt={"12px"}
                      colorScheme="bg-green-600"
                      className="bg-green-600"
                      w={"100%"}
                      onClick={handleCheckMidtransStatus}
                    >
                      Check Status Pembayaran
                    </Button>
                  ) : null}
                  <div className="flex flex-row text-center gap-1 p-3 justify-center items-center pb-0">
                    <IcBaselineVerified className="text-systemAzure" />{" "}
                    Transaksi aman dan nyaman
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <ModalConfirmation
          onToggleModal={onToggleConfirmation}
          isOpen={isOpen}
          buttonSubmit={
            <Button
              id="pay"
              type="submit"
              colorScheme="bg-green-600"
              size="md"
              className="!text-base !font-semibold text-white bg-green-600"
              onClick={handleSubmit(onSubmit)}
            >
              {submitLoading ? "Loading..." : "Submit"}
            </Button>
          }
        />
      </div>
    </form>
  );
};

export default SalesTicket;
