import moment from "moment";
import {
  dateFormat,
  defineCheckInStatus,
  numberToIDR,
  numberWithoutIDR,
} from "./utils";
import { SKU_PREMIUM } from "./constant";

export const ColumnsGenReportCheckin = [
  {
    accessorKey: "reservation_id",
    header: "Kode Booking",
    cell: ({ row, table }) => {
      const item = row.original;
      const kodeBooking = row.getValue("reservation_id");
      return (
        <>
          <h3 className="font-normal">{kodeBooking}</h3>
          <p className="normal-case">{dateFormat(item.created_at)}</p>
        </>
      );
    },
  },
  {
    accessorKey: "group_name",
    header: "nama group umroh",
    cell: ({ row }) => {
      const item = row.original;
      const namaGroup = row.getValue("group_name");
      return (
        <>
          <div>{namaGroup}</div>
          <p>{item.use_date}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tour_leader_name",
    header: "Tour Leader",
    cell: ({ row }) => {
      const item = row.original;
      const tourLeaderName = row.getValue("tour_leader_name");
      return (
        <>
          <div>{tourLeaderName}</div>
          <p className="text-sm">{item.phone_number}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tickets",
    header: "Total Tiket",
    cell: ({ row }) => {
      const tiket = row.getValue("tickets");
      return (
        <>
          <div>{tiket.total}</div>
          <div>
            <p className="text-sm">
              {tiket.vip +
                " " +
                SKU_PREMIUM +
                ", " +
                tiket.regular +
                " Regular"}
            </p>
            {/* <p className="text-sm">{}</p> */}
          </div>
        </>
      );
    },
  },
  {
    accessorKey: "status_checkin",
    header: "Status",
    cell: ({ row }) => {
      const status = row.getValue("status_checkin");
      return defineCheckInStatus(status);
    },
  },
  // {
  //   accessorKey: "status",
  //   header: "Status",
  //   cell: ({ row }) => {
  //     const status = row.getValue("status");
  //     return defineStatus(status);
  //   },
  // },
];

export const ColumnsGenLogTransaksi = [
  {
    accessorKey: "reservation_id",
    header: "ID Order",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <>
          <h3 className="font-normal">{row.getValue("reservation_id")}</h3>
          <p className="normal-case">
            {item.created_at ? dateFormat(item.created_at) : "-"}
          </p>
        </>
      );
    },
  },
  {
    accessorKey: "use_date",
    header: "Tanggal penggunaan",
    cell: ({ row }) => {
      const item = row.getValue("use_date");
      return (
        <>
          <div>{dateFormat(item)}</div>
        </>
      );
    },
  },
  {
    accessorKey: "tour_leader_name",
    header: "Tour Leader",
    cell: ({ row }) => {
      const item = row.getValue("tour_leader_name");
      const items = row.original;
      return (
        <>
          <div>{item}</div>
          <p>{items.phone_number || "-"}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tickets",
    header: "Total pax",
    cell: ({ row }) => {
      const item = row.getValue("tickets");
      return (
        <>
          <div>{item.total || "0"}</div>
        </>
      );
    },
  },
  {
    accessorKey: "discount",
    header: "Discount",
    cell: ({ row }) => {
      const item = row.getValue("discount");
      return (
        <>
          <div className="text-right">{numberToIDR(item || 0)}</div>
        </>
      );
    },
  },
  {
    accessorKey: "total_purchase",
    header: "Total Pembelian",
    cell: ({ row }) => {
      const item = row.getValue("total_purchase");
      return (
        <>
          <div className="text-right">{numberToIDR(item || 0)}</div>
        </>
      );
    },
  },
  {
    accessorKey: "grand_total",
    header: "Harga final",
    cell: ({ row }) => {
      const item = row.getValue("grand_total");
      return (
        <>
          <div className="text-right">{numberToIDR(item || 0)}</div>
        </>
      );
    },
  },
];

export const ColumnsGenReportECYSYS = [
  {
    accessorKey: "invoice_number",
    header: "ID Order",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <div className="max-w-52">
          <h3 className="font-normal">{row.getValue("invoice_number")}</h3>
          <p className="normal-case">
            {item.transaction_date ? dateFormat(item.transaction_date) : "-"}
          </p>
        </div>
      );
    },
  },
  {
    accessorKey: "buyer_name",
    header: "Pembeli",
    cell: ({ row }) => {
      const item = row.getValue("buyer_name");
      return <div>{item}</div>;
    },
  },
  {
    accessorKey: "item_name",
    header: "Nama Barang",
    cell: ({ row }) => {
      const item = row.getValue("item_name");
      return <div>{item}</div>;
    },
  },
  {
    accessorKey: "item_quantity",
    header: "Total pax",
    cell: ({ row }) => {
      const item = row.getValue("item_quantity");
      return <div>{item || "0"}</div>;
    },
  },
  {
    accessorKey: "transaction_amount",
    header: "Total Pembelian (IDR)",
    cell: ({ row }) => {
      const item = row.getValue("transaction_amount");
      return <div className="text-right">{numberWithoutIDR(item || 0)}</div>;
    },
  },
  {
    accessorKey: "item_vat",
    header: "Tax",
    cell: ({ row }) => {
      const item = row.getValue("item_vat");
      const items = row.original;
      return (
        <>
          <div className="text-right">{numberWithoutIDR(item || 0)}</div>
          <div>{items.tax_id}</div>
        </>
      );
    },
  },
  {
    accessorKey: "payment_name",
    header: "Metode pembayaran",
    cell: ({ row }) => {
      const item = row.getValue("payment_name");
      const formatedItem = item.split(/[\s_]/);
      const displayFormated = formatedItem.join(" - ");
      return <div>{displayFormated}</div>;
    },
  },
];
