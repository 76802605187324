import { SKU_PREMIUM } from "../lib/constant";
import { truncateString } from "../lib/utils";

const handlePrint = (qrCodeBase64s) => {
  const printWindow = window.open("", "_blank");
  // opsi 2
  printWindow.document.write(`
    <html>
      <head>
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400:500;700&display=swap" rel="stylesheet">
        <style>
        @media print {
          body {
            margin: 0px;
            padding: 0px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            font-family: 'Inter', sans-serif; /* Apply Inter font to body */
          }
          @page {
            size: 50mm 30mm;
            margin: 0; /* Remove margin to use full label size */
          }
          body * {
            visibility: hidden;
          }
          #printArea, #printArea, #printAreaLabel * {
            visibility: visible;
          }
          #printArea {
            position: relative;
            top: -5px;
            left: 50%;
            transform: translate(-50%, 0%);
            width: 50mm;
            height: 30mm;
            padding: 0;
            overflow: hidden; /* Prevent content overflow */
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            page-break-before: always;
          }
          .labelContainer {
            position: relative;
            width: 50mm;
            height: 30mm;
            page-break-before: always;
            page-break-after: always;
          }
          #printAreaLabel {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0;
            overflow: hidden; /* Prevent content overflow */
          }
          .label {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            flex-direction: column;
            gap: 0px;
          }
          .labelInfo {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            gap: 0px;
          }
          .qr-code {
            display: flex;
            width: 20mm;
            height: 100%;
            padding: 0mm;
          }
          .qr-code img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
          .information {
            flex: 1;
            display: flex;
            font-size: 12px;
            word-wrap: break-word;
            flex-direction: column;
            font-weight: 700;
            padding-left: 1px;
            padding-right: 1px;
            text-align: left;
            padding-top: 0;
            padding-bottom: 0;
            gap: 1px;
          }
          .title {
            font-size: 12px;
          }
          .title.capitalize {
            text-transform: capitalize;
          }
          .title.type {
            line-height: 2;
            font-size: 16px; 
            border: 0.1px solid;
            text-align: center;
          }
          .title.withBg {
            background-color: black; /* Background color */
            color: white; /* Text color */
            border: none;
          }
          .title.travel-agent {
            text-wrap: nowrap;
            white-space: nowrap;
            max-width: 20mm;
          }
          .title.tour-leader {
            text-wrap: no wrap;
            white-space: nowrap;
            max-width: 20mm;
          }
          .heading {
            max-width: 50mm;
            text-align: center;
            font-size: 10px;
          }
        }
      </style>
      </head>
      <body>
      
      ${qrCodeBase64s
        .map((base64, index) => {
          const skuType = base64.sku;
          const skuPreferenceStyle =
            skuType.toLowerCase() === SKU_PREMIUM ? "withBg" : "";
          const skuTitleStyle = skuType.toUpperCase();
          const skuTravelAgent = base64?.travel_agent_name
            ? truncateString(base64.travel_agent_name)
            : "-";

          const skuTourLeader = base64?.tour_leader_name
            ? truncateString(base64.tour_leader_name)
            : "-";
          const skuBookingCode = base64.id;

          return `
          <div class="labelContainer">
            <div id="printAreaLabel" class="label">
            <div class="heading"><div>Kode Booking</div><div>${skuBookingCode}</div></div>
            <div class="labelInfo">
              <div class="qr-code">
                <img id="imgCode" class="object-contain" src="data:image/png;base64,${
                  base64.qr_base64
                }" />
              </div>
              <div class="information">
                <div class="title type ${skuPreferenceStyle}">${
            skuTitleStyle || ""
          }</div>
                <div class="title travel-agent">${skuTravelAgent}</div>
                <div class="title tour-leader capitalize">${skuTourLeader}</div>
              </div>
              </div>
            </div>
          </div> `;
        })
        .join("")}
        
      </body>
    </html>
  `);

  printWindow.document.close();

  // Wait for images to load before printing
  const images = printWindow.document.querySelectorAll("img");
  const totalImages = images.length;
  let loadedImages = 0;

  images.forEach((img) => {
    img.onload = () => {
      loadedImages++;
      if (loadedImages === totalImages) {
        printWindow.print();
        printWindow.onafterprint = printWindow.close;
      }
    };
    img.onerror = () => {
      loadedImages++;
      if (loadedImages === totalImages) {
        printWindow.print();
        printWindow.onafterprint = printWindow.close;
      }
    };
  });
};

export default handlePrint;
