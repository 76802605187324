import React from "react";
import {
  ChevronLeft,
  ChevronRight,
  MoreHorizontal,
  ChevronsLeft,
  ChevronsRight,
} from "lucide-react";

import { cn } from "../../lib/utils";
import { buttonVariants } from "../ui/Button";

const Pagination = ({ className, ...props }) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn("mx-auto flex w-full justify-center", className)}
    {...props}
  />
);
Pagination.displayName = "Pagination";

const PaginationContent = React.forwardRef(function PaginationContent(
  { className, ...props },
  ref
) {
  return (
    <ul
      ref={ref}
      className={cn("flex flex-row items-center gap-1", className)}
      {...props}
    />
  );
});
PaginationContent.displayName = "PaginationContent";

const PaginationItem = React.forwardRef(function PaginationItem(
  { className, ...props },
  ref
) {
  return <li ref={ref} className={cn("", className)} {...props} />;
});
PaginationItem.displayName = "PaginationItem";

const PaginationLink = ({ className, isActive, size = "icon", ...props }) => (
  <button
    aria-current={isActive ? "page" : undefined}
    className={cn(
      buttonVariants({
        variant: isActive ? "default" : "outline",
        size,
      }),
      "rounded-full",
      className
    )}
    {...props}
  />
);
PaginationLink.displayName = "PaginationLink";

const PaginationPrevious = ({ className, ...props }) => (
  <PaginationLink
    aria-label="Go to previous page"
    className={cn("rounded-full", className)}
    {...props}
  >
    <ChevronLeft className="h-4 w-4" />
    {/* <span>Previous</span> */}
  </PaginationLink>
);
PaginationPrevious.displayName = "PaginationPrevious";

const DoubleArrowPrev = ({ className, ...props }) => (
  <PaginationLink
    aria-label="Go to last prev page"
    className={cn("rounded-full", className)}
    {...props}
  >
    <ChevronsLeft className="h-4 w-4" />
  </PaginationLink>
);

DoubleArrowPrev.displayName = "DoubleArrowPrev";

const PaginationNext = ({ className, ...props }) => (
  <PaginationLink
    aria-label="Go to next page"
    className={cn("rounded-full", className)}
    {...props}
  >
    {/* <span>Next</span> */}
    <ChevronRight className="h-4 w-4" />
  </PaginationLink>
);
PaginationNext.displayName = "PaginationNext";

const DoubleArrowNext = ({ className, ...props }) => (
  <PaginationLink
    aria-label="Go to last next page"
    className={cn("rounded-full", className)}
    {...props}
  >
    <ChevronsRight className="h-4 w-4" />
  </PaginationLink>
);

DoubleArrowNext.displayName = "DoubleArrowNext";

const PaginationEllipsis = ({ className, ...props }) => (
  <span
    aria-hidden
    className={cn("flex h-9 w-9 items-center justify-center", className)}
    {...props}
  >
    <MoreHorizontal className="h-4 w-4" />
    <span className="sr-only">More pages</span>
  </span>
);
PaginationEllipsis.displayName = "PaginationEllipsis";

const PaginationNumber = (currentPage, totalPages, onPageChange) => {
  const numPage = 5;
  const pages = [];
  if (totalPages < numPage) {
    for (let i = 0; i < totalPages; i++) {
      pages.push(
        <PaginationItem key={`page-${i}`}>
          <PaginationLink
            onClick={() => onPageChange(i)}
            isActive={i === currentPage}
            // {...props}
          >
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }
  } else {
    const leftside = currentPage - numPage / 2 > 0;
    const rightside = currentPage + numPage / 2 < totalPages;

    pages.push(
      <PaginationItem key={`page-${0}`}>
        <PaginationLink
          onClick={() => onPageChange(0)}
          isActive={currentPage === 0}
        >
          {1}
        </PaginationLink>
      </PaginationItem>
    );
    if (leftside) {
      pages.push(<PaginationEllipsis key={"elipsis-1"} />);
    }
    const str = Math.max(1, Math.round(currentPage - numPage / 2));
    const end = Math.min(totalPages - 1, Math.round(currentPage + numPage / 2));
    for (let i = str; i < end; i++) {
      pages.push(
        <PaginationItem key={`page-${i}`}>
          <PaginationLink
            onClick={() => onPageChange(i)}
            isActive={i === currentPage}
          >
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }
    if (rightside) {
      pages.push(<PaginationEllipsis key={"elipsis-2"} />);
    }
    pages.push(
      <PaginationItem key={`page-${totalPages - 1}`}>
        <PaginationLink
          onClick={() => onPageChange(totalPages - 1)}
          isActive={totalPages - 1 === currentPage}
        >
          {totalPages}
        </PaginationLink>
      </PaginationItem>
    );
  }

  return pages;
};

PaginationNumber.displayName = "PaginationNumber";

export {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  DoubleArrowNext,
  DoubleArrowPrev,
  PaginationNumber,
};
