import React, { useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Card, CardContent } from "../../components/ui/Card";
import {
  CATATAN_OPTIONS,
  NOTE_ACTIONS,
  NOTE_CATEGORY,
} from "../../lib/constant";
import CakraInput from "../../components/CakraInput";
import { Controller, useForm } from "react-hook-form";
import { useApiPost } from "../../hooks/useApiPost";
import toastHandler from "../../components/toastHandler";
import { Select } from "chakra-react-select";
import { useApiPut } from "../../hooks/useApiPut";

const ModalNote = ({
  isOpen,
  onToggleModal,
  data,
  isCreate = true,
  refetch,
}) => {
  const toast = useToast();
  const { register, handleSubmit, resetField, control, setValue } = useForm();

  const {
    handlePost,
    loading: loadingSubmit,
    data: dataSubmit,
    error: errorSubmit,
  } = useApiPost();
  const {
    handlePut,
    loading: loadingUpdate,
    data: dataUpdate,
    error: errorUpdate,
  } = useApiPut();
  useEffect(() => {
    if (!loadingSubmit && dataSubmit) {
      onToggleModal();
      refetch();
    }
    toastHandler({
      data: dataSubmit,
      error: errorSubmit,
      loading: loadingSubmit,
      toast: toast,
      title: `Berhasil disimpan `,
    });
  }, [loadingSubmit, dataSubmit, errorSubmit]);

  useEffect(() => {
    if (!loadingUpdate && dataUpdate) {
      onToggleModal();
      refetch();
    }
    toastHandler({
      data: dataUpdate,
      error: errorUpdate,
      loading: loadingUpdate,
      toast: toast,
      title: `Berhasil diupdate`,
    });
  }, [loadingUpdate, dataUpdate, errorUpdate]);

  useEffect(() => {
    if (data) {
      setValue("notes", data.notes);
      setValue(
        "selectCategory",
        isCreate
          ? null
          : _.find(NOTE_CATEGORY, {
              value: data.category,
            })
      );
    }
  }, [data]);

  const onSubmit = (formData) => {
    const { selectCategory, notes, selectStatus } = formData;
    const payload = {
      ...(!isCreate && { id: data.id }),
      ...(!isCreate && { status: selectStatus.value }),
      ...(!isCreate && { action: NOTE_ACTIONS.updateData }),
      category: selectCategory.value,
      notes: notes,
    };

    if (isCreate) {
      handlePost("/api/shift-notes", payload);
    } else {
      handlePut("/api/shift-notes", payload);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      size={"sm"}
      onClose={() => {
        onToggleModal();
        resetField("notes", { defaultValue: "" });
        resetField("selectCategory", { defaultValue: null });
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={"lg"} className="border-b-2 my-2 !font-bold">
            {isCreate ? "Buat Catatan" : "Baca Catatan"}
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody p={0}>
            <div className="flex flex-row justify-between px-2 rounded-sm">
              <Card
                x-chunk="dashboard-07-chunk-3"
                className={`flex border-none shadow-none bg-gray-50 w-full`}
              >
                <div className="w-full">
                  <CardContent className="p-0">
                    <div className="flex flex-col sm:gap-3 p-4 pt-0 text-gray-500 ">
                      {!isCreate && (
                        <CakraInput label="Status">
                          <Controller
                            name="selectStatus"
                            control={control}
                            defaultValue={
                              isCreate
                                ? null
                                : _.find(CATATAN_OPTIONS, {
                                    value: data?.status,
                                  })
                            }
                            rules={{
                              required: {
                                message: "pilih salah satu",
                              },
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isClearable
                                options={CATATAN_OPTIONS}
                                chakraStyles={{
                                  input: (prev, { selectProps }) => ({
                                    ...prev,
                                    boxShadow: "none !important",
                                  }),
                                }}
                              />
                            )}
                          />
                        </CakraInput>
                      )}
                      <CakraInput label="Kategori">
                        <Controller
                          name="selectCategory"
                          control={control}
                          rules={{
                            required: {
                              message: "pilih salah satu",
                            },
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              isClearable
                              options={NOTE_CATEGORY}
                              chakraStyles={{
                                input: (prev, { selectProps }) => ({
                                  ...prev,
                                  boxShadow: "none !important",
                                }),
                              }}
                              isDisabled={!isCreate}
                            />
                          )}
                        />
                      </CakraInput>
                      <CakraInput label="Keterangan Lengkap">
                        <Textarea
                          {...register("notes", {
                            required: true,
                          })}
                        />
                      </CakraInput>
                    </div>
                  </CardContent>
                </div>
              </Card>
            </div>
          </ModalBody>

          <ModalFooter px={"0.5rem"}>
            <Button
              width="100%"
              colorScheme="green"
              size={"sm"}
              type="submit"
              isDisabled={loadingSubmit}
            >
              {"Simpan"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default ModalNote;
