import React from "react";

export function MdiFilterOutline(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15 19.88c.04.3-.06.62-.29.83a.996.996 0 0 1-1.41 0L9.29 16.7a.99.99 0 0 1-.29-.83v-5.12L4.21 4.62a1 1 0 0 1 .17-1.4c.19-.14.4-.22.62-.22h14c.22 0 .43.08.62.22a1 1 0 0 1 .17 1.4L15 10.75zM7.04 5L11 10.06v5.52l2 2v-7.53L16.96 5z"
      ></path>
    </svg>
  );
}

export function IconWA(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_2001_3451)">
        <path
          d="M0.512151 11.8564C0.511588 13.8729 1.04259 15.8418 2.05228 17.5772L0.415588 23.5067L6.53109 21.9156C8.22255 22.8293 10.1177 23.3081 12.0436 23.3082H12.0487C18.4063 23.3082 23.5816 18.1749 23.5843 11.8654C23.5855 8.80801 22.3867 5.93304 20.2085 3.77006C18.0307 1.60727 15.1342 0.415549 12.0482 0.414154C5.68978 0.414154 0.51487 5.54718 0.512245 11.8564"
          fill="url(#paint0_linear_2001_3451)"
        />
        <path
          d="M0.100313 11.8527C0.0996563 13.9417 0.649687 15.981 1.69537 17.7786L0 23.9207L6.33478 22.2726C8.08022 23.2168 10.0454 23.7147 12.0451 23.7154H12.0502C18.636 23.7154 23.9972 18.3975 24 11.8621C24.0011 8.69488 22.7591 5.71656 20.5031 3.47609C18.2468 1.23591 15.2468 0.00130233 12.0502 0C5.46337 0 0.102938 5.31721 0.100313 11.8527ZM3.87291 17.469L3.63637 17.0965C2.64206 15.5277 2.11725 13.7149 2.118 11.8534C2.12006 6.4213 6.57544 2.00186 12.054 2.00186C14.7071 2.00298 17.2005 3.02921 19.0759 4.89116C20.9512 6.7533 21.9831 9.22865 21.9824 11.8614C21.98 17.2935 17.5245 21.7135 12.0502 21.7135H12.0463C10.2638 21.7126 8.51569 21.2376 6.99113 20.34L6.62831 20.1265L2.86912 21.1045L3.87291 17.469Z"
          fill="url(#paint1_linear_2001_3451)"
        />
        <path
          d="M9.06355 6.89749C8.83987 6.40419 8.60446 6.39424 8.39174 6.38559C8.21755 6.37814 8.01843 6.3787 7.81949 6.3787C7.62037 6.3787 7.29683 6.45303 7.02337 6.74931C6.74962 7.04587 5.97824 7.76252 5.97824 9.2201C5.97824 10.6778 7.04821 12.0864 7.19737 12.2843C7.34671 12.4818 9.26296 15.5687 12.2978 16.7562C14.8201 17.7431 15.3334 17.5468 15.8808 17.4973C16.4283 17.448 17.6474 16.7808 17.8961 16.089C18.145 15.3973 18.145 14.8044 18.0704 14.6805C17.9958 14.557 17.7966 14.4829 17.4981 14.3348C17.1994 14.1866 15.7314 13.4699 15.4578 13.371C15.184 13.2722 14.985 13.2229 14.7859 13.5195C14.5867 13.8157 14.015 14.4829 13.8407 14.6805C13.6666 14.8785 13.4923 14.9032 13.1938 14.755C12.895 14.6063 11.9334 14.2939 10.7926 13.2847C9.90496 12.4994 9.30571 11.5296 9.13152 11.2329C8.95733 10.9367 9.11287 10.7762 9.26258 10.6286C9.39674 10.4958 9.56127 10.2826 9.71071 10.1097C9.85958 9.93666 9.90927 9.81321 10.0088 9.61563C10.1085 9.41786 10.0586 9.24484 9.98408 9.09666C9.90927 8.94847 9.32905 7.48326 9.06355 6.89749Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2001_3451"
          x1="1158.85"
          y1="2309.67"
          x2="1158.85"
          y2="0.414154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1FAF38" />
          <stop offset="1" stop-color="#60D669" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2001_3451"
          x1="1200"
          y1="2392.07"
          x2="1200"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9F9F9" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <clipPath id="clip0_2001_3451">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function IcOutlineFactCheck(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20 3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m0 16H4V5h16z"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.41 10.42L17.99 9l-3.17 3.17l-1.41-1.42L12 12.16L14.82 15zM5 7h5v2H5zm0 4h5v2H5zm0 4h5v2H5z"
      ></path>
    </svg>
  );
}

export function IcOutlineFileCopy(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V11zM8 21V7h6v5h5v9z"
      ></path>
    </svg>
  );
}

export function IcOutlineSetting(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M19.43 12.98c.04-.32.07-.64.07-.98c0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46a.5.5 0 0 0-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65A.488.488 0 0 0 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1a.566.566 0 0 0-.18-.03c-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98c0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46a.5.5 0 0 0 .61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03c.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64zm-1.98-1.71c.04.31.05.52.05.73c0 .21-.02.43-.05.73l-.14 1.13l.89.7l1.08.84l-.7 1.21l-1.27-.51l-1.04-.42l-.9.68c-.43.32-.84.56-1.25.73l-1.06.43l-.16 1.13l-.2 1.35h-1.4l-.19-1.35l-.16-1.13l-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7l-1.06.43l-1.27.51l-.7-1.21l1.08-.84l.89-.7l-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13l-.89-.7l-1.08-.84l.7-1.21l1.27.51l1.04.42l.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43l.16-1.13l.2-1.35h1.39l.19 1.35l.16 1.13l1.06.43c.43.18.83.41 1.23.71l.91.7l1.06-.43l1.27-.51l.7 1.21l-1.07.85l-.89.7zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4s4-1.79 4-4s-1.79-4-4-4m0 6c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2"
      />
    </svg>
  );
}

export function IcBaselineArrowBack(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20z"
      />
    </svg>
  );
}

export function IcAirplaneTicketOutline(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m8.85 15.65l8.9-2.35q.375-.1.563-.462t.087-.738t-.437-.562t-.713-.088l-2.45.65l-4-3.75l-1.4.35l2.4 4.2l-2.4.6l-1.25-.95l-.95.25zM20 20H4q-.825 0-1.412-.587T2 18v-4q.825 0 1.413-.587T4 12t-.587-1.412T2 10V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20m0-2V6H4v2.55q.925.55 1.463 1.463T6 12t-.537 1.988T4 15.45V18zm-8-6"
      ></path>
    </svg>
  );
}

export function IcDollar(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M7 15h2c0 1.08 1.37 2 3 2s3-.92 3-2c0-1.1-1.04-1.5-3.24-2.03C9.64 12.44 7 11.78 7 9c0-1.79 1.47-3.31 3.5-3.82V3h3v2.18C15.53 5.69 17 7.21 17 9h-2c0-1.08-1.37-2-3-2s-3 .92-3 2c0 1.1 1.04 1.5 3.24 2.03C14.36 11.56 17 12.22 17 15c0 1.79-1.47 3.31-3.5 3.82V21h-3v-2.18C8.47 18.31 7 16.79 7 15"
      ></path>
    </svg>
  );
}

export function IcPencil(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20.71 7.04c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83l3.75 3.75M3 17.25V21h3.75L17.81 9.93l-3.75-3.75z"
      ></path>
    </svg>
  );
}

export function IcMagnifying(props) {
  return (
    <svg
      className="w-4 h-4 text-gray-500 dark:text-gray-400"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
      />
    </svg>
  );
}

export function IcOutlineVerified(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={326}
      height={326}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#01df4f"
        d="M23 11.99L20.56 9.2l.34-3.69l-3.61-.82L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5l.34 3.7L1 11.99l2.44 2.79l-.34 3.7l3.61.82l1.89 3.2l3.4-1.47l3.4 1.46l1.89-3.19l3.61-.82l-.34-3.69zm-3.95 1.48l-.56.65l.08.85l.18 1.95l-1.9.43l-.84.19l-.44.74l-.99 1.68l-1.78-.77l-.8-.34l-.79.34l-1.78.77l-.99-1.67l-.44-.74l-.84-.19l-1.9-.43l.18-1.96l.08-.85l-.56-.65L3.67 12l1.29-1.48l.56-.65l-.09-.86l-.18-1.94l1.9-.43l.84-.19l.44-.74l.99-1.68l1.78.77l.8.34l.79-.34l1.78-.77l.99 1.68l.44.74l.84.19l1.9.43l-.18 1.95l-.08.85l.56.65l1.29 1.47z"
      ></path>
      <path
        fill="#01df4f"
        d="m10.09 13.75l-2.32-2.33l-1.48 1.49l3.8 3.81l7.34-7.36l-1.48-1.49z"
      ></path>
    </svg>
  );
}

export function IcBaselineClose(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={326}
      height={326}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#ef2525"
        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
      ></path>
    </svg>
  );
}
