import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import LogTable from "./sales/LogTable";
import CakraInput from "../components/CakraInput";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";
import { dateFormat, dateUnix } from "../lib/utils";
import { useFetchDataDashboard } from "../hooks/useFetchDataDashboard";
import {
  ColumnsGenLogTransaksi,
  ColumnsGenReportCheckin,
  ColumnsGenReportECYSYS,
} from "../lib/generalColumns";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import ReportEcysysTable from "../components/ReportEcysysTable";
import { ACCOUNTING, TA } from "../lib/constant";
import { AsyncSelect } from "chakra-react-select";
import { useFetchData } from "../hooks/useFetchData";
import LoadingOverlay from "../components/ui/LoadingOverlay";

const API_URL_GET_TA = {
  helpdesk: "/api/helpdesk-travel-agents",
  sales: "/api/travel-agents",
  accounting: "/api/helpdesk-travel-agents",
};

const Report = () => {
  const { user } = useAuth();
  const [date, setDate] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [selectedTa, setSelectedTa] = useState(null);
  const [selectedSales, setSelectedSales] = useState(null);
  const [selectedHelpdesk, setSelectedHelpdesk] = useState(null);
  const [genParams, setGenParams] = useState({
    ...(selectedTa && { travel_agent_id: selectedTa.value }),
    start_date: dateFormat(date.startDate),
    end_date: dateFormat(date.endDate),
  });
  const [taDataOptions, setTaDataOptions] = useState([]);
  const [salesDataOptions, setSalesDataOptions] = useState([]);
  const [helpdeskDataOptions, setHelpdeskDataOptions] = useState([]);
  const { setParam, loading, data, error } = useFetchDataDashboard(
    "/api/report-download",
    genParams
  );

  const {
    loading: taLoading,
    data: taData,
    error: taError,
  } = useFetchData(API_URL_GET_TA[user.role] || "");

  const {
    loading: salesLoading,
    data: salesData,
    error: salesError,
  } = useFetchData(user?.role !== TA ? "/api/sales" : "");

  const {
    loading: helpdeskLoading,
    data: helpdeskData,
    error: helpdeskError,
  } = useFetchData(user?.role !== TA ? "/api/helpdesk" : "");

  useEffect(() => {
    if (user?.role !== TA) {
      if (!taLoading && taData && !taError) {
        let options = [];
        taData.data.map((item) => {
          options.push({
            value: item.travel_agent_id,
            label: `${item.pic_name} - ${item.name}`,
          });
        });
        setTaDataOptions(options);
      }
    }
  }, [taLoading, taData]);

  useEffect(() => {
    if (user?.role !== TA) {
      if (!salesLoading && salesData && !salesError) {
        let options = [];
        salesData.data.map((item) => {
          options.push({
            value: item.id,
            label: item.name,
          });
        });
        setSalesDataOptions(options);
      }
    }
  }, [salesLoading, salesData]);

  useEffect(() => {
    if (user?.role !== TA) {
      if (!helpdeskLoading && helpdeskData && !helpdeskError) {
        let options = [];
        helpdeskData.data.map((item) => {
          options.push({
            value: item.id,
            label: item.name,
          });
        });
        setHelpdeskDataOptions(options);
      }
    }
  }, [helpdeskLoading, helpdeskData]);

  useEffect(() => {
    if (genParams) {
      setParam(genParams);
    }
  }, [genParams]);

  const handleDateChange = (dataDate) => {
    const generateParams = {
      ...(selectedTa && { travel_agent_id: selectedTa.value }),
      start_date: dateFormat(dataDate.startDate),
      end_date: dateFormat(dataDate.endDate),
    };
    setDate(dataDate);
    setGenParams(generateParams);
  };

  const handleDownload = () => {
    // Replace with your API endpoint and parameters
    if (!loading && data) {
      const fileName = "report.xls"; // Change the file name and extension as needed

      // Create a link and trigger a download
      const link = document.createElement("a");
      link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const loadOptionsTa = (inputValue, callback) => {
    if (!taData) return [];
    const data = taData.data
      .filter(
        (item) =>
          item.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          item.pic_name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((item) => ({
        label: `${item.pic_name} - ${item.name}`,
        value: item.travel_agent_id,
      }));
    callback(data);
  };

  const loadOptionsSales = (inputValue, callback) => {
    if (!taData) return [];
    const data = taData.data
      .filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));
    callback(data);
  };

  const loadOptionsHelpdesk = (inputValue, callback) => {
    if (!helpdeskData) return [];
    const data = helpdeskData.data
      .filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));
    callback(data);
  };

  const handleFilterTaChange = (filterTa) => {
    const generateParams = {
      travel_agent_id: filterTa ? filterTa.value : "",
      ...(selectedSales && { sales_id: selectedSales.value }),
      start_date: dateFormat(date.startDate),
      end_date: dateFormat(date.endDate),
    };
    setSelectedTa(filterTa);
    setGenParams(generateParams);
  };

  const handleFilterSalesChange = (filterSales) => {
    const generateParams = {
      ...genParams,
      sales_id: filterSales ? filterSales.value : "",
    };
    setSelectedSales(filterSales);
    setGenParams(generateParams);
  };

  const handleFilterHelpdeskChange = (filterHelpdesk) => {
    const generateParams = {
      ...genParams,
      helpdesk_id: filterHelpdesk ? filterHelpdesk.value : "",
    };
    setSelectedHelpdesk(filterHelpdesk);
    setGenParams(generateParams);
  };

  return (
    <div className="min-h-screen">
      <div className="border-b-2 flex justify-between px-5">
        <div className="py-5 items-center mr-2">
          <h1>Laporan</h1>
        </div>
        <div
          className={`flex ${
            user?.role === ACCOUNTING ? "w-full" : "w-1/2"
          } justify-center items-center gap-2`}
        >
          {user?.role !== TA ? (
            <CakraInput>
              <AsyncSelect
                isClearable
                defaultOptions={taDataOptions}
                cacheOptions
                loadOptions={loadOptionsTa}
                onChange={handleFilterTaChange}
                placeholder="travel agent"
              />
            </CakraInput>
          ) : null}
          {user?.role === ACCOUNTING ? (
            <CakraInput>
              <AsyncSelect
                isClearable
                defaultOptions={salesDataOptions}
                cacheOptions
                loadOptions={loadOptionsSales}
                onChange={handleFilterSalesChange}
                placeholder="filter by sales"
              />
            </CakraInput>
          ) : null}
          {user?.role === ACCOUNTING ? (
            <CakraInput>
              <AsyncSelect
                isClearable
                defaultOptions={helpdeskDataOptions}
                cacheOptions
                loadOptions={loadOptionsHelpdesk}
                onChange={handleFilterHelpdeskChange}
                placeholder="filter by helpdesk"
              />
            </CakraInput>
          ) : null}
          <CakraInput>
            <Datepicker
              useRange={false}
              asSingle={false}
              displayFormat="DD MMM YYYY"
              popoverDirection="down"
              inputClassName={
                "bg-inherit  text-black w-full border-inherit rounded-lg"
              }
              placeholder={"Pilih tanggal"}
              value={date}
              onChange={handleDateChange}
              containerClassName="relative bg-white"
              readOnly={true}
            />
          </CakraInput>
        </div>
      </div>

      <Tabs isLazy className="py-4">
        <div className="flex justify-between border-b-2">
          <TabList width={"100%"} borderColor={"transparent"}>
            <Tab>Check in</Tab>
            <Tab>Transaksi</Tab>
            {user.role === "helpdesk" && <Tab>ECYSYS</Tab>}
          </TabList>
          <Link onClick={handleDownload} className="px-2 pb-2">
            <Button
              colorScheme="green"
              variant={"solid"}
              isDisabled={data === null || data === "" || data.length <= 0}
            >
              Download Report
            </Button>
          </Link>
        </div>

        <TabPanels>
          <TabPanel p={0} py={2}>
            <LogTable
              api="/api/report-log-check-in"
              columns={ColumnsGenReportCheckin}
              params={{
                ...(selectedTa && { travel_agent_id: selectedTa.value }),
                start_date: dateFormat(date.startDate),
                end_date: dateFormat(date.endDate),
              }}
            />
          </TabPanel>
          <TabPanel p={0} py={2}>
            {/* alamat */}
            <LogTable
              api="/api/report-log-transactions"
              columns={ColumnsGenLogTransaksi}
              params={{
                ...(selectedTa && { travel_agent_id: selectedTa.value }),
                start_date: dateFormat(date.startDate),
                end_date: dateFormat(date.endDate),
              }}
            />
          </TabPanel>
          <TabPanel p={0} py={2}>
            <ReportEcysysTable
              api="/api/report-log-ecsys"
              columns={ColumnsGenReportECYSYS}
              params={{
                date: dateFormat(date.startDate),
              }}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <LoadingOverlay />
    </div>
  );
};

export default Report;
