import React from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { Input } from "./ui/Input";
import { cn } from "../lib/utils";

const SearchBar = ({
  query,
  setQuery,
  paramDate,
  handleDateChange,
  isRangeDate = false,
  isSingleDate = true,
  outerClassName,
  innerClassName,
  datepickerPlaceholder = "search reservasi",
  searchPlaceholder = "Pencarian nama kelompok, pelanggan",
  withDate = true,
}) => {
  return (
    <>
      <div className={cn("flex flex-row gap-3 basis-3/5", outerClassName)}>
        <div className={cn("relative w-full basis-4/5", innerClassName)}>
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <Input
            type="text"
            value={query}
            placeholder={searchPlaceholder}
            onChange={(e) => setQuery(e.target.value)}
            className="ps-10"
          />
        </div>
        {withDate ? (
          <Datepicker
            useRange={isRangeDate}
            asSingle={isSingleDate}
            displayFormat="DD MMM YYYY"
            popoverDirection="down"
            inputClassName={
              "bg-inherit  text-black w-full border-inherit rounded-lg"
            }
            placeholder={datepickerPlaceholder}
            value={paramDate}
            onChange={handleDateChange}
            containerClassName="relative w-full bg-white basis-2/5"
            readOnly={true}
          />
        ) : null}
      </div>
    </>
  );
};

export default SearchBar;
