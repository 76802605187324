import React, { useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/Card";
import { ACC_ROLE, NOTE_ACTIONS, NOTE_CATEGORY } from "../../lib/constant";
import CakraInput from "../../components/CakraInput";
import { Controller, useForm } from "react-hook-form";
import { useApiPost } from "../../hooks/useApiPost";
import toastHandler from "../../components/toastHandler";
import { Select } from "chakra-react-select";
import { useApiPut } from "../../hooks/useApiPut";

const ModalAccount = ({
  isOpen,
  onToggleModal,
  data,
  isCreate = true,
  refetch,
}) => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    handlePost,
    loading: loadingSubmit,
    data: dataSubmit,
    error: errorSubmit,
  } = useApiPost();
  const {
    handlePut,
    loading: loadingUpdate,
    data: dataUpdate,
    error: errorUpdate,
  } = useApiPut();
  useEffect(() => {
    if (!loadingSubmit && dataSubmit) {
      onToggleModal();
      refetch();
    }
    toastHandler({
      data: dataSubmit,
      error: errorSubmit,
      loading: loadingSubmit,
      toast: toast,
      title: `Berhasil disimpan `,
    });
  }, [loadingSubmit, dataSubmit, errorSubmit]);

  useEffect(() => {
    if (!loadingUpdate && dataUpdate) {
      onToggleModal();
      refetch();
      reset();
      resetField("selecRole", { defaultValue: null });
    }
    toastHandler({
      data: dataUpdate,
      error: errorUpdate,
      loading: loadingUpdate,
      toast: toast,
      title: `Berhasil diupdate`,
    });
  }, [loadingUpdate, dataUpdate, errorUpdate]);

  useEffect(() => {
    if (data) {
      setValue("name", data.name);
      setValue(
        "selectRole",
        isCreate
          ? null
          : _.find(ACC_ROLE, {
              value: data.role,
            })
      );
      setValue("email", data.email);
      setValue("username", data.username || "");
    }
  }, [data]);

  const onSubmit = (formData) => {
    const { email, name, username, pass, phone, selectRole } = formData;
    const payload = {
      ...(!isCreate && { id: data?.id }),
      email: email,
      username: username,
      name: name,
      password: pass,
      role: selectRole?.value || "",
      phone: phone,
    };

    if (isCreate) {
      handlePost("/api/users", payload);
    } else {
      handlePut("/api/users", payload);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      size={"2xl"}
      onClose={() => {
        onToggleModal();
        reset();
        resetField("selecRole", { defaultValue: null });
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={"lg"} className="border-b-2 my-2 !font-bold">
            {"Tambah Akun"}
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody p={0}>
            <div className="flex flex-row justify-between px-2 rounded-sm">
              <Card
                x-chunk="dashboard-07-chunk-3"
                className={`flex border-none shadow-none bg-gray-50 w-full`}
              >
                <div className="w-full">
                  <CardHeader className="py-4 pb-3 px-4">
                    <CardTitle className="flex flex-col items-start gap-2 text-lg">
                      Akun user Palmeera Lounge
                    </CardTitle>
                  </CardHeader>
                  <CardContent className="p-0 flex flex-row justify-between">
                    <div className="flex flex-col p-4 pt-0 text-gray-500 basis-1/2 gap-1">
                      <CakraInput
                        label="Nama"
                        {...register("name", { required: true })}
                      />
                      <CakraInput
                        label="Username"
                        {...register("username", { required: true })}
                        isDisabled={!isCreate}
                      />
                      <CakraInput
                        label="Email"
                        {...register("email", {
                          required: true,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "isi email yang benar",
                          },
                        })}
                        error={errors.email}
                      />
                      {/* <CakraInput
                        label={"Nomor HP Aktif"}
                        {...register("phone", {
                          required: true,
                          pattern: {
                            value: /^(?:\+62|62|0)8[1-9][0-9]{6,11}$/,
                            message: "tolong isikan nomor hp yang benar",
                          },
                        })}
                        error={errors.phone}
                        placeholder="Dimulai dengan 0 atau 62"
                      /> */}
                    </div>
                    <div className="flex flex-col p-4 pt-0 text-gray-500 basis-1/2 gap-1">
                      <CakraInput
                        label="Buat Kata Sandi"
                        {...register("pass", {
                          required: true,
                        })}
                      />
                      <CakraInput
                        label="Ulang kata sandi"
                        {...register("passConfirm", {
                          required: true,
                          validate: (value, formValue) => {
                            if (value !== formValue.pass) {
                              return "isi password yang sama";
                            }
                          },
                        })}
                        error={errors.passConfirm}
                      />

                      <CakraInput label="Role">
                        <Controller
                          name="selectRole"
                          control={control}
                          defaultValue={
                            isCreate
                              ? null
                              : _.find(ACC_ROLE, {
                                  value: data?.role,
                                })
                          }
                          rules={{
                            required: {
                              message: "pilih salah satu",
                            },
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              isClearable
                              options={ACC_ROLE}
                              chakraStyles={{
                                input: (prev, { selectProps }) => ({
                                  ...prev,
                                  boxShadow: "none !important",
                                }),
                              }}
                              isDisabled={!isCreate}
                            />
                          )}
                        />
                      </CakraInput>
                    </div>
                  </CardContent>
                </div>
              </Card>
            </div>
          </ModalBody>

          <ModalFooter px={"0.5rem"}>
            <Button
              width="50%"
              colorScheme="green"
              size={"sm"}
              type="submit"
              isDisabled={loadingSubmit}
            >
              {"Simpan"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default ModalAccount;
