import React from "react";
import { Box, Grid, GridItem, Link, Text } from "@chakra-ui/react";
import { numberToIDR } from "../lib/utils";
import { useFetchDataDashboard } from "../hooks/useFetchDataDashboard";

const InfoCard = ({ idDeposit, travelName, deposit, bukti, status }) => {
  const {
    loading: decodeLoading,
    data: decodeFile,
    error: decodeError,
  } = useFetchDataDashboard("/api/file-deposit-contract", { id: idDeposit });

  const downloadImage = () => {
    const fileName = "bukti.pdf"; // Change the file name and extension as needed

    // Create a link and trigger a download

    if (!decodeLoading && decodeFile) {
      console.log(decodeFile);
      const link = document.createElement("a");
      link.href = `${decodeFile}`;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Box p={5} borderWidth="1px" borderRadius="md">
      <Grid templateColumns="auto auto 1fr" gap={4}>
        <GridItem display="flex" alignItems="center">
          <Text fontWeight="bold">Nama Travel Agent</Text>
        </GridItem>
        <GridItem>
          <Text mx={2}>:</Text>
        </GridItem>
        <GridItem>
          <Text>{travelName || "-"}</Text>
        </GridItem>

        <GridItem display="flex" alignItems="center">
          <Text fontWeight="bold">Besaran Deposit</Text>
        </GridItem>
        <GridItem>
          <Text mx={2}>:</Text>
        </GridItem>
        <GridItem>
          <Text>{deposit ? numberToIDR(deposit) : "-"}</Text>
        </GridItem>
        <GridItem display="flex" alignItems="center">
          <Text fontWeight="bold">Bukti penerimaan dana</Text>
        </GridItem>
        <GridItem>
          <Text mx={2}>:</Text>
        </GridItem>
        <GridItem>
          <Link color="teal.500" onClick={downloadImage}>
            {!decodeLoading && decodeFile ? (
              <div>
                <button>Download Bukti</button>
              </div>
            ) : (
              <p>No bukti image available</p>
            )}
          </Link>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default InfoCard;
