import React, { useEffect } from "react";
import moment from "moment";
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { ColumnsHelpdeskTransaksi, ColumnsTransaksi } from "./Columns";
import DataTable from "../../components/ui/dataTable/DataTable";
import { Input } from "../../components/ui/Input";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";
import { TA } from "../../lib/constant";
import "moment/min/locales";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TaModal from "./TaModal";
import { useAuth } from "../../hooks/useAuth";
import { numberToIDR } from "../../lib/utils";
import { ChevronsRight } from "lucide-react";
import { useSnap } from "../../hooks/useSnap";

function TaTiket() {
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [query, setQuery] = React.useState("");
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [modalData, setModalData] = React.useState();
  const [dataUser, setDataUser] = React.useState([]);
  const [isCreated, setIsCreated] = React.useState("created");
  const { loading, data, error, setParam } = useFetchDataDashboard(
    "/api/ticket-transactions",
    {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
    }
  );
  const {
    loading: saldoDepositLoading,
    data: saldoDepositData,
    error: saldoDepositError,
  } = useFetchDataDashboard("/api/deposit");
  const { user } = useAuth();
  const { onOpen, isOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { snap, snapPopup } = useSnap();

  useEffect(() => {
    if (!loading && data) {
      setDataUser(data.data || []);
    }
  }, [data]);

  useEffect(() => {
    setParam({
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
    });
  }, [pagination, query]);

  const onToggleModal = ({ row } = {}) => {
    if (isOpen) {
      setModalData(null);
      onClose();
    } else {
      setModalData(row);
      onOpen();
    }
  };

  const handleSnapShow = (snapToken) => {
    snap.pay(snapToken, {
      onSuccess: function (result) {
        navigate(`/travel-agent/pembelian-tiket`, {
          replace: true,
        });
        console.log("success", JSON.stringify(result));
      },
      onPending: function (result) {
        navigate(`/travel-agent/pembelian-tiket`, {
          replace: true,
        });
        console.log("pending", JSON.stringify(result));
      },
      onError: function (result) {
        console.log("error", JSON.stringify(result));
        snap.hide();
      },
      onClose: function () {
        console.log("customer closed the popup without finishing the payment");
      },
    });
  };

  const table = useReactTable({
    data: dataUser,
    columns: user?.role === TA ? ColumnsTransaksi : ColumnsHelpdeskTransaksi,
    rowCount: data?.total_data || 0, // Set the total rows based on
    // pageCount: Math.ceil(data?.total / pagination.pageSize) || 0, // Set the total pages based on your data
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    onToggleModal: onToggleModal,
    handleSnapShow: handleSnapShow,
    state: {
      columnFilters,
      rowSelection,
      pagination,
    },
  });

  return (
    <div className="min-h-screen">
      <div className="border-b-2 flex justify-between px-5">
        <div className="py-5">
          <h1>Selamat datang di Admin Dashboard</h1>
          <p>{moment().format("DD MMM YYYY, HH:mm") + " WIB"}</p>
        </div>
        <div className="flex flex-row space-x-4 text-center m-5">
          <div className="bg-green-100 rounded-lg px-4 py-5">
            <h1 className="font-bold text-4xl">
              {!saldoDepositLoading && saldoDepositData
                ? numberToIDR(saldoDepositData)
                : 0}
            </h1>
            <Link to="/travel-agent/partnership">
              <p className="flex flex-row justify-center items-center">
                Saldo Deposit{" "}
                <ChevronsRight className="h-5 w-5 text-blue-500" />
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 justify-between p-5">
        <div className="flex flex-row gap-3">
          <div className="relative w-full">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <Input
              type="text"
              placeholder="Pencarian nama kelompok, pelanggan"
              onChange={(e) => setQuery(e.target.value)}
              className="ps-10"
            />
          </div>
        </div>
        <div className="flex justify-end gap-1">
          <Link to={`form/${isCreated}`}>
            <button
              type="button"
              className="flex h-full gap-1 items-center px-5 border font-semibold rounded-lg bg-white border-tropicGreen500 text-tropicGreen500"
            >
              Pesan Lounge
            </button>
          </Link>
        </div>
      </div>
      <div id="content-table">
        <DataTable columns={ColumnsTransaksi} table={table} loading={loading} />
      </div>
      <TaModal onToggleModal={onToggleModal} isOpen={isOpen} data={modalData} />
      {/* <Outlet /> */}
    </div>
  );
}

export default TaTiket;
