import { useState } from "react";

const useFileUpload = () => {
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setPreviews((prevPreviews) => [...prevPreviews, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setPreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
  };

  const resetFileChange = () => {
    setFiles([]);
    setPreviews([]);
  };

  return {
    files,
    previews,
    handleFileChange,
    handleRemoveFile,
    resetFileChange,
  };
};

export default useFileUpload;
