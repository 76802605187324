import React from "react";
import { Box, Grid, GridItem, Link, Text } from "@chakra-ui/react";

const InfoRateCode = ({ rateCodeName, rateCodeDiscount, status }) => {
  return (
    <Box p={5} borderWidth="1px" borderRadius="md">
      <Grid templateColumns="auto auto 1fr" gap={4}>
        <GridItem display="flex" alignItems="center">
          <Text fontWeight="bold">Nama Rate Code</Text>
        </GridItem>
        <GridItem>
          <Text mx={2}>:</Text>
        </GridItem>
        <GridItem>
          <Text>{rateCodeName || "-"}</Text>
        </GridItem>

        <GridItem display="flex" alignItems="center">
          <Text fontWeight="bold">Rate Code Diskon</Text>
        </GridItem>
        <GridItem>
          <Text mx={2}>:</Text>
        </GridItem>
        <GridItem>
          <Text>{rateCodeDiscount || "-"}</Text>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default InfoRateCode;
