import { Box, Spinner, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { timeFormat } from "../../lib/utils";

const CheckInMessages = ({ scannedDataRef, loading, data, error }) => {
  if (loading) return <div></div>;
  if (error)
    return (
      <CheckInMessage
        success={error.success}
        data={error.data}
        errorMsg={error.message}
        scannedDataRef={scannedDataRef}
      />
    );
  if (data)
    return (
      <CheckInMessage
        success={data.success}
        data={data.data}
        scannedDataRef={scannedDataRef}
      />
    );
};

const CheckInMessage = ({ data, scannedDataRef, errorMsg, success }) => {
  const skus = data?.sku.split("_");
  const typeSku = skus[0];
  const sku = skus[1];
  return (
    <Stack spacing={1} className="items-center">
      <Box className="text-center mt-2 bg-gray-50 py-1 px-2 text-systemGrey">
        {scannedDataRef && <Text>{scannedDataRef.current}</Text>}
      </Box>
      <Box
        className={`${
          success ? "bg-[#EFFAF5] text-tropicGreen" : "bg-red-50 text-red-500"
        } text-5xl font-bold px-6 py-4 text-center`}
      >
        {success ? "Kode Booking Valid" : "Kode Booking Invalid"}
      </Box>
      <Text className="text-gray-500 text-center">{`Check in ${timeFormat(
        data.updated_at
      )}`}</Text>

      <Stack direction="row" className="justify-center space-x-24 my-5 ">
        <Stack>
          <Text className="text-gray-700 text-lg">
            {data?.group_name || "-"}
          </Text>
          <Text className="text-base font-medium">
            {data?.travel_agent_name || "-"}
          </Text>
        </Stack>
        <Stack>
          <Text className="text-gray-700 text-lg">
            {sku ? `1 ${sku}` : "-"}
          </Text>
          <Text className="text-base font-medium">{typeSku || "-"}</Text>
        </Stack>
      </Stack>
      {!success && (
        <Box className="text-red-600 font-semibold px-6 py-4">{errorMsg}</Box>
      )}
      <Box className="text-center">
        <Text className="font-medium">Tour Leader</Text>
        <Text className="text-gray-700 text-lg font-semibold">
          {data?.tour_leader_name || "-"}
        </Text>
        <Text className="text-base font-medium">
          {data?.phone_number || "-"}
        </Text>
      </Box>
    </Stack>
  );
};

export default CheckInMessages;
