import { useEffect, useState } from "react";

export const useSnap = () => {
  const [snap, setSnap] = useState();
  useEffect(() => {
    const midtransScriptUrl = process.env.REACT_APP_MIDTRANS_SCRIPT_URL;
    let scriptTag = document.createElement("script");
    scriptTag.src = midtransScriptUrl;

    // Optional: set script attribute, for example snap.js have data-client-key attribute
    // (change the value according to your client-key)
    const myMidtransClientKey = process.env.REACT_APP_MIDTRANS_CLIENT_KEY;
    scriptTag.setAttribute("data-client-key", myMidtransClientKey);
    scriptTag.async = true;
    scriptTag.onload = () => {
      setSnap(window.snap);
    };

    document.body.appendChild(scriptTag);

    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);

  const snapPopup = (snapToken, action) => {
    if (snap) {
      snap.pay(snapToken, {
        onSuccess: function (result) {
          action.onSuccess(result);
        },
        onPending: function (result) {
          action.onPending(result);
        },
        onError: function (result) {
          action.onError(result);
        },
        onClose: function (result) {
          action.onClose(result);
          console.log(
            "customer closed the popup without finishing the payment"
          );
        },
      });
    }
  };

  return { snap, snapPopup };
};
