import React from "react";
import CakraInput from "../../components/CakraInput";
import { Button, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { numberToIDR } from "../../lib/utils";
import { ArrowLeftIcon } from "lucide-react";

const METODE_PEMBAYARAN = [
  { id: "helpdesk_cash", label: "Cash" },
  { id: "helpdesk_debit", label: "Kartu Debit via EDC" },
  { id: "helpdesk_qris", label: "QRIS" },
  { id: "helpdesk_va", label: "VA/Transfer Bank" },
  { id: "helpdesk_credit_card", label: "Kartu Kredit" },
];

const PaymentForm = ({
  checked,
  setChecked,
  register,
  totalPrice,
  onHandleBack,
}) => {
  return (
    <>
      <div className="py-4">
        <h2>Pilih Metode Pembayaran</h2>
        <p>
          Bantu tour leader untuk menentukan metode paling cepat dan efisien
        </p>
      </div>
      <Stack spacing={5}>
        {METODE_PEMBAYARAN.map((value, idx) => {
          return (
            <React.Fragment key={`checkbox-${idx}`}>
              <RadioGroup onChange={setChecked} value={checked}>
                <Radio colorScheme="green" value={value.id}>
                  {value.label}
                </Radio>
              </RadioGroup>
              <RenderForm
                checked={checked}
                value={value}
                register={register}
                totalPrice={totalPrice}
              />
            </React.Fragment>
          );
        })}
      </Stack>
      <Button
        leftIcon={<ArrowLeftIcon />}
        border={0}
        backgroundColor={"transparent"}
        textColor={"black"}
        _hover={{ opacity: "70%" }}
        mt={"2rem"}
        pl={0}
        onClick={() => onHandleBack()}
      >
        Ubah data pesanan
      </Button>
    </>
  );
};

const RenderForm = ({ checked, value, register, totalPrice }) => {
  if (checked === value.id) {
    if (checked === "helpdesk_cash") {
      return (
        <CakraInput
          label="Jumlah Uang yang dibayarkan (IDR)"
          placeholder="input bayar"
          value={numberToIDR(totalPrice)}
          isDisabled
        />
      );
    }
    if (checked === "helpdesk_debit" || checked === "helpdesk_credit_card") {
      return (
        <Stack>
          <CakraInput label="Bank Asal" {...register("bank")} />
          <CakraInput label="Nomor Kartu" {...register("cardNumber")} />
          <CakraInput
            label="Jumlah Uang yang dibayarakan (IDR)"
            value={numberToIDR(totalPrice)}
            isDisabled
          />
          <CakraInput
            label="Nomor referensi transaksi"
            {...register("noRevTransaction")}
          />
        </Stack>
      );
    }

    if (checked === "helpdesk_qris" || checked === "helpdesk_va") {
      return (
        <>
          <CakraInput
            label="Nomor referensi transaksi"
            {...register("noRevTransaction")}
          />
          <CakraInput
            label="Jumlah Uang yang dibayarkan (IDR)"
            value={numberToIDR(totalPrice)}
            isDisabled
          />
        </>
      );
    }

    return null;
  }
  return null;
};

export default PaymentForm;
