import React from "react";
import { Outlet } from "react-router-dom";
import NavigationBar from "../../components/NavigationBar";
import { LoadingProvider } from "../../hooks/LoadingContext";
import LoadingOverlay from "../../components/ui/LoadingOverlay";

const AdminPanel = ({ children }) => {
  return (
    <>
      <LoadingProvider>
        <NavigationBar />
        <main className="sm:ml-64 flex-1 bg-[#F4FBFA]">
          <Outlet />
        </main>
      </LoadingProvider>
    </>
  );
};

export default AdminPanel;
