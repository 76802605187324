import React, { useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useToast,
} from "@chakra-ui/react";
import { Card, CardContent } from "../../components/ui/Card";
import LabelWrapper from "../../components/ui/LabelWrapper";
import CakraInput from "../../components/CakraInput";
import { useForm } from "react-hook-form";
import { useApiPost } from "../../hooks/useApiPost";
import toastHandler from "../../components/toastHandler";
import { dateFormat } from "../../lib/utils";
import { SKU_PREMIUM } from "../../lib/constant";

const HdModalForm = ({ onToggleModal, isOpen, data, refetch }) => {
  const [isDisabled, setIsDisabled] = React.useState();
  const { register, handleSubmit, watch, setValue, getValues } = useForm();
  const {
    handlePost,
    loading: checkInLoading,
    data: checkInData,
    error: checkInError,
  } = useApiPost();
  const toast = useToast();

  useEffect(() => {
    const watchFields = watch(["vip", "regular"]);
    const isDisabled =
      watchFields.some((value) => isNaN(value) || value === undefined) ||
      (watch("vip") === 0 && watch("regular") === 0);
    setIsDisabled(isDisabled);
  }, [watch("vip"), watch("regular")]);

  useEffect(() => {
    if (!checkInLoading && checkInData) {
      refetch();
      setValue("vip", 0);
      setValue("regular", 0);
    }
    toastHandler({
      data: checkInData,
      error: checkInError,
      loading: checkInLoading,
      toast: toast,
    });
  }, [checkInData, checkInLoading, checkInError]);

  const onSubmit = (formData) => {
    const { vip, regular } = formData;
    const { reservation_id } = data;
    const payload = {
      vip,
      regular,
      reservation_id,
    };
    onToggleModal();
    handlePost("/api/check-in", payload);
  };

  const preventNegativeValue = (e) => {
    if (e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };

  const onVipChange = (e) => {
    const max = data?.status_checkin?.available_vip_ticket;
    const defaultValue = parseInt(0);
    if (e.target.value < 0) {
      setValue("vip", parseInt(0));
    }

    if (max >= 0) {
      if (e.target.value > max) {
        setValue("vip", max || defaultValue);
      }
    }
    setValue("vip", parseInt(e.target.value));
  };

  const onRegularChange = (e) => {
    const max = data?.status_checkin?.available_regular_ticket;
    const defaultValue = parseInt(0);
    if (e.target.value < 0) {
      setValue("regular", parseInt(0));
    }

    if (max >= 0) {
      if (e.target.value > max) {
        setValue("regular", max || defaultValue);
      }
    }
    setValue("regular", parseInt(e.target.value));
  };

  const onVipIncrease = () => {
    const max = data?.status_checkin?.available_vip_ticket;
    if (parseInt(getValues("vip") || 0) < max) {
      let incr = parseInt(
        isNaN(getValues("vip")) ? 0 + 1 : getValues("vip") + 1
      );
      setValue("vip", incr);
    }
  };

  const onVipDecrease = () => {
    setValue(
      "vip",
      getValues("vip") > 0 ? parseInt(getValues("vip") - 1) : parseInt(0)
    );
  };

  const onRegularIncrease = () => {
    const max = data?.status_checkin?.available_regular_ticket;
    const value = parseInt(getValues("regular") || 0);
    if (value < max) {
      let incr = parseInt(
        isNaN(getValues("regular")) ? 0 + 1 : getValues("regular") + 1
      );
      setValue("regular", incr);
    }
  };

  const onRegularDecrease = () => {
    setValue(
      "regular",
      getValues("regular") > 0
        ? parseInt(getValues("regular") - 1)
        : parseInt(0)
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        size={"xl"}
        onClose={(e) => {
          // e.preventDefault();
          onToggleModal();
          setValue("vip", 0);
          setValue("regular", 0);
        }}
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalCloseButton left={0} />
            <ModalHeader className="border-b-2 my-2"></ModalHeader>
            <ModalBody p={0}>
              <div className="flex justify-between px-2">
                <Card
                  x-chunk="dashboard-07-chunk-3"
                  className="w-full bg-gray-50"
                >
                  <CardContent className="p-0">
                    <div className="flex flex-row">
                      <div className="flex flex-col sm:gap-3 p-4 text-gray-500 basis-2/5">
                        <h3 className="text-gray-700 text-lg font-bold capitalize">
                          Check in
                        </h3>
                        <LabelWrapper className="flex-col uppercase text-sm font-bold">
                          Nama Group
                          <h3 className="font-normal text-gray-700 capitalize">
                            {data?.group_name || "-"}
                          </h3>
                        </LabelWrapper>
                        <LabelWrapper className="flex-col uppercase text-sm font-bold">
                          Nama Leader
                          <h3 className="font-normal text-gray-700 capitalize">
                            {data?.tour_leader_name || "-"}
                          </h3>
                        </LabelWrapper>
                        <LabelWrapper className="flex-col uppercase text-sm font-bold">
                          Nomor Ponsel Leader
                          <h3 className="font-normal text-gray-700 capitalize">
                            {data?.phone_number || "-"}
                          </h3>
                        </LabelWrapper>
                        <LabelWrapper className="flex-col uppercase text-sm font-bold">
                          Total Pesanan
                          <h3 className="font-normal text-gray-700 capitalize">
                            {`${data?.tickets.total} pax` || "-"}
                          </h3>
                        </LabelWrapper>
                      </div>
                      <div className="flex flex-col sm:gap-3 p-4 text-gray-500 basis-3/5">
                        <div className="flex flex-row w-full gap-3">
                          <LabelWrapper className="flex-col basis-1/2 max-w-52 uppercase text-sm font-bold gap-1">
                            Kode Booking
                            <h3 className="font-normal text-gray-700 capitalize text-wrap">
                              {data?.reservation_id || "-"}
                            </h3>
                          </LabelWrapper>
                          <LabelWrapper className="flex-col basis-1/2 uppercase text-sm font-bold gap-1">
                            Date Issued
                            <h3 className="font-normal text-wrap max-w-20 text-gray-700 capitalize">
                              {data?.use_date
                                ? dateFormat(data.use_date)
                                : "" || "-"}
                            </h3>
                          </LabelWrapper>
                        </div>
                        <CakraInput
                          label={`Jumlah tiket ${SKU_PREMIUM}`}
                          labelHelp={`Tersedia ${data?.status_checkin?.available_vip_ticket}`}
                        >
                          <NumberInput
                            min={0}
                            max={data?.status_checkin?.available_vip_ticket}
                          >
                            <NumberInputField
                              bgColor="white"
                              {...register("vip", {
                                valueAsNumber: true,
                                onChange: onVipChange,
                              })}
                              onKeyDown={preventNegativeValue}
                            />
                            <NumberInputStepper>
                              <NumberIncrementStepper onClick={onVipIncrease} />
                              <NumberDecrementStepper onClick={onVipDecrease} />
                            </NumberInputStepper>
                          </NumberInput>
                        </CakraInput>
                        <CakraInput
                          label={"Jumlah tiket Reguler"}
                          labelHelp={`Tersedia ${data?.status_checkin?.available_regular_ticket}`}
                        >
                          <NumberInput
                            min={0}
                            max={data?.status_checkin?.available_regular_ticket}
                          >
                            <NumberInputField
                              bgColor="white"
                              {...register("regular", {
                                valueAsNumber: true,
                                onChange: onRegularChange,
                              })}
                              onKeyDown={preventNegativeValue}
                            />
                            <NumberInputStepper>
                              <NumberIncrementStepper
                                onClick={onRegularIncrease}
                              />
                              <NumberDecrementStepper
                                onClick={onRegularDecrease}
                              />
                            </NumberInputStepper>
                          </NumberInput>
                        </CakraInput>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                {/* side content */}
              </div>
            </ModalBody>

            <ModalFooter justifyContent={"space-between"} px={"0.5rem"}>
              {/* <Button
                colorScheme="orange"
                variant="solid"
                size={"sm"}
                gap={"0.25rem"}
                onClick={() => {
                  console.log("WIP");
                }}
              >
                <PrinterIcon size={"14px"} /> Print Code Booking
              </Button> */}
              <Button
                type="submit"
                colorScheme="green"
                mr={3}
                size={"sm"}
                isDisabled={isDisabled}
              >
                {!checkInLoading ? "Check in sekarang" : "Loading"}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};

export default HdModalForm;
