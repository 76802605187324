import PropTypes from "prop-types";
import "moment/locale/id";
import {
  Badge,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { dateFormat, defineCheckInStatus, defineStatus } from "../../lib/utils";
import { EllipsisVertical } from "lucide-react";
import { SETTLEMENT, SKU_PREMIUM } from "../../lib/constant";

export const ColumnsTransaksi = [
  {
    accessorKey: "reservation_id",
    header: "Kode Booking",
    cell: ({ row, table }) => {
      const tiket = row.original;
      return (
        <>
          <button
            onClick={() => {
              table.options.onToggleModal({ row: tiket });
            }}
            className="bg-transparent border-none text-left"
          >
            <div className="text-systemAzure">{tiket.reservation_id}</div>
            <p>{dateFormat(tiket.created_at)}</p>
          </button>
        </>
      );
    },
  },
  {
    accessorKey: "group_name",
    header: "nama group umroh",
    cell: ({ row }) => {
      const tiket = row.original;
      return (
        <>
          <div>{row.getValue("group_name")}</div>
          <p className="text-sm">{dateFormat(tiket.use_date)}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tour_leader_name",
    header: "Tour Leader",
    cell: ({ row }) => {
      const person = row.original;
      return (
        <>
          <div>{person.tour_leader_name}</div>
          <p className="text-sm">{person.phone_number}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tickets",
    header: "Total Tiket",
    cell: ({ row }) => {
      const tiket = row.getValue("tickets");
      return (
        <>
          <div>{tiket.total}</div>
          <div>
            <p className="text-sm">
              {tiket.vip +
                " " +
                SKU_PREMIUM +
                ", " +
                tiket.regular +
                " Regular"}
            </p>
            {/* <p className="text-sm">{}</p> */}
          </div>
        </>
      );
    },
  },
  {
    accessorKey: "status",
    header: "Status (Pembayaran & Penggunaan)",
    cell: ({ row }) => {
      const status = row.getValue("status");
      const items = row.original;
      return (
        <div className="flex flex-col gap-1">
          {defineStatus(status)} {defineCheckInStatus(items.status_checkin)}
        </div>
      );
    },
  },
  {
    accessorKey: "action",
    header: "",
    cell: ({ table, row }) => {
      const items = row.original;
      const menuItems = [];

      if (
        items.payment_midtrans_token &&
        items.payment_status.status !== SETTLEMENT
      ) {
        menuItems.push(
          <MenuItem
            key={`menu-${menuItems.length + 1}`}
            onClick={() => {
              table.options.handleSnapShow(items.payment_midtrans_token);
            }}
            closeOnSelect={true}
          >
            Lanjut Pembayaran
          </MenuItem>
        );
      }

      return (
        <Menu>
          <MenuButton
            as={Button}
            size={"xs"}
            bgColor="transparent"
            isDisabled={menuItems.length <= 0}
          >
            <EllipsisVertical size={18} />
          </MenuButton>
          <MenuList fontWeight={500}>{menuItems}</MenuList>
        </Menu>
      );
    },
  },
];

export const ColumnsHelpdeskTransaksi = [
  {
    accessorKey: "order_id",
    header: "Kode Booking",
    cell: ({ row, table }) => {
      const tiket = row.original;
      return (
        <>
          <button
            onClick={() => {
              table.options.onToggleModal({ row: tiket });
            }}
            className="bg-transparent border-none"
          >
            <div className="text-systemAzure">{tiket.order_id}</div>
            <p>{tiket.created_at}</p>
          </button>
        </>
      );
    },
  },
  {
    accessorKey: "nama_group",
    header: "nama group umroh",
    cell: ({ row }) => {
      const tiket = row.original;
      return (
        <>
          <div className="text-systemAzure">{row.getValue("nama_group")}</div>
          <p>{tiket.tgl_dipakai}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tour_leader",
    header: "Tour Leader",
    cell: ({ row }) => {
      const person = row.original;
      return (
        <>
          <div>{person.tour_leader}</div>
          <p className="text-sm">{person.phone_number}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tikets",
    header: "Total Tiket",
    cell: ({ row }) => {
      const tiket = row.getValue("tikets");
      return (
        <>
          <div>{tiket.total}</div>
          <div>
            <p className="text-sm">
              {tiket.vip +
                " " +
                SKU_PREMIUM +
                ", " +
                tiket.regular +
                " Regular"}
            </p>
            {/* <p className="text-sm">{}</p> */}
          </div>
        </>
      );
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const status = row.getValue("status");
      return defineStatus(status);
    },
  },
];

export const ColumnsReportCheckin = [
  {
    accessorKey: "order_id",
    header: "Kode Booking",
    cell: ({ row, table }) => {
      const tiket = row.original;
      return (
        <>
          <button
            onClick={() => {
              table.options.onToggleModal({ row: tiket });
            }}
            className="bg-transparent border-none"
          >
            <div className="text-systemAzure">{tiket.order_id}</div>
            <p>{tiket.created_at}</p>
          </button>
        </>
      );
    },
  },
  {
    accessorKey: "nama_group",
    header: "nama group umroh",
    cell: ({ row }) => {
      const tiket = row.original;
      return (
        <>
          <div className="text-systemAzure">{row.getValue("nama_group")}</div>
          <p>{tiket.tgl_dipakai}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tour_leader",
    header: "Tour Leader",
    cell: ({ row }) => {
      const person = row.original;
      return (
        <>
          <div>{person.tour_leader}</div>
          <p className="text-sm">{person.phone_number}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tikets",
    header: "Total Tiket",
    cell: ({ row }) => {
      const tiket = row.getValue("tikets");
      return (
        <>
          <div>{tiket.total}</div>
          <div>
            <p className="text-sm">
              {tiket.vip +
                " " +
                SKU_PREMIUM +
                ", " +
                tiket.regular +
                " Regular"}
            </p>
            {/* <p className="text-sm">{}</p> */}
          </div>
        </>
      );
    },
  },
  {
    accessorKey: "status_checkin",
    header: "Status",
    cell: ({ row }) => {
      const status = row.getValue("status_checkin");
      return defineCheckInStatus(status);
    },
  },
  // {
  //   accessorKey: "status",
  //   header: "Status",
  //   cell: ({ row }) => {
  //     const status = row.getValue("status");
  //     return defineStatus(status);
  //   },
  // },
];

// PropTypes for the columns array
ColumnsTransaksi.propTypes = PropTypes.arrayOf(
  PropTypes.shape({
    accessorKey: PropTypes.string,
    header: PropTypes.string,
  })
).isRequired;
