// src/routes/index.js
import React from "react";
import { Routes, Route } from "react-router-dom";
// import PrivateRoute from "./PrivateRoute";
import { ProtectedRoute } from "./ProtectedRoute";
import routesConfig from "./routesConfig";
import NotFound from "../pages/NotFound";

const renderRoutes = (routes) => {
  return routes.map(({ path, element, roles, children }, index) => (
    <Route
      // exacts
      key={index}
      path={path}
      element={
        roles ? (
          <ProtectedRoute roles={roles}>{element}</ProtectedRoute>
        ) : (
          element
        )
      }
    >
      {children && renderRoutes(children)}
    </Route>
  ));
};

const AppRoutes = () => {
  return (
    <Routes>
      {renderRoutes(routesConfig)}
      {/* {routesConfig.map(({ path, element, roles, children }, index) => (
        <Route
          key={index}
          path={path}
          element={
            roles ? (
              <ProtectedRoute roles={roles}>{element}</ProtectedRoute>
            ) : (
              element
            )
          }
        >
          {children &&
            children.map((child, idx) => (
              <Route
                // exact
                // index
                key={idx}
                path={child.path}
                element={child.element}
              />
            ))}
        </Route>
      ))} */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
