import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import "./index.css";
import App from "./App";
import cakraTheme from "./cakraTheme";

import * as Sentry from "@sentry/browser";
import { EnvProvider } from "./hooks/EnvContext";

const integrations = Sentry.getDefaultIntegrations({}).filter(
  (defaultIntegration) => {
    return !["BrowserApiErrors", "Breadcrumbs", "GlobalHandlers"].includes(
      defaultIntegration.name
    );
  }
);

integrations.push(
  Sentry.httpClientIntegration({
    sendDefaultPii: true, // Optional, to include headers and cookies
    failedRequestStatusCodes: [[400, 500]], // Track all client and server errors
    failedRequestTargets: [/\/api\//], // Adjust as needed to match your API
  })
);

Sentry.init({
  dsn: process.env.REACT_APP_GLITCH_DSN,
  environment: process.env.NODE_ENV,
  transport: Sentry.makeFetchTransport,
  stackParser: Sentry.defaultStackParser,
  integrations: integrations,
  tracesSampleRate: 0.1,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <EnvProvider>
    <ChakraProvider theme={cakraTheme}>
      <Router>
        <App />
      </Router>
    </ChakraProvider>
  </EnvProvider>

  // {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
