import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormHelperText,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useToast,
} from "@chakra-ui/react";
import Datepicker from "react-tailwindcss-datepicker";
import { dateFormat, dateUnix, numberToIDR } from "../../lib/utils";
import { useApiPost } from "../../hooks/useApiPost";
import { useSnap } from "../../hooks/useSnap";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/Card";
import { Label } from "../../components/ui/Label";
import LabelWrapper from "../../components/ui/LabelWrapper";
import { IcBaselineVerified } from "../../components/ui/icon";
import "moment/min/locales";
import CakraInput from "../../components/CakraInput";
import { useFetchPrice } from "../../hooks/useFetchPrice";
import { useAuth } from "../../hooks/useAuth";
import { HELPDESK, SKU_PREMIUM, TA } from "../../lib/constant";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import PaymentTypeForm from "../PaymentTypeForm";
import toastHandler from "../../components/toastHandler";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";

const TaFormTiket = () => {
  const location = useLocation();

  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    trigger,
    formState: { errors },
    reset,
    setError,
  } = useForm();

  const toast = useToast();

  const [snapShow, setSnapshow] = useState(false);
  const [vipTotalPrice, setVipTotalPrice] = useState(0);
  const [regularTotalPrice, setRegularTotalPrice] = useState(0);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isPaymentType, setIsPaymentType] = useState(false);

  const {
    loading: loadingPrice,
    data: dataPrice,
    error: errorPrice,
  } = useFetchPrice();
  const {
    handlePost,
    loading: submitLoading,
    data: submitData,
    error: submitError,
  } = useApiPost();
  const { snap, snapPopup } = useSnap();
  const navigate = useNavigate();
  const {
    handlePost: submitDepositPost,
    loading: submitDepositLoading,
    data: submitDepositData,
    error: submitDepositError,
  } = useApiPost();
  const { user } = useAuth();
  const [paymentToken, setPaymentToken] = useLocalStorage("paymentToken", null);

  const {
    loading: saldoDepositLoading,
    data: saldoDepositData,
    refetch: saldoDepositRefetch,
  } = useFetchDataDashboard("/api/deposit");

  useEffect(() => {
    if (watch("vip") || watch("regular")) {
      displayPrice();
    }
  }, [watch("vip"), watch("regular")]);

  useEffect(() => {
    if (!submitLoading && submitData) {
      if (submitData.data) {
        setSnapshow(true);
        setPaymentToken(submitData.data.token);
        handleSnapShow(submitData.data.token);
        reset();
      }
    }
  }, [submitLoading, submitData]);

  useEffect(() => {
    if (!submitDepositLoading && submitDepositData) {
      if (submitDepositData) {
        saldoDepositRefetch();
        toastHandler({
          data: submitDepositData,
          error: null,
          loading: false,
          toast: toast,
          title: "Transaksi berhasil dengan Order Id",
        });
        navigate("/travel-agent/pembelian-tiket", { replace: false });
        reset();
      }
    } else {
      if (!submitDepositLoading && submitDepositError) {
        toastHandler({
          data: submitDepositError,
          error: null,
          loading: false,
          toast: toast,
          title: "Transaksi Gagal",
        });
      }
    }
  }, [submitDepositLoading, submitDepositData]);

  const onSubmit = (data) => {
    const payload = {
      flight_number: data.kodePenerbangan,
      group_name: data.namaGroupUmroh,
      phone_number: data.phone,
      email: data.email,
      total: {
        regular: parseInt(data.regular),
        vip: parseInt(data.vip),
      },
      tour_leader_name: data.namaLeader,
      use_date: dateFormat(data.tglPakai.startDate),
    };

    handlePost(`/api/payment`, payload);
  };

  const preventNegativeValue = (e) => {
    if (e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };

  const onVipChange = (e) => {
    if (e.target.value < 0) {
      setValue("vip", 0);
    }
    setValue("vip", e.target.value);
  };

  const onRegularChange = (e) => {
    if (e.target.value < 0) {
      setValue("regular", 0);
    }
    setValue("regular", e.target.value);
  };

  const onVipIncrease = () => {
    setValue("vip", getValues("vip") + 1);
  };

  const onVipDecrease = () => {
    setValue("vip", getValues("vip") > 0 ? getValues("vip") - 1 : 0);
  };

  const onRegularIncrease = () => {
    setValue("regular", getValues("regular") + 1);
  };

  const onRegularDecrease = () => {
    setValue(
      "regular",
      getValues("regular") > 0 ? getValues("regular") - 1 : 0
    );
  };

  const displayPrice = () => {
    const {
      vip_price,
      vip_discount_price,
      regular_price,
      regular_discount_price,
    } = dataPrice.data || {};

    const discount = Math.ceil(
      watch("vip") * vip_price +
        watch("regular") * regular_price -
        (watch("vip") * vip_discount_price +
          watch("regular") * regular_discount_price)
    );

    const subTotal = Math.ceil(
      watch("vip") * vip_price + watch("regular") * regular_price
    );

    const total = Math.ceil(subTotal - discount);

    switch (user.role) {
      case TA:
        setVipTotalPrice(Math.ceil(watch("vip") * vip_discount_price));
        setRegularTotalPrice(
          Math.ceil(watch("regular") * regular_discount_price)
        );
        setDiscount(discount);
        setSubTotalPrice(subTotal);
        setTotalPrice(total);
        break;
      case HELPDESK:
        setVipTotalPrice(Math.ceil(watch("vip") * vip_price));
        setRegularTotalPrice(Math.ceil(watch("regular") * regular_price));
        setSubTotalPrice(subTotal);
        setTotalPrice(subTotal);
        break;
    }
  };

  const handleCheckMidtransStatus = () => {
    snap.show();
    if (paymentToken !== null) {
      handleSnapShow(paymentToken);
    }
  };

  const handleSnapShow = (snapToken) => {
    snap.pay(snapToken, {
      onSuccess: function (result) {
        navigate("/travel-agent/pembelian-tiket", { replace: true });
        console.log("success", JSON.stringify(result));
        setPaymentToken(null);
      },
      onPending: function (result) {
        navigate("/travel-agent/pembelian-tiket", { replace: true });
        console.log("pending", JSON.stringify(result));
        setPaymentToken(null);
      },
      onError: function (result) {
        console.log("error", JSON.stringify(result));
        setPaymentToken(null);
        setSnapshow(false);
      },
      onClose: function () {
        navigate(`/travel-agent/pembelian-tiket`, {
          replace: true,
        });
        console.log("customer closed the popup without finishing the payment");
      },
    });
  };

  const handleIsPayementType = async () => {
    const validation = await trigger();
    if (parseInt(watch("vip")) === 0 && parseInt(watch("regular")) === 0) {
      setError("vip", {
        type: "manual",
        message: `${SKU_PREMIUM} dan Regular tidak boleh keduanya nol`,
      });
      setError("regular", {
        type: "manual",
        message: `${SKU_PREMIUM} dan Regular tidak boleh keduanya nol`,
      });
    } else if (validation) {
      setIsPaymentType(!isPaymentType);
    }
  };

  const handleDepositPayment = (data) => {
    const payload = {
      flight_number: data.kodePenerbangan,
      group_name: data.namaGroupUmroh,
      phone_number: data.phone,
      email: data.email,
      total: {
        regular: parseInt(data.regular),
        vip: parseInt(data.vip),
      },
      tour_leader_name: data.namaLeader,
      use_date: dateFormat(data.tglPakai.startDate),
    };

    submitDepositPost(`/api/payment-deposit`, payload);
  };

  return (
    <form>
      <div className="w-full grid grid-cols-3">
        <div id="form-1" className="col-span-2 mx-4">
          {/* <div
            className={`text-systemRed  ${
              error != "" || errorForm !== null ? "block" : "hidden"
            }`}
          >
            {error.message || errorForm
              ? error.message || errorForm.message
              : ""}
          </div> */}
          {!isPaymentType ? (
            <>
              <div className="mb-4">
                <h1 className="text-2xl font-normal">Detail Pemesanan</h1>
                <h3 className="text-base font-normal text-systemGrey">
                  Isi formulir ini dengan benar karena e-tiket akan dikirim ke
                  WA / alamat email sesuai data leader.
                </h3>
              </div>
              <div className="flex flex-col gap-4">
                <CakraInput
                  label={"Nama leader"}
                  labelHelp={"Nama lengkap sesuai KTP"}
                  {...register("namaLeader", { required: true })}
                  placeholder="Tulis nama leader umroh"
                  error={errors.namaLeader}
                />
                <CakraInput
                  label={"Nomor ponsel aktif WA"}
                  {...register("phone", {
                    required: true,
                    pattern: {
                      value: /^(?:\+62|62|0)8[1-9][0-9]{6,11}$/,
                      message: "tolong isikan nomor hp yang benar",
                    },
                  })}
                  error={errors.phone}
                  placeholder="Dimulai dengan 0 atau 62"
                />
                <CakraInput
                  label={"Alamat email"}
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "isi email yang benar",
                    },
                  })}
                  maxLength={50}
                  placeholder="Alamat email"
                  error={errors.email}
                />
                <CakraInput
                  label={"Nomor penerbangan"}
                  {...register("kodePenerbangan", { required: true })}
                  placeholder="Isi nomor penerbangan pesawat umroh"
                  error={errors.kodePenerbangan}
                />
                <CakraInput
                  label={"Nama group umroh"}
                  {...register("namaGroupUmroh", { required: true })}
                  placeholder="Isi nama group jamaah umroh dengan unik"
                  error={errors.namaGroupUmroh}
                />
                <CakraInput
                  label={"Waktu Penggunaan area Lounge"}
                  error={errors.tglPakai}
                >
                  <Controller
                    control={control}
                    name="tglPakai"
                    defaultValue={null}
                    rules={{
                      validate: (v) => {
                        if (
                          v === null ||
                          v.startDate === undefined ||
                          v.startDate === null
                        ) {
                          return "tolong isi tanggal dipakai";
                        }
                      },
                    }}
                    render={({ field }) => (
                      <Datepicker
                        useRange={false}
                        asSingle={true}
                        displayFormat="DD MMM YYYY"
                        popoverDirection="up"
                        minDate={new Date()}
                        inputClassName={
                          "bg-inherit text-black w-full border-inherit rounded-lg"
                        }
                        placeholder="Pilih tanggal penggunaan"
                        value={field.value}
                        onChange={(date) => field.onChange(date)}
                        readOnly={true}
                      />
                    )}
                  />
                </CakraInput>
                <Label className="border-b-2 pb-4 text-lg font-semibold">
                  Pilih tipe pesanan
                </Label>
                {/* <div className="flex"> */}
                <FormControl className="flex flex-row">
                  <div className="basis-1/2 flex flex-col gap-3 capitalize">
                    <FormHelperText mt={"1px"} mb={"0.5rem"}>
                      {`${SKU_PREMIUM} Normal `}{" "}
                      <span className="line-through">
                        {numberToIDR(dataPrice?.data?.vip_price || 0)}
                      </span>{" "}
                      {`/pax`}
                    </FormHelperText>
                    <div className="text-base font-semibold">
                      {`${SKU_PREMIUM} Agent ${numberToIDR(
                        dataPrice?.data?.vip_discount_price || 0
                      )}/pax`}
                    </div>
                  </div>
                  <div className="basis-1/2">
                    <CakraInput label={"Jumlah tiket"} error={errors.vip}>
                      <NumberInput min={0}>
                        <NumberInputField
                          {...register("vip", {
                            value: 0,
                            min: 0,
                            onChange: onVipChange,
                          })}
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "e") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper onClick={onVipIncrease} />
                          <NumberDecrementStepper onClick={onVipDecrease} />
                        </NumberInputStepper>
                      </NumberInput>
                    </CakraInput>
                  </div>
                </FormControl>
                <FormControl className="flex flex-row mb-10">
                  <div className="basis-1/2 flex flex-col gap-3">
                    <FormHelperText mt={"1px"} mb={"0.5rem"}>
                      {`Regular Normal `}{" "}
                      <span className="line-through">
                        {numberToIDR(dataPrice?.data?.regular_price || 0)}
                      </span>{" "}
                      {`/pax`}
                    </FormHelperText>
                    <div className="text-base font-semibold">
                      {`Regular Agent ${numberToIDR(
                        dataPrice?.data?.regular_discount_price || 0
                      )}/pax`}
                    </div>
                  </div>
                  <div className="basis-1/2">
                    <CakraInput label={"Jumlah tiket"} error={errors.regular}>
                      <NumberInput min={0}>
                        <NumberInputField
                          {...register("regular", {
                            value: 0,
                            min: 0,
                            onChange: onRegularChange,
                          })}
                          onKeyDown={preventNegativeValue}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper onClick={onRegularIncrease} />
                          <NumberDecrementStepper onClick={onRegularDecrease} />
                        </NumberInputStepper>
                      </NumberInput>
                    </CakraInput>
                  </div>
                </FormControl>
                {/* </div> */}
              </div>
            </>
          ) : (
            <PaymentTypeForm
              handleDepositPayment={handleSubmit(handleDepositPayment)}
              handleOnlinePayment={handleSubmit(onSubmit)}
              subtitle="pilih metode pembayaran"
              onHandleBack={handleIsPayementType}
              saldoDeposit={
                !saldoDepositLoading && saldoDepositData ? saldoDepositData : 0
              }
              isDepositPayment={saldoDepositData >= totalPrice}
            />
          )}
        </div>
        <div id="fomr-2" className="mx-3">
          <Card x-chunk="dashboard-07-chunk-3" className="bg-green-100 ">
            <CardHeader className="pt-5 pr-0">
              <CardTitle className="flex items-center gap-2 text-lg">
                Preview Pesanan
              </CardTitle>
            </CardHeader>
            <CardContent className="p-0">
              <div className="flex flex-col sm:gap-6 p-6 pt-0 ">
                <LabelWrapper className="text-sm font-normal">
                  Nama Leader
                  <article className="text-pretty max-w-2xs">
                    <h3 className="capitalize">{watch("namaLeader") || "-"}</h3>
                  </article>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Nomor ponsel{" "}
                  <h3 className="capitalize">{watch("phone") || "-"}</h3>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Email
                  <article className="text-pretty max-w-2xs">
                    <h3>{watch("email") || "-"}</h3>
                  </article>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Nama Group Umroh
                  <article className="text-pretty max-w-2xs">
                    <h3 className="capitalize">
                      {watch("namaGroupUmroh") || "-"}
                    </h3>
                  </article>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Waktu Penggunaan
                  <h3>
                    {(watch("tglPakai") &&
                      moment(watch("tglPakai").startDate).format(
                        "DD MMM YYYY"
                      )) ||
                      "-"}
                  </h3>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  {SKU_PREMIUM} x {watch("vip")} pax
                  <h3 className="capitalize">
                    {numberToIDR(vipTotalPrice || 0)}
                  </h3>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Regular x {watch("regular")} pax
                  <h3 className="capitalize">
                    {numberToIDR(regularTotalPrice || 0)}
                  </h3>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Sub total
                  <h3 className="capitalize">
                    {numberToIDR(subTotalPrice || 0)}
                  </h3>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Potongan khusus Agent
                  <h3 className="capitalize text-green-700">
                    {"-" + numberToIDR(discount || 0)}
                  </h3>
                </LabelWrapper>
              </div>
              <div>
                <LabelWrapper className="bg-yellow-200 px-6 py-4 mx-0">
                  Total Pembayaran{" "}
                  <h3 className="font-bold text-base">
                    {numberToIDR(totalPrice || 0)}
                  </h3>
                </LabelWrapper>
                <div className="px-6 pt-6 pb-4">
                  <button
                    id="pay"
                    type="button"
                    className="w-full rounded-lg p-3 text-white bg-green-600"
                    onClick={handleIsPayementType}
                  >
                    Pilih metode pembayaran
                  </button>
                  {snapShow ? (
                    <Button
                      mt={"12px"}
                      colorScheme="bg-green-600"
                      className="bg-green-600"
                      w={"100%"}
                      onClick={handleCheckMidtransStatus}
                    >
                      Check Status Pembayaran
                    </Button>
                  ) : null}
                  <div className="flex flex-row text-center gap-1 p-3 justify-center items-center pb-0">
                    <IcBaselineVerified className="text-systemAzure" />{" "}
                    Transaksi aman dan nyaman
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          {/* <div className="p-2">
            <img src={listBank} alt="list-bank" />
          </div> */}
        </div>
      </div>
      {/* {data ? renderModal : null} */}
    </form>
  );
};

export default TaFormTiket;
