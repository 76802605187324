import React, { Fragment, useEffect } from "react";
import moment from "moment";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/Card";
import LabelWrapper from "../../components/ui/LabelWrapper";
import { FRONT_OFFICE, HD_PAYMENT_TYPE } from "../../lib/constant";
import { numberToIDR } from "../../lib/utils";
import { useAuth } from "../../hooks/useAuth";
import { Clock } from "lucide-react";
import CakraInput from "../../components/CakraInput";
import { useForm } from "react-hook-form";
import { useApiPost } from "../../hooks/useApiPost";
import toastHandler from "../../components/toastHandler";

const ModalOpenClosing = ({
  isOpen,
  onToggleModal,
  data,
  isClockIn,
  shiftRefetch,
}) => {
  const { user } = useAuth();
  const toast = useToast();
  const { register, handleSubmit, resetField, setValue } = useForm();
  const {
    handlePost,
    loading: loadingSubmit,
    data: dataSubmit,
    error: errorSubmit,
  } = useApiPost();

  useEffect(() => {
    if (!loadingSubmit && dataSubmit) {
      onToggleModal();
      shiftRefetch();
    }
    toastHandler({
      data: dataSubmit,
      error: errorSubmit,
      loading: loadingSubmit,
      toast: toast,
      title: `Berhasil ${isClockIn ? "clock out" : "clock in"} `,
    });
  }, [loadingSubmit, dataSubmit, errorSubmit]);

  useEffect(() => {
    if (data) {
      setValue("initialBalance", data?.shift_detail?.initial_balance || 0);
    }
  }, [data]);

  const onSubmit = (formData) => {
    const payload = {
      ...(!isClockIn && {
        initial_balance: parseInt(formData.initialBalance) || 0,
      }),
      ...(isClockIn && {
        id: data.shift_detail.id,
        end_balance: data.shift_detail.initial_balance,
      }),
    };
    if (!isClockIn) {
      handlePost("/api/clock-in", payload);
    } else {
      handlePost("/api/clock-out", payload);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      size={isClockIn ? "xl" : "sm"}
      onClose={() => {
        onToggleModal();
        if (!isClockIn) {
          resetField("initialBalance");
        }
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={"lg"} className="border-b-2 my-2 !font-bold">
            {isClockIn ? "Clock out" : "Clock in" + " Front Office"}
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody p={0}>
            <div className="flex flex-row justify-between px-2 rounded-sm">
              <Card
                x-chunk="dashboard-07-chunk-3"
                className={`flex border-none shadow-none bg-gray-50 w-full`}
              >
                <div className={`${isClockIn ? "basis-1/2" : "w-full"}`}>
                  <CardHeader className="p-4 pb-3 text-lg font-bold">
                    <CardTitle className="flex flex-col items-start gap-2 text-lg">
                      {isClockIn ? "Clock out" : "Clock in"}
                      <p className="flex gap-1">
                        <Clock size={16} />
                        {moment().format("DD MMMM YYYY, HH:mm") + " WIB"}
                      </p>
                    </CardTitle>
                  </CardHeader>
                  <CardContent className="p-0">
                    <div className="flex flex-col sm:gap-3 p-4 pt-0 text-gray-500 ">
                      <LabelWrapper className="flex-col uppercase text-sm font-bold">
                        {`Name ${FRONT_OFFICE}`}
                        <p>{user?.name}</p>
                      </LabelWrapper>
                      <LabelWrapper className="flex-col uppercase text-sm font-bold">
                        {"Saldo Awal"}
                      </LabelWrapper>
                      <CakraInput
                        type="number"
                        label="Uang deposit box"
                        labelClassName="!font-normal !text-black"
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                        labelHelp="Pastikan jumlah sesuai"
                        {...register("initialBalance", {
                          required: true,
                          value: data?.shift_detail?.initial_balance,
                        })}
                        isDisabled={isClockIn ? true : false}
                      />
                      <LabelWrapper className="flex-col uppercase text-sm font-bold">
                        {"Kamu punya"}
                        <p className="normal-case">
                          {data?.shift_notes_open_total +
                            " Catatan yang harus dilakukan hari ini"}
                        </p>
                      </LabelWrapper>
                    </div>
                  </CardContent>
                </div>
                {isClockIn && (
                  <div className="basis-1/2">
                    <CardHeader className="p-4 pb-3 text-lg font-bold">
                      <CardTitle className="flex flex-col items-start text-lg">
                        {"Laporan ke Accounting"}
                        <LabelWrapper className="flex-col uppercase text-sm font-bold text-gray-500">
                          Transaksi On the spot
                        </LabelWrapper>
                      </CardTitle>
                    </CardHeader>
                    <CardContent className="p-0">
                      <div className="flex flex-col sm:gap-3 p-4 pt-0 text-gray-500">
                        {data?.shift_payment_detail?.map((item, idx) => {
                          const labelName = HD_PAYMENT_TYPE[item.payment_type];
                          return (
                            <Fragment key={`paymeny-${idx}`}>
                              <LabelWrapper className="flex-col text-md font-normal">
                                {labelName}
                                <h3 className="!font-bold text-lg !text-gray-700 !capitalize">
                                  {numberToIDR(item.amount)}
                                </h3>
                              </LabelWrapper>
                            </Fragment>
                          );
                        })}
                      </div>
                    </CardContent>
                  </div>
                )}
              </Card>
              {/* side content */}
            </div>
          </ModalBody>

          <ModalFooter px={"0.5rem"}>
            <Button
              type="submit"
              variant={"green-outline"}
              size={"sm"}
              isDisabled={loadingSubmit}
            >
              {isClockIn ? "Clock out" : "Clock in"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default ModalOpenClosing;
