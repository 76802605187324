import { useEffect, useRef, useState } from "react";
import axios from "axios";
import _ from "lodash";
import { useAuth } from "./useAuth";

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers = { "Content-Type": "application/json" };

export const useFetchPrice = (initParam) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [param, setParam] = useState({ ...initParam });
  const { user } = useAuth();
  const prevParamRef = useRef();
  const { logout } = useAuth();

  const fetchPrice = async () => {
    try {
      const res = await axios.get(`/api/price`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        params: param,
      });

      if (!res.status === 200) {
        setError({ errMsg: "something when wrong!!" });
      }

      setData(res.data);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        logout();
      }
      // return error;
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPrice(param);
    prevParamRef.current = param;
  }, []);

  useEffect(() => {
    if (
      prevParamRef.current !== undefined &&
      !_.isEqual(param, prevParamRef.current)
    ) {
      fetchPrice(param);
      prevParamRef.current = param;
    }
  }, [param]);

  return {
    data,
    loading,
    error,
    setParam,
  };
};
