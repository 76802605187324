import { useCallback, useState } from "react";
import axios from "axios";
import { useAuth } from "./useAuth";
import { handleError } from "../lib/utils";
import { useLoading } from "./LoadingContext";

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const useApiPut = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useAuth();
  const { startLoading, stopLoading } = useLoading();
  const { logout } = useAuth();

  const handlePut = useCallback(async (api, payload) => {
    setLoading(true);
    startLoading();
    try {
      const res = await axios.put(api, payload, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (!res.status === 200 || res.data.success === false) {
        setError({
          ...res.data,
          message: res.data.message || "something when wrong!!",
        });
      }

      setData(res.data);
      setError(null);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        logout();
      }
      setError(handleError(error));
    }
    setLoading(false);
    stopLoading();
  }, []);

  return {
    handlePut,
    data,
    loading,
    error,
  };
};
