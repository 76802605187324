import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  useToast,
} from "@chakra-ui/react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/Card";
import LabelWrapper from "../../components/ui/LabelWrapper";
import { useApiPost } from "../../hooks/useApiPost";
import toastHandler from "../../components/toastHandler";
import CakrauploadDocument from "../../components/CakrauploadDocument";
import CakraInput from "../../components/CakraInput";
import { useForm } from "react-hook-form";
import useFileUpload from "../../hooks/useFileUpload";
import { convertToBase64 } from "../../lib/utils";

const TopupDepositModal = ({ onToggleModal, isOpen, data, refetch }) => {
  const [selectRateCard, setSelectRateCard] = useState(data.rate_card);
  // const [documentContract, setDocumentContract] = useState(null);
  // const [documentContractPreview, setDocumentContractPreview] = useState(null);

  const toast = useToast();
  const {
    files: documentContract,
    previews: documentContractPreview,
    handleFileChange: handleDocumentChange,
    handleRemoveFile: handleRemoveDocument,
    resetFileChange,
  } = useFileUpload();

  const { register, handleSubmit, reset } = useForm();
  //   wip

  const {
    handlePost,
    loading: submitLoading,
    data: submitData,
    error: submitError,
  } = useApiPost();

  useEffect(() => {
    if (!submitLoading && submitData) {
      onToggleModal();
      refetch();
      reset();
      resetFileChange();
    }

    toastHandler({
      data: submitData,
      error: submitError,
      loading: submitLoading,
      toast: toast,
      title: "Topup berhasil",
    });
  }, [submitData, submitLoading, submitError]);

  const onSubmit = async (dataForm) => {
    // WIP handle submit
    const base64Contracts = await Promise.all(
      documentContract.map(convertToBase64)
    );
    const payload = {
      travel_agent_id: data.travel_agent_id,
      amount: parseFloat(dataForm.amountDeposit),
      contract: base64Contracts,
    };

    handlePost("/api/register-deposit", payload);
  };

  return (
    <>
      <Modal isOpen={isOpen} size={"xl"} onClose={onToggleModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton left={0} />
          <ModalHeader className="border-b-2 my-2"></ModalHeader>

          <ModalBody p={0}>
            <div className="flex flex-row justify-between px-2">
              <Card x-chunk="dashboard-07-chunk-3" className="basis-1/2">
                <CardHeader className="p-4 pb-3 text-lg font-bold">
                  <CardTitle className="flex items-center gap-2 text-lg">
                    Detail Top Up Deposit
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-0">
                  <div className="flex flex-col sm:gap-3 p-4 pt-0 text-gray-500 ">
                    <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Nama Travel Agent
                      <h3 className="font-normal text-gray-700 capitalize">
                        {data?.name || "-"}
                      </h3>
                    </LabelWrapper>
                    <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Nomor NPWP
                      <h3 className="font-normal text-gray-700 capitalize">
                        {/* WIP */}
                        {data?.npwp || "-"}
                      </h3>
                    </LabelWrapper>
                    <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Sales yang Menangani
                      <h3 className="font-normal text-gray-700 capitalize">
                        {data?.sales_name}
                      </h3>
                    </LabelWrapper>
                  </div>
                  <div></div>
                </CardContent>
              </Card>
              {/* side content */}
              <Card
                x-chunk="dashboard-07-chunk-3"
                className="border-none shadow-none basis-1/2"
              >
                <CardHeader className="p-4 pb-3 text-lg font-bold">
                  <CardTitle className="flex items-center gap-2 text-lg">
                    Persetujuan & Bukti
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-0">
                  <div className="flex flex-col sm:gap-3 p-4 pt-0 text-gray-500 ">
                    <CakraInput
                      label="Jumlah deposit (IDR)"
                      labelClassName="!uppercase !text-sm !font-bold"
                    >
                      <NumberInput>
                        <NumberInputField {...register("amountDeposit")} />
                      </NumberInput>
                    </CakraInput>
                    <CakrauploadDocument
                      label="Dokumen Legalitas"
                      buttonLabel="Upload file"
                      preview={documentContractPreview}
                      file={documentContract}
                      handleDocumentChange={handleDocumentChange}
                      handleRemoveDocument={handleRemoveDocument}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
          </ModalBody>

          <ModalFooter justifyContent={"right"} px={"1rem"}>
            {/* <Button
              colorScheme="red"
              variant={"outline"}
              mr={3}
              size={"sm"}
              onClick={() => {}}
              isDisabled
            >
              Laporkan
            </Button> */}
            <Button
              colorScheme="green"
              variant="solid"
              size={"sm"}
              gap={"0.25rem"}
              onClick={handleSubmit(onSubmit)}
              isDisabled={submitLoading}
            >
              Ajukan Deposit Saldo
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TopupDepositModal;
