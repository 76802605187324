import React, { useEffect } from "react";
import _ from "lodash";
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { Box, useDisclosure } from "@chakra-ui/react";
import { ColumnsNotes } from "./Columns";
import DataTable from "../../components/ui/dataTable/DataTable";
import { Input } from "../../components/ui/Input";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";
import { IcMagnifying } from "../../assets/icons/icon";
import ModalNote from "./ModalNote";
import { Select } from "chakra-react-select";
import { CATATAN_OPTIONS } from "../../lib/constant";

const Notes = () => {
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [query, setQuery] = React.useState("");
  const [selectNoteStatus, setSelectNoteStatus] = React.useState({});
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [dataToUpdate, setDataToUpdate] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);

  const {
    loading: loadingNotes,
    data: dataNotes,
    error: errorNotes,
    setParam: setParamNotes,
    refetch: refetchNotes,
  } = useFetchDataDashboard("/api/shift-notes", {
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    query: query,
  });

  const {
    onOpen: onOpenNote,
    isOpen: isOpenNote,
    onClose: onCloseNote,
  } = useDisclosure();

  useEffect(() => {
    if (!loadingNotes && dataNotes) {
      setTableData(dataNotes.data);
    }
  }, [loadingNotes, dataNotes, errorNotes]);

  useEffect(() => {
    setParamNotes({
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
      ...(selectNoteStatus && { status: selectNoteStatus.value }),
    });
  }, [pagination, query, selectNoteStatus]);

  const onToggleModalNote = (selectedData) => {
    if (isOpenNote) {
      setDataToUpdate(null);
      onCloseNote();
    } else {
      if (selectedData) {
        setDataToUpdate(selectedData);
      }
      onOpenNote();
    }
  };

  const handleOnChangeSelectNoteStatus = (data) => {
    setSelectNoteStatus(data);
  };

  const table = useReactTable({
    data: tableData,
    columns: ColumnsNotes,
    rowCount: tableData?.total_data || 0, // Set the total rows based on
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    onToggleModalNote: onToggleModalNote,
    state: {
      columnFilters,
      rowSelection,
      pagination,
    },
  });

  return (
    <div className="min-h-screen">
      <div className="flex justify-between p-5">
        <div className="flex flex-row gap-3 basis-3/5">
          <div className="relative w-full basis-3/5">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <IcMagnifying />
            </div>
            <Input
              type="text"
              placeholder="Pencarian"
              onChange={(e) => setQuery(e.target.value)}
              className="ps-10"
            />
          </div>
          <Box className="basis-2/5 w-fit">
            <Select
              size={"md"}
              isClearable
              placeholder="pilih status"
              onChange={handleOnChangeSelectNoteStatus}
              options={CATATAN_OPTIONS}
              chakraStyles={{
                input: (prev, { selectProps }) => ({
                  ...prev,
                  boxShadow: "none !important",
                }),
              }}
            />
          </Box>
        </div>
        <div className="flex justify-end gap-1">
          <button
            onClick={(e) => {
              e.preventDefault();
              onToggleModalNote(null);
            }}
            type="button"
            className="flex w-full h-full gap-1 items-center px-5 border rounded-lg font-semibold bg-white border-tropicGreen500 text-tropicGreen500"
          >
            Buat baru
          </button>
        </div>
      </div>
      <div id="content-table">
        <DataTable
          columns={ColumnsNotes}
          table={table}
          loading={loadingNotes}
        />
      </div>
      <ModalNote
        onToggleModal={onToggleModalNote}
        isOpen={isOpenNote}
        data={dataToUpdate}
        refetch={refetchNotes}
        isCreate={!dataToUpdate ? true : false}
      />
    </div>
  );
};

export default Notes;
