import { useCallback, useState } from "react";
import axios from "axios";
import { HELPDESK, TA, TL } from "../lib/constant";

export const useDoLogin = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const doLogin = useCallback(async (payload) => {
    setLoading(true);
    try {
      // call API here
      const res = await axios.post(`/api/login`, payload);

      if (!res.status === 200 || res.data.success === false) {
        setError({
          ...res.data,
          message: res.data.message || "something when wrong!!",
        });
      }

      setData(res.data);
    } catch (error) {
      setError({
        message: error?.response?.data?.message || error.code,
        data: null,
        success: false,
      });
    }
    setLoading(false);
  }, []);

  return {
    doLogin,
    data,
    loading,
    error,
  };
};
