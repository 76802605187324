import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { dateFormat } from "../../lib/utils";
import React from "react";
import { EllipsisVertical } from "lucide-react";
import { MAPPING_TABLE_ROLE } from "../../lib/constant";

export const ColumnsMasterAccount = [
  {
    accessorKey: "name",
    header: "Nama",
    cell: ({ row }) => {
      const item = row.original;
      return <div>{row.getValue("name")}</div>;
    },
  },
  {
    accessorKey: "email",
    header: "Email",
    cell: ({ row }) => {
      return <div className="!lowercase">{row.getValue("email")}</div>;
    },
  },
  {
    accessorKey: "role",
    header: "Role",
    cell: ({ row }) => {
      const item = row.original;
      return <div>{MAPPING_TABLE_ROLE[item.role] || item.role}</div>;
    },
  },
  {
    accessorKey: "action",
    header: "",
    cell: ({ row, table }) => {
      const items = row.original;
      const menuItems = [];

      menuItems.push(
        <MenuItem
          key={`menu-${menuItems.length + 1}`}
          onClick={() => {
            table.options.onToggleModalDetail({ row: items });
          }}
          closeOnSelect={true}
        >
          Ubah
        </MenuItem>
      );

      menuItems.push(
        <MenuItem
          key={`menu-${menuItems.length + 1}`}
          onClick={() => {
            table.options.onToggleModalDelConfirmation({ row: items });
          }}
          closeOnSelect={true}
        >
          Delete
        </MenuItem>
      );

      return (
        <Menu>
          <MenuButton
            as={Button}
            size={"xs"}
            bgColor="transparent"
            isDisabled={menuItems.length <= 0}
          >
            <EllipsisVertical size={18} />
          </MenuButton>
          <MenuList fontWeight={500}>{menuItems}</MenuList>
        </Menu>
      );
    },
  },
];

export const ColumnsMasterApprover = [
  {
    accessorKey: "name",
    header: "Nama",
    cell: ({ row }) => {
      const item = row.original;
      return <div>{row.getValue("name")}</div>;
    },
  },
  {
    accessorKey: "email",
    header: "Email",
    cell: ({ row }) => {
      return <div className="!lowercase">{row.getValue("email")}</div>;
    },
  },
  {
    accessorKey: "action",
    header: "",
    cell: ({ row, table }) => {
      const items = row.original;
      const menuItems = [];

      menuItems.push(
        <MenuItem
          key={`menu-${menuItems.length + 1}`}
          onClick={() => {
            table.options.onToggleModalDelConfirmation({ row: items });
          }}
          closeOnSelect={true}
        >
          Delete
        </MenuItem>
      );

      return (
        <Menu>
          <MenuButton
            as={Button}
            size={"xs"}
            bgColor="transparent"
            isDisabled={menuItems.length <= 0}
          >
            <EllipsisVertical size={18} />
          </MenuButton>
          <MenuList fontWeight={500}>{menuItems}</MenuList>
        </Menu>
      );
    },
  },
];

export const ColumnsMasterSku = [
  {
    accessorKey: "id",
    header: "ID",
    cell: ({ row, table }) => {
      const item = row.original;
      return (
        <>
          <button
            onClick={() => {
              table.options.onToggleModalDetail({ row: item });
            }}
            className="bg-transparent border-none text-left"
          >
            <div className="text-systemAzure">
              {row.getValue("reservation_id")}
            </div>
            <p>{`check in: ${dateFormat(item.last_checkin_at)}`}</p>
          </button>
        </>
      );
    },
  },
  {
    accessorKey: "name",
    header: "Nama Sku",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <>
          <div>{row.getValue("name")}</div>
          <p>{item.travel_agent_name}</p>
        </>
      );
    },
  },
  {
    accessorKey: "price",
    header: "Harga",
    cell: ({ row }) => {
      const item = row.getValue("price");
      return (
        <>
          <div>{row.item}</div>
          {/* <p className="text-sm">{item.phone_number}</p> */}
        </>
      );
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const status = row.getValue("status");
      return <div>{status}</div>;
    },
  },
  {
    accessorKey: "action",
    header: "",
    cell: ({ row, table }) => {
      const items = row.original;
      const menuItems = [];

      if (items.payment_status.status === "INITIATED") {
        menuItems.push(
          <MenuItem
            key={`menu-${menuItems.length + 1}`}
            onClick={() => {
              console.log("change");
            }}
            closeOnSelect={true}
          >
            Update
          </MenuItem>
        );
      }

      return (
        <Menu>
          <MenuButton
            as={Button}
            size={"xs"}
            bgColor="transparent"
            isDisabled={menuItems.length <= 0}
          >
            <EllipsisVertical size={18} />
          </MenuButton>
          <MenuList fontWeight={500}>{menuItems}</MenuList>
        </Menu>
      );
    },
  },
];
