import React from "react";
import moment from "moment";

const toastHandler = ({ data, error, loading, toast, title }) => {
  if (!loading && data && (error === null || error === "")) {
    return toast({
      title: (
        <div className="flex flex-row gap-1">
          {data.success ? (
            <div>
              {title || "Pengunjung tercatat masuk"}
              <p className="text-sm text-white">
                {moment().format("DD MMM YYYY HH:mm") + " WIB"}
              </p>
            </div>
          ) : (
            <div>
              {"Terjadi kesalahan"}
              <p className="text-sm text-white">
                {moment().format("DD MMM YYYY HH:mm") + " WIB"}
              </p>
            </div>
          )}
        </div>
      ),
      description: data.success ? (
        <>{data.data || "Berikan pelayanan terbaik untuk pengunjung"}</>
      ) : (
        <>{data.message}</>
      ),
      status: data.success && !error ? "success" : "error",
      duration: 3000,
      isClosable: true,
      position: "top-right",
      containerStyle: {
        backgroundColor: "white!important",
        color: "#1F755B",
      },
    });
  }
  if (!loading && error) {
    return toast({
      title: (
        <div className="flex flex-row gap-1">
          <div>
            {"Terjadi kesalahan"}
            <p className="text-sm text-white">
              {moment().format("DD MMM YYYY HH:mm") + " WIB"}
            </p>
          </div>
        </div>
      ),
      description: <>{error.message}</>,
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top-right",
      containerStyle: {
        color: "#1F755B",
      },
    });
  }
  return null;
};

export default toastHandler;
