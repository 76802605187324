export const PRESENT = "PRESENT";
export const ABSENT = "ABSENT";
export const LATE = "LATE";
export const EXCUSED = "EXCUSED";
export const SETTLEMENT = "SETTLEMENT";
export const CANCELLED = "CANCELLED";
export const REFUNDED = "REFUNDED";
export const WAITING_FOR_PAYMENT = "WAITING_FOR_PAYMENT";
export const PAYMENT_VERIFIED = "PAYMENT_VERIFIED";

export const TA = "travelAgent";
export const TL = "tourLeader";
export const HELPDESK = "helpdesk";
export const SALES = "sales";
export const ACCOUNTING = "accounting";
export const ADMIN = "admin";
export const FRONT_OFFICE = "front office";

export const SKU_PREMIUM = "premium";
export const SKU_REGULAR = "regular";
export const DEVELOPMENT = "development";
export const PRODUCTION = "production";
export const SHIFT_UPDATE_STATUS = "UPDATE_STATUS";
export const SHIFT_UPDATE_DATA = "UPDATE_DATA";
export const SHIFT_DELETE = "DELETE";

export const definePaymentStatus = (status) => {
  let stat = {
    INITIATED: "Menunggu Pembayaran",
    COMPLETED: "Pembayaran Terverifikasi",
    SETTLEMENT: "Pembayaran Terverifikasi",
    PENDING: "Menunggu Pembayaran",
    FAILED: "Dibatalkan",
    REFUNDED: "Selesai dengan Refund",
    ERROR: "Terjadi kesalahan",
  };

  return stat[status];
};

export const SKU = [
  {
    key: "premium",
    label: "Premium",
  },
  { key: "reguler", label: "Reguler" },
];
export const HD_PAYMENT_TYPE = {
  helpdesk_cash: "cash",
  helpdesk_debit: "Kartu Debit",
  helpdesk_qris: "QRIS",
  helpdesk_va: "VA/Transfer Bank",
  helpdesk_credit_card: "Kartu Kredit",
};

export const CATATAN_OPTIONS = [
  { value: "CLOSED", label: "Selesai" },
  { value: "OPEN", label: "Buka" },
];

export const NOTE_CATEGORY = [
  { value: "FASILITAS", label: "Fasilitas" },
  { value: "MAKANAN", label: "Makanan" },
  { value: "CETAK TIKET", label: "Cetak Tiket" },
  { value: "PEMBAYARAN", label: "Pembayaran" },
];

export const ACC_ROLE = [
  { value: "accounting", label: "Accounting" },
  { value: "helpdesk", label: "Front Office" },
  { value: "sales", label: "Sales" },
];

export const NOTE_ACTIONS = {
  updateStatus: "UPDATE_STATUS",
  updateData: "UPDATE_DATA",
  delete: "DELETE",
};

export const COLOR_SCHEME_INFO_CARD_TRANSACTION = {
  "total transaksi": "bg-green-100",
};

export const MAPPING_TABLE_ROLE = {
  helpdesk: "front office",
  sales: "sales",
  accounting: "accounting",
  travelAgent: "travel agent",
};

export const COLOR_SCHEME_INFO_CARD_CHECK_IN = {
  "ticket check in": "bg-green-100",
};

export const TITLE_FORMAT_TRANSACTION = {
  "total transaksi": "total transaksi (IDR)",
  qris: "QRIS",
};
