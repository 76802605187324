import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import _ from "lodash";
import axios from "axios";
import { useAuth } from "./useAuth";
import { handleError } from "../lib/utils";
import { useLoading } from "./LoadingContext";

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers = { "Content-Type": "application/json" };

export const useFetchDataDashboard = (url, initParam) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [param, setParam] = useState({ ...initParam });
  const { user } = useAuth();
  const { startLoading, stopLoading } = useLoading();
  const prevParamRef = useRef();
  const { logout } = useAuth();

  const fetchData = async (fetchParam = param) => {
    startLoading();
    try {
      const res = await axios.get(url, {
        params: fetchParam,
        headers: { Authorization: `Bearer ${user.token}` },
      });

      if (res.status !== 200) {
        setError({ error: "something when wrong!!" });
      } else {
        setError(null);
      }

      setData(res.data.data);
    } catch (error) {
      // return error;
      if (error.response && error.response.status === 403) {
        logout();
      }
      setError(handleError(error));
    }

    setLoading(false);
    stopLoading();
  };

  useEffect(() => {
    fetchData(param);
    prevParamRef.current = param;
  }, []);

  useEffect(() => {
    if (
      prevParamRef.current !== undefined &&
      !_.isEqual(param, prevParamRef.current)
    ) {
      fetchData();
      prevParamRef.current = param;
    }
  }, [param]);

  const refetch = useCallback(async (refetchParam) => {
    await fetchData(refetchParam);
  }, []);

  return {
    data,
    loading,
    error,
    setParam,
    refetch,
  };
};
