import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/Card";
import LabelWrapper from "../../components/ui/LabelWrapper";
import { PrinterIcon } from "lucide-react";
import { HD_PAYMENT_TYPE, definePaymentStatus } from "../../lib/constant";
import { dateFormat, renderUsedTotalTicket } from "../../lib/utils";

const HdModal = ({ onToggleModal, isOpen, data }) => {
  return (
    <>
      <Modal isOpen={isOpen} size={"xl"} onClose={onToggleModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton left={0} />
          <ModalHeader className="border-b-2 my-2"></ModalHeader>

          <ModalBody p={0}>
            <div className="flex flex-row justify-between px-2">
              <Card x-chunk="dashboard-07-chunk-3" className="basis-1/2">
                <CardHeader className="p-4 pb-3 text-lg font-bold">
                  <CardTitle className="flex items-center gap-2 text-lg">
                    Detail Pesanan
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-0">
                  <div className="flex flex-col sm:gap-3 p-4 pt-0 text-gray-500 ">
                    <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Kode Booking
                      <h3 className="font-normal text-gray-700 capitalize">
                        {data?.reservation_id || "-"}
                      </h3>
                    </LabelWrapper>
                    <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Travel Agent
                      <h3 className="font-normal text-gray-700 capitalize">
                        {data?.travel_agent_name || "-"}
                      </h3>
                    </LabelWrapper>
                    <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Total Tiket
                      <h3 className="font-normal text-gray-700 capitalize">
                        {`${data?.tickets.total} untuk ${
                          data?.use_date && dateFormat(data.use_date)
                        }`}
                      </h3>
                    </LabelWrapper>
                    <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Total Tiket digunakan
                      {data?.tickets
                        ? Object.keys(data.tickets).map((value, idx) => {
                            if (value !== "total") {
                              return (
                                <h3
                                  key={`item-${idx}`}
                                  className="font-normal text-gray-700 capitalize"
                                >
                                  {renderUsedTotalTicket(value, data)}
                                </h3>
                              );
                            }
                            return null;
                          })
                        : "-"}
                    </LabelWrapper>
                    <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Status Pembayaran
                      <h3 className="font-normal text-gray-700 capitalize">
                        {`${definePaymentStatus(
                          data?.payment_status.status
                        )} - ${
                          HD_PAYMENT_TYPE[data?.payment_status.via]
                            ? HD_PAYMENT_TYPE[data?.payment_status.via]
                            : data?.payment_status.via || ""
                        }`}
                      </h3>
                      {/* <Link to={"#"}>
                        <h3 className="font-normal text-blue-500 capitalize">
                          {"Bukti Pembayaran"}
                        </h3>
                      </Link> */}
                    </LabelWrapper>
                  </div>
                  <div></div>
                </CardContent>
              </Card>
              {/* side content */}
              <Card
                x-chunk="dashboard-07-chunk-3"
                className="border-none shadow-none basis-1/2"
              >
                <CardHeader className="p-4 pb-3 text-lg font-bold">
                  <CardTitle className="flex items-center gap-2 text-lg">
                    Detail Leader
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-0">
                  <div className="flex flex-col sm:gap-3 p-4 pt-0 text-gray-500 ">
                    <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Nama Group
                      <h3 className="font-normal text-gray-700 capitalize">
                        {data?.group_name || "-"}
                      </h3>
                    </LabelWrapper>
                    <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Nomor Penerbangan
                      <h3 className="font-normal text-gray-700 capitalize">
                        {data?.flight_number || "-"}
                      </h3>
                    </LabelWrapper>
                    <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Nama Leader
                      <h3 className="font-normal text-gray-700 capitalize">
                        {data?.tour_leader_name || "-"}
                      </h3>
                    </LabelWrapper>
                    <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Nomor Ponsel aktif wa
                      <h3 className="font-normal text-gray-700 capitalize">
                        {data?.phone_number || "-"}
                      </h3>
                    </LabelWrapper>
                  </div>
                </CardContent>
              </Card>
            </div>
          </ModalBody>

          <ModalFooter justifyContent={"space-between"} px={"0.5rem"}>
            <Button
              colorScheme="red"
              variant={"outline"}
              mr={3}
              size={"sm"}
              onClick={() => {}}
              isDisabled
            >
              Laporkan
            </Button>
            {/* <Button
              colorScheme="orange"
              variant="solid"
              size={"sm"}
              gap={"0.25rem"}
              onClick={() => {}}
            >
              <PrinterIcon size={"14px"} /> Print Code Booking
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HdModal;
