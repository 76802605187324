import React, { useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { Button, useDisclosure, useToast } from "@chakra-ui/react";
import { ColumnsMasterAccount } from "./Columns";
import DataTable from "../../components/ui/dataTable/DataTable";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";
import { useNavigate } from "react-router-dom";
import { dateTimeFormat } from "../../lib/utils";
import { Clock } from "lucide-react";
import SearchBar from "../../components/SearchBar";
import ModalAccount from "./ModalAccount";
import ModalConfirmation from "../../components/ModalConfirmation";
import { useApiDelete } from "../../hooks/useApiDelete";
import toastHandler from "../../components/toastHandler";

const MasterAccounts = () => {
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [query, setQuery] = React.useState("");
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [dataUser, setDataUser] = React.useState([]);
  const [modalDetail, setModalDetail] = React.useState();
  const [deleteData, setDeleteData] = React.useState();

  const { loading, data, error, setParam, refetch } = useFetchDataDashboard(
    "/api/users",
    {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
    }
  );
  const {
    handleDelete,
    loading: loadingDelete,
    data: dataDelete,
    error: errorDelete,
  } = useApiDelete();

  const toast = useToast();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: onOpenDelete,
    isOpen: isOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  useEffect(() => {
    if (!loading && data) {
      setDataUser(data.data || []);
    }
  }, [data]);

  useEffect(() => {
    setParam({
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
    });
  }, [pagination]);

  useEffect(() => {
    if (!loadingDelete && dataDelete) {
      onToggleModalDelConfirmation();
      refetch();
    }
    toastHandler({
      data: dataDelete,
      error: errorDelete,
      loading: loadingDelete,
      toast: toast,
      title: `Berhasil Menghapus akun `,
    });
  }, [loadingDelete, dataDelete, errorDelete]);

  const onToggleModalDetail = ({ row } = {}) => {
    if (isOpen) {
      setModalDetail(null);
      onClose();
    } else {
      setModalDetail(row);
      onOpen();
    }
  };

  const onToggleModalDelConfirmation = ({ row } = {}) => {
    if (isOpenDelete) {
      setDeleteData(null);
      onCloseDelete();
    } else {
      setDeleteData(row);
      onOpenDelete();
    }
  };

  const onSubmitDelete = () => {
    handleDelete(`/api/users/${deleteData.id}`);
  };

  const onQueryChange = (data) => {
    setQuery(data);
    setPagination({ pageIndex: 0, pageSize: 5 });
    setParam({
      page: 1,
      limit: pagination.pageSize,
      query: query,
    });
  };

  const table = useReactTable({
    data: dataUser,
    columns: ColumnsMasterAccount,
    rowCount: data?.total_data || 0, // Set the total rows based on
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    onToggleModalDetail: onToggleModalDetail,
    onToggleModalDelConfirmation: onToggleModalDelConfirmation,
    state: {
      columnFilters,
      rowSelection,
      pagination,
    },
  });

  return (
    <div className="min-h-screen">
      <div className="border-b-2 flex justify-between px-5">
        <div className="py-5">
          <h1 className="font-medium">
            Selamat datang di Lounge Umroh Terminal 2F
          </h1>
          <p className="flex flex-row text-base items-center gap-1">
            <Clock size={24} /> {dateTimeFormat(moment()) + " WIB"}
          </p>
        </div>
      </div>
      <div className="flex justify-between p-5">
        <SearchBar
          query={query}
          setQuery={onQueryChange}
          isRangeDate={false}
          searchPlaceholder="Pencarian"
          withDate={false}
        />
        <div className="flex justify-end gap-1 basis-1/5">
          <Button
            onClick={onToggleModalDetail}
            type="button"
            colorScheme="green"
            variant="outline"
            bgColor="white"
            className="flex w-full h-full gap-1 items-center px-5 border font-semibold !border-tropicGreen500 !text-tropicGreen500 rounded-lg bg-white"
          >
            Tambah Akun
          </Button>
        </div>
      </div>
      <div id="content-table">
        <DataTable
          columns={ColumnsMasterAccount}
          table={table}
          loading={loading}
        />
      </div>
      <ModalAccount
        onToggleModal={onToggleModalDetail}
        isOpen={isOpen}
        data={modalDetail}
        refetch={() =>
          refetch({
            page: pagination.pageIndex + 1,
            limit: pagination.pageSize,
            query: query,
          })
        }
        isCreate={modalDetail === null || modalDetail === undefined}
      />
      <ModalConfirmation
        onToggleModal={onToggleModalDelConfirmation}
        isOpen={isOpenDelete}
        title={"Hapus Akun"}
        desc={`Apa anda yakin akan menghapus akun ini ${
          deleteData?.name || ""
        } ?`}
        buttonSubmit={
          <Button
            id="delete"
            type="submit"
            colorScheme="bg-green-600"
            size="md"
            className="!text-base !font-semibold text-white bg-green-600"
            onClick={onSubmitDelete}
          >
            {loadingDelete ? "Loading..." : "Delete"}
          </Button>
        }
      />
    </div>
  );
};

export default MasterAccounts;
