// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { extendTheme } from "@chakra-ui/react";
// import type { StyleFunctionProps } from '@chakra-ui/styled-system'

const cakraTheme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "Inter, sans-serif",
      },
    },
  },
  components: {
    Button: {
      variants: {
        "green-outline": {
          bg: "white",
          color: "#38A169",
          border: "1px solid",
          borderColor: "#38A169",
        },
      },
    },
  },
});

export default cakraTheme;
