import React, { useEffect } from "react";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";

const DownloadReport = ({ genParams }) => {
  const { setParam, loading, data, error } = useFetchDataDashboard(
    "/api/report-download",
    genParams
  );

  useEffect(() => {
    if (genParams) {
      setParam(genParams);
    }
  }, [genParams]);

  const handleDownload = () => {
    // Replace with your API endpoint and parameters
    if (!loading && data) {
      const fileName = "report.xls"; // Change the file name and extension as needed

      // Create a link and trigger a download
      const link = document.createElement("a");
      link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  return (
    <Link onClick={handleDownload} className="px-2 pb-2">
      <Button
        colorScheme="green"
        variant={"solid"}
        isDisabled={data === null || data === "" || data.length <= 0}
      >
        Download Report
      </Button>
    </Link>
  );
};

export default DownloadReport;
