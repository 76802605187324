import React, { useEffect } from "react";
import {
  AspectRatio,
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import CakraInput from "./CakraInput";
import { useForm } from "react-hook-form";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import imageLogin from "../assets/image-login-2.jpg";
import { useAuth } from "../hooks/useAuth";
import { TA } from "../lib/constant";
import { useApiPut } from "../hooks/useApiPut";
import toastHandler from "./toastHandler";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [showKtp, setShowKtp] = React.useState(false);
  const [showPass, setShowPass] = React.useState(false);
  const [showConfirmPass, setShowConfirmPass] = React.useState(false);
  const [imgFile, setImgFile] = React.useState(null);
  const [imgPreview, setImgPreview] = React.useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { user } = useAuth();
  const { handlePut, loading, data, error } = useApiPut();
  const toast = useToast();
  const navigate = useNavigate();
  const handleKtpClick = () => setShowKtp(!showKtp);
  const handlePassClick = () => setShowPass(!showPass);
  const handleConfirmPassClick = () => setShowConfirmPass(!showConfirmPass);
  useEffect(() => {
    if (!loading && data && !error) {
      navigate(`/${user.role}/dashboard`);
    }
    toastHandler({
      data: data,
      error: error,
      loading: loading,
      toast: toast,
      title: "Password berhasil di ganti",
    });
  }, [loading, data, error]);

  const onSubmit = (data) => {
    if (user?.role !== TA) {
      const { pass } = data;
      const payload = { password: pass };

      handlePut("/api/profile", payload);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImgFile(file);
    setImgPreview(file);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <GridItem w="100%" colSpan={2} h="100%">
          {user.role === TA ? (
            <>
              <CakraInput
                label="Travel Agent Id"
                value={"TravelAgent - 11233455"}
                isDisabled
              />
              <CakraInput
                label="Nama Travel Agent"
                {...register("namaTravel", { value: "Nabawi Travel" })}
              />
              <CakraInput
                label="Alamat Email"
                {...register("email", { value: "NabawiTravel@gmail.com" })}
              />
              <CakraInput
                label="No Telepon Kantor"
                {...register("phone", { value: "081234567873" })}
              />
              <CakraInput label="Nomor KTP">
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={showKtp ? "text" : "password"}
                    placeholder="No KTP anda"
                    {...register("ktp", { value: "" })}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      bg={"transparent"}
                      size="sm"
                      onClick={handleKtpClick}
                    >
                      {showKtp ? <EyeOffIcon /> : <EyeIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </CakraInput>
              <CakraInput label="Kata Sandi">
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={showPass ? "text" : "password"}
                    placeholder="Enter password"
                    {...register("pass", { value: "" })}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      bg={"transparent"}
                      onClick={handlePassClick}
                    >
                      {showPass ? <EyeOffIcon /> : <EyeIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </CakraInput>
            </>
          ) : (
            <>
              <CakraInput label="Kata Sandi" error={errors?.pass}>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={showPass ? "text" : "password"}
                    placeholder="Enter password"
                    {...register("pass", {
                      required: true,
                    })}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      bg={"transparent"}
                      onClick={handlePassClick}
                    >
                      {showPass ? <EyeOffIcon /> : <EyeIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </CakraInput>
              <CakraInput
                label="Konfirmasi Kata Sandi"
                error={errors?.confirmPass}
              >
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={showPass ? "text" : "password"}
                    placeholder="Enter password"
                    {...register("confirmPass", {
                      required: true,
                      validate: (value, formValue) => {
                        if (value !== formValue.pass) {
                          return "isi password yang sama";
                        }
                      },
                    })}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      bg={"transparent"}
                      onClick={handleConfirmPassClick}
                    >
                      {showPass ? <EyeOffIcon /> : <EyeIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </CakraInput>
            </>
          )}
          <Button type="submit" colorScheme="green" mt="1.5rem">
            Simpan Perubahan
          </Button>
        </GridItem>
        {user.role === TA ? (
          <GridItem w="100%" h="100%">
            <AspectRatio width="64" ratio={1}>
              <Box
                rounded="md"
                shadow="sm"
                role="group"
                _hover={{
                  shadow: "md",
                }}
              >
                <Box position="relative" height="100%" width="100%">
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    height="100%"
                    width="100%"
                    display="flex"
                    flexDirection="column"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      width="100%"
                      position="relative"
                      className="bg-gray-100 mix-blend-multiply"
                    >
                      <Image
                        borderRadius="full"
                        boxSize="150px"
                        src={imgPreview || imageLogin}
                        alt="logo"
                        className="text-center justify-center"
                      />
                    </Box>
                    <Stack
                      p="8"
                      textAlign="center"
                      spacing="1"
                      className="bg-gray-100 mix-blend-multiply"
                    >
                      <Heading fontSize="lg" color="gray.700" fontWeight="bold">
                        click to upload
                      </Heading>
                    </Stack>
                  </Box>
                  <Input
                    type="file"
                    height="100%"
                    width="100%"
                    position="absolute"
                    top="0"
                    left="0"
                    opacity="0"
                    aria-hidden="true"
                    accept="image/*"
                    onChange={handleFileChange}
                    // onDragEnter={startAnimation}
                    // onDragLeave={stopAnimation}
                  />
                </Box>
              </Box>
            </AspectRatio>
          </GridItem>
        ) : null}
      </Grid>
    </form>
  );
};

export default Profile;
