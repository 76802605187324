import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useLocation, Link } from "react-router-dom";
import { Badge, Box } from "@chakra-ui/react";
import logo from "../assets/dashboard/Logo-Palmeera-new.png";
import { useAuth } from "../hooks/useAuth";
import { navLinksConfig } from "../routes/navLinkConfig";
import { HELPDESK, PRODUCTION, FRONT_OFFICE } from "../lib/constant";
import { useEnv } from "../hooks/EnvContext";

const DEV_TAG = "SANDBOX";

const NavigationBar = () => {
  const [activeNavIdx, setActiveNavIdx] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const { user, logout } = useAuth();
  const env = useEnv();
  const isProduction = env === PRODUCTION;

  const navLinks = navLinksConfig[user.role] || [];

  useEffect(() => {
    const activeIdx = _.findIndex(
      navLinks,
      (item) => item.link === location.pathname
    );
    setActiveNavIdx(activeIdx);
  }, [location, navLinks]);

  const handleLogout = () => {
    logout();
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <div className="w-full bg-tropicGreenDark sm:hidden">
        <button
          onClick={toggleSidebar}
          aria-controls="sidebar"
          type="button"
          className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-white rounded-lg hover:bg-tropicGreen focus:outline-none focus:ring-2 focus:ring-tropicGreen"
        >
          <span className="sr-only">Open sidebar</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              fillRule="evenodd"
              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
            ></path>
          </svg>
        </button>
      </div>
      <aside
        id="sidebar"
        className={`fixed flex flex-col h-full pt-5 px-3 top-0 left-0 z-40 w-64 min-h-screen transition-transform ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        } sm:translate-x-0 bg-[#144035]`}
      >
        <div className="flex justify-between flex-col mb-14 px-2">
          <Box px={2}>
            {isProduction ? null : (
              <Badge size="xl" variant="solid" colorScheme="red">
                {DEV_TAG}
              </Badge>
            )}
          </Box>
          <img src={logo} alt="logo-dashboard" />
        </div>
        <div className="pt-0 py-4 bg-[#144035] flex flex-col basis-full h-full">
          <div className="text-left text-white p-2 flex flex-row items-center justify-between">
            <h1 className="text-base font-extrabold capitalize">{`Hi, ${
              user.name || "user"
            }`}</h1>
            <div>
              <Badge colorScheme="blue" variant="solid" fontWeight="bold">
                {user.role !== HELPDESK ? user.role : FRONT_OFFICE}
              </Badge>
            </div>
          </div>
          <div className="mt-2 flex flex-col gap-5 h-full">
            {navLinks.map((item, idx) => {
              if (item.name !== "Keluar" && item.name !== "Pengaturan") {
                return (
                  <Link
                    key={`nav-${idx}`}
                    className={
                      "flex hover:opacity-80 space-x-2 p-2 rounded text-base text-white" +
                      (activeNavIdx === idx
                        ? " bg-tropicGreen font-semibold"
                        : "")
                    }
                    to={item.link}
                    onClick={() => setSidebarOpen(false)}
                  >
                    <item.icon />
                    <span className="capitalize">{item.name}</span>
                  </Link>
                );
              } else if (item.name === "Pengaturan") {
                return (
                  <Link
                    key={`nav-${idx}`}
                    className={
                      "flex hover:opacity-80 space-x-2 p-2 rounded text-base text-white [&:nth-last-child(-n+3)]:mt-auto" +
                      (activeNavIdx === idx
                        ? " bg-tropicGreen font-semibold"
                        : "")
                    }
                    to={item.link}
                    onClick={() => setSidebarOpen(false)}
                  >
                    <item.icon />
                    <span className="capitalize">{item.name}</span>
                  </Link>
                );
              } else if (
                item.name === "Keluar" &&
                !_.find(navLinks, { name: "Pengaturan" })
              ) {
                return (
                  <Link
                    key={`nav-${idx}`}
                    className={
                      "flex hover:opacity-80 space-x-2 p-2 rounded text-base text-white [&:nth-last-child(-n+3)]:mt-auto" +
                      (activeNavIdx === idx
                        ? " bg-tropicGreen font-semibold"
                        : "")
                    }
                    to={"#"}
                    onClick={(e) => {
                      e.preventDefault();
                      handleLogout();
                    }}
                  >
                    <item.icon />
                    <span className="capitalize">{item.name}</span>
                  </Link>
                );
              }
              return (
                <Link
                  key={`nav-${idx}`}
                  className={
                    "flex hover:opacity-80 space-x-2 p-2 rounded text-base  text-white [&:nth-last-child(-n+3)]" +
                    (activeNavIdx === idx
                      ? " bg-tropicGreen font-semibold"
                      : "")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    handleLogout();
                  }}
                  to={"#"}
                >
                  <item.icon />
                  <span>{item.name}</span>
                </Link>
              );
            })}
          </div>
        </div>
      </aside>
    </>
  );
};

export default NavigationBar;
