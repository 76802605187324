import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect } from "react";
import { useFetchDataDashboard } from "../hooks/useFetchDataDashboard";
import DataTable from "./ui/dataTable/DataTable";

const ReportEcysysTable = ({ api, columns, params }) => {
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [query, setQuery] = React.useState("");
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [dataUser, setDataUser] = React.useState([]);

  const { loading, data, error, setParam, refetch } = useFetchDataDashboard(
    api,
    {
      query: query,
      ...params,
    }
  );

  useEffect(() => {
    if (!loading && data) {
      if (data.data) {
        setDataUser(data.data || []);
      } else {
        setDataUser(data);
      }
    }
  }, [data, loading]);

  useEffect(() => {
    setParam({
      query: query,
      ...params,
    });
  }, [query, params]);

  const table = useReactTable({
    data: dataUser,
    columns,
    rowCount: data?.total_data || 0, // Set the total rows based on
    manualPagination: false,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    state: {
      columnFilters,
      rowSelection,
      pagination,
    },
  });

  return (
    <div>
      <DataTable columns={columns} table={table} loading={loading} />
    </div>
  );
};

export default ReportEcysysTable;
