import React from "react";
import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import { useFetchData } from "../hooks/useFetchData";
import { LoadingProvider } from "../hooks/LoadingContext";
import { numberToIDR } from "../lib/utils";

const InfoArc = ({ id }) => {
  const { loading, data } = useFetchData(
    `/api/rate-card-activity-detail/${id}`
  );

  if (loading) return <LoadingProvider />;
  const { id: notifId, skus } = data?.data || {};
  return (
    <Box p={5} borderWidth="1px" borderRadius="md">
      <Grid templateColumns="auto auto 1fr" gap={4}>
        <GridItem display="flex" alignItems="center" width="max-content">
          <Text fontWeight="bold">Nama Travel Agent</Text>
        </GridItem>
        <GridItem>
          <Text mx={2}>:</Text>
        </GridItem>
        <GridItem>
          <Text>{notifId || "-"}</Text>
        </GridItem>
        {skus
          ? skus.map((sku) => {
              return (
                <>
                  <GridItem
                    display="flex"
                    alignItems="center"
                    width="max-content"
                  >
                    <Text fontWeight="bold">{`Harga ${sku.sku_name}`}</Text>
                  </GridItem>
                  <GridItem>
                    <Text mx={2}>:</Text>
                  </GridItem>
                  <GridItem display="flex" gap={1}>
                    <Text className="line-through">
                      {numberToIDR(sku.old_price)}
                    </Text>
                    <Text>{numberToIDR(sku.price) || "-"}</Text>
                  </GridItem>
                </>
              );
            })
          : null}
      </Grid>
    </Box>
  );
};

export default InfoArc;
