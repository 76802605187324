import React from "react";
import logo from "../assets/dashboard/Logo-Palmeera-green.png";
import loginImage from "../assets/image-login-2.jpg";
import { useLocation } from "react-router-dom";
import InfoCard from "../components/InfoCard";
import { IcBaselineClose, IcOutlineVerified } from "../assets/icons/icon";
import InfoRateCode from "../components/InfoRateCode";
import InfoArc from "../components/InfoArc";
import { LoadingProvider } from "../hooks/LoadingContext";

const NotificationPage = () => {
  const queryParam = useQuery();
  // general use
  const type = queryParam.get("type");
  const message = queryParam.get("message");
  const status = queryParam.get("status");

  // specific use
  const travelAgent = queryParam.get("travelAgent");
  const newRateCode = queryParam.get("newRateCode");
  const rateCodeDiscount = queryParam.get("rateCodeDiscount");
  const rateCodeName = queryParam.get("rateCodeName");
  const depositAmount = queryParam.get("amount");
  const bukti = queryParam.get("bukti");
  const id = atob(queryParam.get("id"));

  // Determine the background color and icon based on the status
  const bgColor = status === "success" ? "bg-green-100" : "bg-red-100";
  const Icon = status === "success" ? IcOutlineVerified : IcBaselineClose;

  const renderContent = () => {
    let contents = null;

    if (type === "NRC") {
      contents = (
        <InfoRateCode
          rateCodeName={rateCodeName}
          rateCodeDiscount={rateCodeDiscount}
        />
      );
    }
    if (type === "ARC") {
      contents = (
        <InfoArc
          id={id}
          travelAgent={travelAgent}
          newRateCode={newRateCode}
          rateCodeDiscount={rateCodeDiscount}
        />
      );
    }
    if (type === "DEPOSIT") {
      contents = (
        <InfoCard
          travelName={travelAgent}
          deposit={depositAmount}
          bukti={bukti}
          idDeposit={id}
        />
      );
    }
    return contents;
  };

  return (
    <LoadingProvider>
      <div className={`relative w-full h-screen overflow-auto ${bgColor}`}>
        <div className="grid grid-cols-2 h-full">
          <div className="flex justify-center flex-col p-3 mr-0 lg:p-10 lg:mr-24 ">
            <div className="flex flex-col items-start justify-start gap-3 mb-2">
              <div className="aspect-video max-w-96">
                <img src={logo} alt="logo-terminal-2" />
              </div>
              <div>
                <h1
                  className={
                    status === "success" ? "text-green-500" : "text-red-500"
                  }
                >
                  {message}
                </h1>
                <p>Dengan rincian: </p>
              </div>
              {renderContent()}
              {/* <InfoCard type={type} travelName={travelName} /> */}
            </div>
          </div>
          <div className="relative">
            <img
              src={loginImage}
              alt="login"
              className="object-cover object-bottom aspect-square h-full w-full"
            />
            <div className={`absolute inset-0 opacity-50 ${bgColor}`}></div>{" "}
            {/* Overlay */}
          </div>
        </div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="flex flex-col items-center">
            <Icon size={64} color={status === "success" ? "green" : "red"} />

            <h1
              className={`text-2xl font-bold ${
                status === "success" ? "text-green-500" : "text-red-500"
              } mt-4`}
            >
              {status === "success" ? "Success" : "Error"}
            </h1>
          </div>
        </div>
      </div>
    </LoadingProvider>
  );
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default NotificationPage;
