import React from "react";
import { cn } from "../../lib/utils";

const LabelWrapper = ({ children, className }) => {
  return (
    <div className={cn("flex flex-row justify-between capitalize", className)}>
      {children}
    </div>
  );
};

export default LabelWrapper;
