import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { InputGroup } from "../components/ui/InputGroup";
import logo from "../assets/dashboard/Logo-Palmeera-green.png";
import logoDev from "../assets/dashboard/Logo-Palmeera-green-dev.png";
import { useAuth } from "../hooks/useAuth";
import { useDoLogin } from "../hooks/useDoLogin";
import {
  ACCOUNTING,
  ADMIN,
  HELPDESK,
  PRODUCTION,
  SALES,
  TA,
} from "../lib/constant";
import loginImage from "../assets/image-login-2.jpg";
import { useEnv } from "../hooks/EnvContext";

const Login = () => {
  const { user, login } = useAuth();
  const { doLogin, loading, data, error } = useDoLogin();
  const { register, handleSubmit } = useForm();
  const toast = useToast();
  const navigate = useNavigate();
  const env = useEnv();
  const isProduction = env === PRODUCTION;

  useEffect(() => {
    if (user) {
      if (user.token && user.role) {
        switch (user.role) {
          case TA:
            navigate("/travel-agent/pembelian-tiket", { replace: true });
            break;
          case ACCOUNTING:
            navigate("/accounting/laporan", { replace: true });
            break;
          case HELPDESK:
            navigate("/helpdesk/dashboard", { replace: true });
            break;
          case SALES:
            navigate("/sales/dashboard", { replace: true });
            break;
          case ADMIN:
            navigate("/admin/master-account", { replace: true });
            break;
          default:
            navigate("/", { replace: true });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (data) {
      if (data.data) {
        const token = data.data.token;
        const role = data.data.role;
        const name = data.data.name;
        login(token, role, name);

        switch (role) {
          case TA:
            navigate("/travel-agent/pembelian-tiket", { replace: true });
            break;
          case ACCOUNTING:
            navigate("/accounting/laporan", { replace: true });
            break;
          case HELPDESK:
            navigate("/helpdesk/dashboard", { replace: true });
            break;
          case SALES:
            navigate("/sales/dashboard", { replace: true });
            break;
          case ADMIN:
            navigate("/admin/master-account", { replace: true });
            break;
          default:
            navigate("/", { replace: true });
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [error]);

  const onSubmit = async (data) => {
    doLogin({ username: data.username, password: data.password });
  };

  return (
    <div className="grid grid-cols-2 w-full h-screen max-h-screen overflow-auto bg-[#F4FBFA]">
      <form method="post" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-center flex-col gap-4 p-3 mr-0 lg:p-10 lg:mr-24 sm:mt-28 lg:pr-48">
          <div className="flex flex-col items-start justify-start gap-12 mb-2">
            <img src={isProduction ? logo : logoDev} alt="logo-terminal-2" />
            <div>
              <h1>Masuk ke Admin</h1>
              <h4>
                Gunakan nama akun dan kata sandi yang sudah didaftarkan ke Admin
                Dashboard.
              </h4>
            </div>
          </div>

          <InputGroup
            label={"Nama Akun"}
            type="text"
            registerLabel={"username"}
            register={register}
            validation={{ required: true }}
          />
          <InputGroup
            label={"Kata Sandi"}
            type="password"
            registerLabel={"password"}
            register={register}
            validation={{ required: true }}
          />
          <button
            type="submit"
            className="block w-full text-center p-3 rounded-lg text-white bg-tropicGreen"
            disabled={loading}
          >
            {loading ? "Loading..." : "Masuk"}
          </button>
        </div>
      </form>
      <div className="">
        <img
          src={loginImage}
          alt="login"
          className="object-cover object-bottom aspect-square h-full w-full"
        ></img>
      </div>
    </div>
  );
};

export default Login;
