import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

const CommonAlert = ({ error, loading, data }) => {
  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    if (!loading && error) {
      onOpen();
    } else if (!loading && data) {
      onClose();
    }
  }, [error, data, loading]);

  return isVisible && error ? (
    <Alert status="error">
      <AlertIcon />
      <Box className="w-full">
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Box>
      <CloseButton
        alignSelf="flex-start"
        top={-1}
        right={-1}
        position="relative"
        onClick={onClose}
      />
    </Alert>
  ) : null;
};

export default CommonAlert;
