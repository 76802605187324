import { Clock } from "lucide-react";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

import { dateTimeFormat } from "../../lib/utils";
import { useApiPost } from "../../hooks/useApiPost";
import { Input, InputGroup, InputLeftElement, Stack } from "@chakra-ui/react";
import CheckInMessages from "./CheckInMessage";
import { IcMagnifying } from "../../assets/icons/icon";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";

const ScanQr = () => {
  const [barcode, setBarcode] = useState("");
  const [error, setError] = useState(null);
  const scannedDataRef = useRef(null);
  const inputRef = useRef(null);
  const timeoutRef = useRef(null);
  const {
    handlePost: checkInPost,
    loading: checkInLoading,
    data: checkInData,
    error: checkInError,
  } = useApiPost();
  const {
    loading: reservationTicketLoading,
    data: reservationTicketData,
    // error: reservationTicketError,
    refetch: reservationTicketRefetch,
  } = useFetchDataDashboard("/api/reservation-tickets");

  const SCAN_TIMEOUT = 50; // Timeout in milliseconds to detect end of scan

  useEffect(() => {
    // Focus the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }

    // Clean up timeout on unmount
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!checkInLoading && checkInData) {
      scannedDataRef.current = barcode;
      setBarcode("");
      reservationTicketRefetch();
    }

    if (!checkInLoading && checkInError) {
      scannedDataRef.current = barcode;
      setBarcode("");
    }
  }, [checkInLoading, checkInData, checkInError]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setBarcode(value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout to detect end of scan
    timeoutRef.current = setTimeout(() => {
      if (value) {
        let payload = {
          package_id: value,
        };

        checkInPost("/api/check-in-scan", payload);
      }
    }, SCAN_TIMEOUT);
  };

  return (
    <div className="min-h-screen">
      <div className="border-b-2 flex justify-between px-5">
        <div className="py-5">
          <h1 className="font-medium">
            Selamat datang di Lounge Umroh Terminal 2F
          </h1>
          <p className="flex flex-row text-base items-center gap-1">
            <Clock size={24} /> {dateTimeFormat(moment()) + " WIB"}
          </p>
        </div>
        <div className="flex flex-row space-x-4 text-center m-5">
          <div className="bg-green-100 rounded-lg px-4 py-5">
            <h1 className="font-bold text-4xl">
              {!reservationTicketLoading && reservationTicketData
                ? reservationTicketData.verified_ticket
                : "-"}
            </h1>
            <p>Tiket check in</p>
          </div>
          <div className="bg-gray-200 bg-opacity-25 rounded-lg px-4 py-5">
            <h1 className="text-black text-4xl font-bold">
              {!reservationTicketLoading && reservationTicketData
                ? reservationTicketData.valid_ticket
                : "-"}
            </h1>
            <p>Tiket untuk hari ini</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center flex-col items-center p-5 gap-1">
        <Stack width={"40%"} spacing={4}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <IcMagnifying />
            </InputLeftElement>
            <Input
              type="text"
              ref={inputRef}
              value={barcode}
              onChange={handleInputChange}
              onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
              placeholder="Scan barcode here"
            />
          </InputGroup>

          {/* <div>
          <input
            type="text"
            ref={inputRef}
            value={barcode}
            onChange={handleInputChange}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
            placeholder="Scan barcode here"
            style={{ width: "300px", height: "40px", fontSize: "20px" }}
          />
        </div> */}
          <CheckInMessages
            scannedDataRef={scannedDataRef}
            loading={checkInLoading}
            data={checkInData}
            error={checkInError}
          />
          {error && <p style={{ color: "red" }}>{error}</p>}
        </Stack>
      </div>
    </div>
  );
};

export default ScanQr;
