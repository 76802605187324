import React from "react";
import logo from "../assets/dashboard/Logo-Palmeera-4.jpg";
import { numberToIDR, numberWithoutIDR } from "../lib/utils";
import { Box, Text, Heading } from "@chakra-ui/react";
import { useAuth } from "../hooks/useAuth";
import { HD_PAYMENT_TYPE } from "../lib/constant";

const Invoice = React.forwardRef((props, ref) => {
  const { user } = useAuth();
  const defaultPriceTotal = props.items.reduce(
    (sum, item) => sum + item.qty * item.default_price,
    0
  );
  const saveAmount = defaultPriceTotal - props.totalPrice;
  const paymentType = HD_PAYMENT_TYPE[props.paymentType];

  return (
    <div
      ref={ref}
      className="p-8 max-w-3xl mx-auto bg-white relative print-container"
    >
      <div className="flex self-start justify-between items-center mb-8">
        <div>
          <Heading className="text-2xl font-extrabold text-gray-900 p-0">
            Billing
          </Heading>
          <p className="text-lg text-gray-700">
            Kode Booking/Kode Transaksi:{" "}
            <span className="font-semibold">{props.invoiceNumber}</span>
          </p>
        </div>
        <div className="text-right">
          <img
            src={logo}
            alt="logo-terminal-2"
            className="aspect-auto w-52 h-auto mb-2"
          />
          <p className="text-lg text-gray-700">
            Date: <span className="font-semibold">{props.date}</span>
          </p>
        </div>
      </div>

      <div className="flex border rounded-lg px-4 py-5 mb-3 gap-6">
        <Box textAlign="left">
          <Heading size="xs" textTransform="uppercase">
            Nama Tour Leader
          </Heading>
          <Text fontSize="sm" color="black" textTransform="capitalize">
            {props.tourLeaderName}
          </Text>
        </Box>
        <Box textAlign="left">
          <Heading size="xs" textTransform="uppercase">
            Travel Agent
          </Heading>
          <Text fontSize="sm" color="black" textTransform="capitalize">
            {props.travelAgentName}
          </Text>
        </Box>
        <Box textAlign="left">
          <Heading size="xs" textTransform="uppercase">
            Nomor Telepon
          </Heading>
          <Text fontSize="sm" color="black" textTransform="capitalize">
            {props.phoneNumber}
          </Text>
        </Box>
      </div>

      <table className="min-w-full bg-white border border-gray-500 rounded-t-lg overflow-hidden">
        <thead>
          <tr className="bg-gray-100 border-b border-gray-300">
            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-700">
              No
            </th>
            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-700">
              Paket
            </th>
            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-700">
              Pax
            </th>
            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-700">
              Harga Satuan (IDR)
            </th>
            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-700">
              Special Price (IDR)
            </th>
            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-700">
              Total (IDR)
            </th>
          </tr>
        </thead>
        <tbody>
          {props.items.map((item, index) => (
            <tr key={index} className="border-b border-gray-200">
              <td className="py-2 px-4 text-sm text-gray-800">{index + 1}</td>
              <td className="py-2 px-4 text-sm text-gray-800">
                {item.sku_name.split("_").join(" ")}
              </td>
              <td className="py-2 px-4 text-sm text-gray-800">{item.qty}</td>
              <td className="py-2 px-4 text-right text-sm text-gray-800">
                {numberWithoutIDR(item.default_price)}
              </td>
              <td className="py-2 px-4 text-right text-sm text-gray-800">
                {numberWithoutIDR(item.price_per_unit)}
              </td>
              <td className="py-2 px-4 text-sm text-right text-gray-800">
                {numberWithoutIDR(item.total_price)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="text-right">
          <tr>
            <td colSpan="5" className="py-2 px-4 text-sm text-gray-700">
              Subtotal:
            </td>
            <td className="py-2 px-4 text-right text-sm text-gray-700">
              {numberWithoutIDR(props.totalPrice)}
            </td>
          </tr>
        </tfoot>
      </table>

      <div className="flex justify-between items-center border border-gray-200 border-t-gray-500 rounded-b-lg py-4 px-4">
        <div className="flex flex-col gap-1">
          <Box textAlign="left">
            <p className="text-sm font-medium">Pembayaran </p>
            <Text fontSize="sm" color="black" textTransform="capitalize">
              {paymentType}
            </Text>
          </Box>
          <Box textAlign="left">
            <p className="text-sm font-medium">Nomor Kartu</p>
            <Text fontSize="sm" color="black" textTransform="capitalize">
              {props.paymentCardNo || "-"}
            </Text>
          </Box>
        </div>
        <div className="flex flex-col text-right">
          <p className="text-lg font-semibold text-gray-900">Total Harga</p>
          <p className="text-xl font-bold text-gray-900">
            {numberToIDR(props.totalPrice)}
          </p>
          <p>{"*)Hemat " + numberToIDR(saveAmount)}</p>
        </div>
      </div>
      {/* place ment */}
      <footer className="mt-auto bg-white py-4 sticky print-footer">
        <div className="border-b border-gray-500 pb-5">
          <div className="flex justify-end">
            <div className="text-left">
              <p className="text-lg font-semibold text-gray-700">Signature</p>
              <div className="h-24 border-b border-gray-500 w-64"></div>
            </div>
          </div>
        </div>
        <div className="text-left">
          <p className="text-lg font-semibold text-gray-700">
            {`Prepared by: ` + user?.name}
          </p>
          {/* <div className="">{"HELPDESK"}</div> */}
        </div>
      </footer>
    </div>
  );
});

export default Invoice;
