import React, { useEffect } from "react";
import { Button, Grid, GridItem, useToast } from "@chakra-ui/react";
import CakraInput from "./CakraInput";
import { useForm } from "react-hook-form";
import { useApiPost } from "../hooks/useApiPost";
import { useFetchDataDashboard } from "../hooks/useFetchDataDashboard";
import toastHandler from "./toastHandler";
import { useNavigate } from "react-router-dom";
import { timeFormatMillisec } from "../lib/utils";

const ShiftForm = () => {
  const { setValue, register, handleSubmit } = useForm();
  const toast = useToast();
  const navigate = useNavigate();

  const {
    handlePost,
    loading: submitLoading,
    data: submitData,
    error: submitError,
  } = useApiPost();

  const {
    loading: getShiftLoading,
    data: getShiftData,
    error: getShiftError,
  } = useFetchDataDashboard("/api/shifts");

  useEffect(() => {
    if (!getShiftLoading && getShiftData && !getShiftError) {
      const startAt = timeFormatMillisec(getShiftData.StartTime);
      const endAt = timeFormatMillisec(getShiftData.EndTime);
      setValue("startAt", startAt);
      setValue("endAt", endAt);
    }
  }, [getShiftLoading, getShiftData, getShiftError]);

  useEffect(() => {
    if (!submitLoading && submitData) {
      navigate("/helpdesk/dashboard");
    }
    toastHandler({
      data: submitData,
      error: submitError,
      loading: submitLoading,
      toast: toast,
      title: "Pengaturan Shift berhasil",
    });
  }, [submitLoading, submitData, submitError]);

  const onSubmit = (data) => {
    const { startAt, endAt } = data;

    const payload = {
      start_time: timeFormatMillisec(startAt),
      end_time: timeFormatMillisec(endAt),
    };

    handlePost("/api/shifts", payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <GridItem w="100%" colSpan={2} h="100%">
          <CakraInput
            label="Waktu mulai shift"
            type="time"
            {...register("startAt", { required: true })}
          />
          <CakraInput
            label="Waktu selesai shift"
            type="time"
            {...register("endAt", { required: true })}
          />
          <Button type="submit" colorScheme="green" mt="1.5rem">
            Simpan Perubahan
          </Button>
        </GridItem>
      </Grid>
    </form>
  );
};

export default ShiftForm;
