import React from "react";
import CakraInput from "./CakraInput";
import {
  Box,
  Button,
  CloseButton,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
} from "@chakra-ui/react";
import { UploadIcon } from "lucide-react";

const CakrauploadDocument = ({
  preview,
  file,
  handleDocumentChange,
  label,
  buttonLabel,
  handleRemoveDocument,
  ...props
}) => {
  return (
    <div>
      <CakraInput label={label} labelClassName="!uppercase !text-sm !font-bold">
        <Box
          rounded="md"
          shadow="sm"
          role="group"
          _hover={{
            shadow: "none",
          }}
        >
          <Box position="relative" height="100%" width="100%">
            <InputGroup className="items-end">
              <Stack width={"100%"}>
                <Button
                  colorScheme="bg-blue-500"
                  className={`!bg-blue-500 text-white !rounded-md gap-1`}
                  size="sm"
                >
                  <UploadIcon size={16} />
                  {buttonLabel}
                </Button>
              </Stack>
              <Input
                type="file"
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                opacity="0"
                className="!border-none"
                aria-hidden="true"
                onChange={handleDocumentChange}
                {...props}
              />
            </InputGroup>
          </Box>
          {preview.length > 0 && (
            <Box mt={4} justifyContent={"center"}>
              {preview.map((doc, index) => (
                <Box display="flex" key={index}>
                  <a
                    href={URL.createObjectURL(doc)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {doc.name}
                  </a>
                  <CloseButton
                    ml={2}
                    size="sm"
                    colorScheme="red"
                    onClick={() => handleRemoveDocument(index)}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </CakraInput>
    </div>
  );
};

export default CakrauploadDocument;
