import React, { useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { Button, useDisclosure, useToast } from "@chakra-ui/react";
import { ColumnsMasterSku } from "./Columns";
import DataTable from "../../components/ui/dataTable/DataTable";
import { Input } from "../../components/ui/Input";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";
import { useApiPost } from "../../hooks/useApiPost";
import { Link, useNavigate } from "react-router-dom";
import { dateTimeFormat } from "../../lib/utils";
import { useFetchData } from "../../hooks/useFetchData";
import { Clock } from "lucide-react";
import Datepicker from "react-tailwindcss-datepicker";
import { SALES_Data } from "../../lib/data";
import HdModal from "../helpdesk/HdModal";
import SearchBar from "../../components/SearchBar";

const MasterSkuPrice = () => {
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [query, setQuery] = React.useState("");
  const [paramDate, setParamDate] = React.useState({
    startDate: moment().format("DD MMM YYYY"),
    endDate: moment().format("DD MMM YYYY"),
  });
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [dataUser, setDataUser] = React.useState([]);
  const [modalDetail, setModalDetail] = React.useState();
  const [modalForm, setModalForm] = React.useState();

  const { loading, data, error, setParam, refetch } = useFetchDataDashboard(
    "/api/sales-transactions",
    {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
      date: paramDate.startDate,
    }
  );
  const navigate = useNavigate();

  const toast = useToast();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: formOnOpen,
    isOpen: formIsOpen,
    onClose: formOnClose,
  } = useDisclosure();

  useEffect(() => {
    if (!loading && data) {
      setDataUser(data.data || []);
    }
  }, [data]);

  useEffect(() => {
    setParam({
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
      date: paramDate.startDate
        ? moment(paramDate.startDate).format("DD MMM YYYY")
        : "",
    });
  }, [pagination, query, paramDate]);

  const onToggleModalDetail = ({ row } = {}) => {
    if (isOpen) {
      setModalDetail(null);
      onClose();
    } else {
      setModalDetail(row);
      onOpen();
    }
  };

  const onToggleModalForm = ({ row } = {}) => {
    if (formIsOpen) {
      setModalForm(null);
      formOnClose();
    } else {
      setModalForm(row);
      formOnOpen();
    }
  };

  const handleDateChange = (date) => {
    setParamDate(date);
  };

  const table = useReactTable({
    data: dataUser,
    columns: ColumnsMasterSku,
    rowCount: data?.total_data || 0, // Set the total rows based on
    // pageCount: Math.ceil(data?.total / pagination.pageSize) || 0, // Set the total pages based on your data
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    onToggleModalDetail: onToggleModalDetail,
    onToggleModalForm: onToggleModalForm,
    state: {
      columnFilters,
      rowSelection,
      pagination,
    },
  });

  return (
    <div className="min-h-screen">
      <div className="border-b-2 flex justify-between px-5">
        <div className="py-5">
          <h1 className="font-medium">
            Selamat datang di Lounge Umroh Terminal 2F
          </h1>
          <p className="flex flex-row text-base items-center gap-1">
            <Clock size={24} /> {dateTimeFormat(moment()) + " WIB"}
          </p>
        </div>
      </div>
      <div className="flex justify-between p-5">
        <SearchBar
          query={query}
          setQuery={setQuery}
          paramDate={paramDate}
          handleDateChange={handleDateChange}
          isRangeDate={false}
          searchPlaceholder="Pencarian"
        />
        {/* <div className="flex justify-end gap-1 basis-1/5">
          <Link to={"/admin/master-sales/form"}>
            <Button
              type="button"
              colorScheme="green"
              variant="outline"
              bgColor="white"
              className="flex w-full h-full gap-1 items-center px-5 border font-semibold !border-tropicGreen500 !text-tropicGreen500 rounded-lg bg-white"
            >
              Tambah Pegawai
            </Button>
          </Link>
        </div> */}
      </div>
      <div id="content-table">
        <DataTable columns={ColumnsMasterSku} table={table} loading={loading} />
      </div>
      <HdModal
        onToggleModal={onToggleModalDetail}
        isOpen={isOpen}
        data={modalDetail}
      />
    </div>
  );
};

export default MasterSkuPrice;
