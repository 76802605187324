import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

const ModalConfirmation = ({
  onToggleModal,
  isOpen,
  buttonSubmit,
  desc,
  title,
}) => {
  return (
    <>
      <Modal isOpen={isOpen} size={"xl"} onClose={onToggleModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader className="border-b-2 my-2">
            {title || "Pembayaran selesai"}
          </ModalHeader>

          <ModalBody p={0}>
            <div className="p-6">
              <p
                className="text-xl text-black"
                dangerouslySetInnerHTML={{
                  __html:
                    desc ||
                    "Apakah pembayaran sudah yakin sesuai dan dapat diselesaikan statusnya?",
                }}
              />
            </div>
          </ModalBody>

          <ModalFooter justifyContent={"right"} px={"0.5rem"}>
            <Button
              colorScheme="gray"
              variant={"solid"}
              mr={3}
              size={"md"}
              onClick={onToggleModal}
            >
              Batalkan
            </Button>
            {buttonSubmit}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalConfirmation;
