import React, { Fragment, useEffect, useRef } from "react";
import moment from "moment";
import _ from "lodash";
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import {
  Alert,
  AlertDescription,
  Button,
  HStack,
  SimpleGrid,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ColumnsDashboard } from "./Columns";
import DataTable from "../../components/ui/dataTable/DataTable";
import { Input } from "../../components/ui/Input";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";
import { useApiPost } from "../../hooks/useApiPost";
import HdModal from "./HdModal";
import HdModalForm from "./HdModalForm";
import { Link } from "react-router-dom";
import { dateFormat, dateTimeFormat, numberToIDR } from "../../lib/utils";
import { Clock, LogInIcon, LogOutIcon } from "lucide-react";
import Datepicker from "react-tailwindcss-datepicker";
import { IcMagnifying } from "../../assets/icons/icon";
import handlePrint from "../PrintQr";
import { useReactToPrint } from "react-to-print";
import Invoice from "../Invoice";
import ModalConfirmation from "../../components/ModalConfirmation";
import toastHandler from "../../components/toastHandler";
import CardStack from "../../components/ui/CardStack";
import ModalOpenClosing from "./ModalOpenClosing";

const INITIAL_FNB = [
  { sku_name: "premium", total: 0 },
  { sku_name: "reguler", total: 0 },
];

function DashboardHelpdesk() {
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [query, setQuery] = React.useState("");
  const [paramDate, setParamDate] = React.useState({
    startDate: moment().format("DD MMM YYYY"),
    endDate: moment().format("DD MMM YYYY"),
  });
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [dataUser, setDataUser] = React.useState([]);
  const [modalDetail, setModalDetail] = React.useState();
  const [modalForm, setModalForm] = React.useState();
  const [selectCheckout, setSelectCheckout] = React.useState();
  const [dataFnb, setDataFnb] = React.useState(INITIAL_FNB);
  const [getShift, setGetShift] = React.useState();

  const { loading, data, error, setParam, refetch } = useFetchDataDashboard(
    "/api/helpdesk-transactions",
    {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
      date: paramDate.startDate,
    }
  );

  const {
    loading: reservationTicketLoading,
    data: reservationTicketData,
    // error: reservationTicketError,
    refetch: reservationTicketRefetch,
  } = useFetchDataDashboard("/api/reservation-tickets");

  const {
    loading: loadingShift,
    data: dataShift,
    error: errorShift,
    refetch: refetchShift,
  } = useFetchDataDashboard("/api/shifts");

  const {
    handlePost: doPrintPost,
    loading: printLoading,
    data: printData,
    error: printError,
  } = useApiPost();

  const {
    handlePost: printInvoicePost,
    loading: printInvoiceLoading,
    data: printInvoiceData,
    error: printInvoiceError,
  } = useApiPost();

  const {
    handlePost: checkoutPost,
    loading: checkoutLoading,
    data: checkoutData,
    error: checkoutError,
  } = useApiPost();

  const toast = useToast();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: formOnOpen,
    isOpen: formIsOpen,
    onClose: formOnClose,
  } = useDisclosure();

  const {
    onOpen: checkoutOnOpen,
    isOpen: checkoutIsOpen,
    onClose: checkoutOnClose,
  } = useDisclosure();

  const {
    onOpen: onOpenClock,
    isOpen: isOpenClock,
    onClose: onCloseClock,
  } = useDisclosure();

  const {
    onOpen: onOpenNote,
    isOpen: isOpenNote,
    onClose: onCloseNote,
  } = useDisclosure();

  const printRef = useRef(null);
  const printInvoiceRef = useRef();

  const handlePrintInvoice = useReactToPrint({
    pageStyle:
      ".print-container {display: flex;flex-direction: column;height: 100vh;}.print-footer {margin-top: auto;}",
    content: () => printInvoiceRef.current,
  });

  useEffect(() => {
    if (!loading && data) {
      setDataFnb(data.total_ticket_breakdown || INITIAL_FNB);
      setDataUser(data.data || []);
    }
  }, [data, loading]);

  useEffect(() => {
    setParam({
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
      date: !_.isEmpty(paramDate.startDate)
        ? moment(paramDate.startDate).format("DD MMM YYYY")
        : "",
    });
  }, [pagination, query, paramDate]);

  useEffect(() => {
    if (!printLoading && printData !== null) {
      printRef.current = printData.data;
      handlePrint(printData.data);
    }

    if (!printLoading && printError) {
      console.log("err", printError);
    }
  }, [printLoading, printData, printError]);

  useEffect(() => {
    if (!printInvoiceLoading && printInvoiceData !== null) {
      handlePrintInvoice();
    }

    if (!printInvoiceLoading && printInvoiceError) {
      console.log("err", printInvoiceError);
    }
  }, [printInvoiceLoading, printInvoiceData, printInvoiceError]);

  useEffect(() => {
    if (!checkoutLoading && checkoutData && !checkoutError) {
      checkoutOnClose();
      setSelectCheckout(null);
      reservationTicketRefetch();
      refetch({
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        query: query,
        date: !_.isEmpty(paramDate.startDate)
          ? moment(paramDate.startDate).format("DD MMM YYYY")
          : "",
      });
    }
    toastHandler({
      data: checkoutData,
      error: checkoutError,
      loading: checkoutLoading,
      toast: toast,
      title: "Checkout Pelanggan berhasil",
    });
  }, [checkoutLoading, checkoutData, checkoutError]);

  useEffect(() => {
    if (!loadingShift && dataShift && !errorShift) {
      setGetShift(dataShift);
    }
  }, [loadingShift, dataShift, errorShift]);

  const onToggleModalDetail = ({ row } = {}) => {
    if (isOpen) {
      setModalDetail(null);
      onClose();
    } else {
      setModalDetail(row);
      onOpen();
    }
  };

  const onToggleModalForm = ({ row } = {}) => {
    if (formIsOpen) {
      setModalForm(null);
      formOnClose();
    } else {
      setModalForm(row);
      formOnOpen();
    }
  };

  const onToggleModalPrint = ({ row } = {}) => {
    doPrintPost("/api/print-tickets", { reservation_id: row.reservation_id });
  };

  const onToggleModalPrintInvoice = ({ row }) => {
    printInvoicePost("/api/print-invoice-offline", {
      reservation_id: row.reservation_id,
    });
  };

  const onToggleModalCheckout = ({ row } = {}) => {
    if (checkoutIsOpen) {
      setSelectCheckout(null);
      checkoutOnClose();
    } else {
      setSelectCheckout(row);
      checkoutOnOpen();
    }
  };

  const onToggleModalOpenClosing = () => {
    if (isOpenClock) {
      onCloseClock();
    } else {
      onOpenClock();
    }
  };

  const onSubmitCheckout = () => {
    checkoutPost(`/api/check-out/${selectCheckout.reservation_id}`);
  };

  const handleDateChange = (date) => {
    setParamDate(date);
  };

  const handleRefetch = () => {
    refetch({
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      query: query,
      date: !_.isEmpty(paramDate.startDate)
        ? moment(paramDate.startDate).format("DD MMM YYYY")
        : "",
    });
    reservationTicketRefetch();
  };

  const table = useReactTable({
    data: dataUser,
    columns: ColumnsDashboard,
    rowCount: data?.total_data || 0, // Set the total rows based on
    // pageCount: Math.ceil(data?.total / pagination.pageSize) || 0, // Set the total pages based on your data
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    onToggleModalDetail: onToggleModalDetail,
    onToggleModalForm: onToggleModalForm,
    onToggleModalPrint: onToggleModalPrint,
    onToggleModalPrintInvoice: onToggleModalPrintInvoice,
    onToggleModalCheckout: onToggleModalCheckout,
    state: {
      columnFilters,
      rowSelection,
      pagination,
    },
  });

  const invoiceData = printInvoiceData
    ? {
        date:
          printInvoiceData.data.created_at || moment().format("DD MMM YYYY"),
        invoiceNumber: printInvoiceData.data.id,
        items: printInvoiceData.data.detail,
        totalPrice: printInvoiceData.data.total_price,
        tourLeaderName: printInvoiceData.data.tour_leader_name,
        travelAgentName: printInvoiceData.data.travel_agent_name,
        phoneNumber: printInvoiceData.data.phone_number || "-",
        discount: printInvoiceData.data.discount,
        paymentType: printInvoiceData.data.payment_type,
        paymentCardNo: printInvoiceData.data.payment_card_no,
        paymentRevTrans: printInvoiceData.data.payment_rev_transaction,
      }
    : null;

  const momentStartDate = moment(paramDate.startDate);
  const currentDate = moment();

  return (
    <div className="min-h-screen">
      <div className="border-b-2 flex justify-between px-5">
        <div className="flex flex-col justify-between py-5 mr-1">
          <VStack alignItems="start">
            <h1 className="font-medium">Selamat datang</h1>
            <p className="flex flex-row text-base items-center gap-1">
              <Clock size={24} /> {dateTimeFormat(moment()) + " WIB"}
            </p>
          </VStack>
          <HStack gap={1}>
            <Button
              leftIcon={
                getShift?.shift_detail?.id !== "" ? (
                  <LogOutIcon size="16px" />
                ) : (
                  <LogInIcon size="16px" />
                )
              }
              size="sm"
              variant="green-outline"
              onClick={onToggleModalOpenClosing}
            >
              {getShift?.shift_detail?.id !== "" ? "Clock out" : "Clock in"}
            </Button>
            <Link to={"/helpdesk/notes"}>
              <Button size="sm" variant="green-outline">
                {`Catatan (${getShift?.shift_notes_open_total})`}
              </Button>
            </Link>
          </HStack>
        </div>
        <div className="flex flex-col text-center my-5 flex-1">
          <SimpleGrid spacing={4} minChildWidth={"185px"}>
            <CardStack
              bodyClassName="bg-green-100"
              footerClassName="bg-green-600"
              bodyData={
                !reservationTicketLoading && reservationTicketData
                  ? reservationTicketData.verified_ticket
                  : "-"
              }
              footerData={
                !reservationTicketLoading && reservationTicketData
                  ? reservationTicketData.verified_ticket_breakdown
                  : []
              }
              title="tiket check in"
            />
            <CardStack
              bodyClassName="bg-gray-200"
              footerClassName="bg-gray-500"
              bodyData={
                !reservationTicketLoading && reservationTicketData
                  ? reservationTicketData.valid_ticket
                  : "-"
              }
              footerData={
                !reservationTicketLoading && reservationTicketData
                  ? reservationTicketData.valid_ticket_breakdown
                  : []
              }
              title="Tiket untuk hari ini"
            />
            <CardStack
              bodyClassName="bg-gray-200"
              footerClassName="bg-gray-500"
              bodyData={
                !reservationTicketLoading && reservationTicketData
                  ? reservationTicketData.total_ticket_in_room
                  : "-"
              }
              footerData={
                !reservationTicketLoading && reservationTicketData
                  ? reservationTicketData.total_ticket_in_room_breakdown
                  : []
              }
              title="Dalam Lounge"
            />
            <CardStack
              bodyClassName="bg-green-100"
              footerClassName="bg-green-600"
              bodyData={
                !reservationTicketLoading && reservationTicketData
                  ? numberToIDR(reservationTicketData.offline_revenue)
                  : "-"
              }
              title="Pendapatan Hari ini"
            />
          </SimpleGrid>
        </div>
      </div>
      <div className="flex justify-between p-5 ">
        <div className="flex flex-row gap-3 basis-3/5">
          <div className="relative w-full basis-4/5">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <IcMagnifying />
            </div>
            <Input
              type="text"
              placeholder="Pencarian nama kelompok, pelanggan"
              onChange={(e) => setQuery(e.target.value)}
              className="ps-10"
            />
          </div>
          <Datepicker
            useRange={false}
            asSingle={true}
            displayFormat="DD MMM YYYY"
            popoverDirection="down"
            inputClassName={
              "bg-inherit  text-black w-full border-inherit rounded-lg"
            }
            placeholder="search reservasi"
            value={paramDate}
            onChange={handleDateChange}
            containerClassName="relative w-full bg-white basis-2/5"
            readOnly={true}
          />
        </div>
        <div className="flex justify-end gap-1 basis-1/5">
          <Link to={"/helpdesk/pembelian-tiket"}>
            <button
              type="button"
              className="flex w-full h-full gap-1 items-center px-5 border rounded-lg font-semibold bg-white border-tropicGreen500 text-tropicGreen500"
            >
              Beli tiket baru
            </button>
          </Link>
        </div>
      </div>
      {momentStartDate.isSameOrAfter(currentDate, "day") && (
        <Alert colorScheme="yellow">
          <AlertDescription className="bg-yellow-200 text-sm text-gray-700 rounded-md p-2">
            {`Estimasi tiket pada ${dateFormat(momentStartDate)}:`}{" "}
            {dataFnb.map((fnb, idx) => (
              <Fragment key={idx}>
                <span className="font-extrabold capitalize mr-1">
                  {`${fnb.sku_name} ${fnb.total} ${
                    idx < dataFnb.length - 1 ? "|" : ""
                  }`}
                </span>
              </Fragment>
            ))}
          </AlertDescription>
        </Alert>
      )}
      <div id="content-table">
        <DataTable columns={ColumnsDashboard} table={table} loading={loading} />
      </div>
      <HdModal
        onToggleModal={onToggleModalDetail}
        isOpen={isOpen}
        data={modalDetail}
      />
      <HdModalForm
        onToggleModal={onToggleModalForm}
        isOpen={formIsOpen}
        data={modalForm}
        refetch={handleRefetch}
      />
      <ModalConfirmation
        title="Checkout"
        desc={`Apakah anda yakin Pelanggan dengan kode booking <mark className="bg-yellow-200 text-gray-700">${selectCheckout?.reservation_id}</mark> ini akan di checkout?`}
        onToggleModal={onToggleModalCheckout}
        isOpen={checkoutIsOpen}
        buttonSubmit={
          <Button
            id="pay"
            type="button"
            colorScheme="bg-green-600"
            size="md"
            className="!text-base !font-semibold text-white bg-green-600"
            onClick={onSubmitCheckout}
          >
            {checkoutLoading ? "Loading..." : "Submit"}
          </Button>
        }
      />
      <ModalOpenClosing
        onToggleModal={onToggleModalOpenClosing}
        isOpen={isOpenClock}
        data={getShift}
        shiftRefetch={refetchShift}
        isClockIn={getShift?.shift_detail?.id !== ""}
      />

      {!printInvoiceLoading && printInvoiceData && (
        <div style={{ display: "none" }}>
          <Invoice ref={printInvoiceRef} {...invoiceData} />
        </div>
      )}

      <div ref={printRef} style={{ display: "none" }}></div>
    </div>
  );
}

export default DashboardHelpdesk;
