import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import CardStack from "../../components/ui/CardStack";
import { numberWithoutIDR } from "../../lib/utils";

const InfoHeader = ({ infoData = [], infoFormat }) => {
  return (
    <Flex
      zIndex={"-1"}
      spacing={5}
      direction={"row"}
      my={4}
      mx={4}
      gap={2}
      flexWrap={"wrap"}
      align={"stretch"}
    >
      {infoData?.map((item, idx) => {
        return (
          <Box key={idx} flex="1" minW={"20%"} className="">
            <CardStack
              bodyClassName={`${
                infoFormat.bgColor
                  ? infoFormat?.bgColor[item?.title?.toLowerCase()] ||
                    "bg-gray-200"
                  : "bg-gray-200"
              } w-full z-1`}
              footerClassName="bg-green-600"
              bodyData={numberWithoutIDR(item.amount)}
              footerData={[]}
              title={
                infoFormat?.title
                  ? infoFormat?.title[item?.title?.toLowerCase()] || item.title
                  : item.title
              }
            />
          </Box>
        );
      })}
    </Flex>
  );
};

export default InfoHeader;
