import React, { useEffect } from "react";
import {
  AspectRatio,
  Box,
  Button,
  Grid,
  GridItem,
  Image,
  Input,
  InputGroup,
  useToast,
  InputLeftAddon,
  FormLabel,
  IconButton,
  CloseButton,
} from "@chakra-ui/react";
import _ from "lodash";

import { Controller, useForm } from "react-hook-form";
import skeletonImage from "../../assets/skeleteon-img.svg";
import CakraInput from "../../components/CakraInput";
import { useApiPost } from "../../hooks/useApiPost";
import toastHandler from "../../components/toastHandler";
import { useAuth } from "../../hooks/useAuth";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";
import { AsyncSelect } from "chakra-react-select";
import { useFetchAddress } from "../../hooks/useFetchAddress";
import { convertToBase64 } from "../../lib/utils";

const RegisterTa = () => {
  const [imgFile, setImgFile] = React.useState();
  const [imgPreview, setImgPreview] = React.useState(null);
  const [documentFiles, setDocumentFiles] = React.useState([]);
  const [documentFilePreviews, setDocumentFilePreviews] = React.useState([]);
  const [provinceOptions, setProvinceOptions] = React.useState([]);
  const [cityOptions, setCityOptions] = React.useState([]);
  const [districtOptions, setDistrictOptions] = React.useState([]);
  const [subDistrictOptions, setSubDistrictOptions] = React.useState([]);
  const {
    register,
    handleSubmit,
    reset,
    resetField,
    watch,
    control,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const {
    handlePost: submitPost,
    loading: submitLoading,
    data: submitData,
    error: submitError,
  } = useApiPost();
  const toast = useToast();
  const { loading: provinceLoading, data: provinceData } =
    useFetchDataDashboard("/api/provinces");
  const {
    loading: cityLoading,
    data: cityData,
    setParam: citySetParam,
  } = useFetchAddress("/api/cities", {
    province_code: "",
  });
  const {
    loading: districtLoading,
    data: districtData,
    setParam: districtSetParam,
  } = useFetchAddress("/api/districts", {
    city_code: "",
  });
  const {
    loading: subDistrictLoading,
    data: subDistrictData,
    setParam: subDistrictSetParam,
  } = useFetchAddress("/api/sub-districts", {
    district_code: "",
  });

  useEffect(() => {
    if (!submitLoading && submitData) {
      reset();
    }
    toastHandler({
      data: submitData,
      error: submitError,
      loading: submitLoading,
      toast: toast,
      title: "Travel agent berhasil di daftarkan tolong check email",
    });
  }, [submitData, submitLoading, submitError]);

  useEffect(() => {
    if (!provinceLoading && provinceData) {
      setProvinceOptions(
        provinceData.map((province) => ({
          value: province.code,
          label: province.name,
        }))
      );
    }
  }, [provinceLoading, provinceData]);

  useEffect(() => {
    if (!cityLoading && cityData) {
      setCityOptions(
        cityData.map((city) => ({ value: city.code, label: city.name }))
      );
    }
  }, [cityLoading, cityData]);

  useEffect(() => {
    if (!districtLoading && districtData) {
      setDistrictOptions(
        districtData.map((district) => ({
          value: district.code,
          label: district.name,
        }))
      );
    }
  }, [districtLoading, districtData]);

  useEffect(() => {
    if (!subDistrictLoading && subDistrictData) {
      setSubDistrictOptions(
        subDistrictData.map((subDistrict) => ({
          value: subDistrict.code,
          label: subDistrict.name,
        }))
      );
    }
  }, [subDistrictLoading, subDistrictData]);

  useEffect(() => {
    if (watch("province")) {
      const selectProvince = watch("province");
      citySetParam({ province_code: selectProvince.value });
    } else if (watch("province") === null) {
      resetField("city", { defaultValue: "" });
    }
  }, [watch("province")]);

  useEffect(() => {
    //district
    if (watch("city")) {
      const value = watch("city");
      districtSetParam({ city_code: value.value });
    } else if (watch("city") === null) {
      resetField("district", { defaultValue: "" });
    }
  }, [watch("city")]);

  useEffect(() => {
    //sub-district
    if (watch("district")) {
      const value = watch("district");
      subDistrictSetParam({ district_code: value.value });
    } else if (watch("district") === null) {
      resetField("subDistrict", { defaultValue: "" });
    }
  }, [watch("district")]);

  const { user } = useAuth();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImgFile(file);
    setImgPreview(URL.createObjectURL(file));
  };

  const handleDocumentChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setDocumentFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setDocumentFilePreviews((prevPreviews) => [...prevPreviews, ...newFiles]);
  };

  const handleRemoveDocument = (index) => {
    setDocumentFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setDocumentFilePreviews((prevPreviews) =>
      prevPreviews.filter((_, i) => i !== index)
    );
  };
  const onSubmit = async (data) => {
    const {
      travelName,
      email,
      phone,
      username,
      address,
      city,
      province,
      district,
      subDistrict,
      picName,
      picPhone,
    } = data;

    const docBase64 = await Promise.all(documentFiles.map(convertToBase64));

    const payload = {
      address,
      bank_name: "",
      bank_number: "",
      business_category: "string",
      email,
      name: travelName,
      office_phone_number: phone,
      // postal_code: postalCode,
      province: province ? province.label : "",
      city: city ? city.label : "",
      district: district ? district.label : "",
      subdistrict: subDistrict ? subDistrict.label : "",
      username,
      pic_name: picName,
      pic_phone_number: picPhone,
      documents: docBase64,
    };

    submitPost("/api/register-travel-agent", payload);
  };

  const isDisabledButton = () => {
    const watchAllFields = watch([
      "travelName",
      "email",
      "phone",
      "username",
      "picName",
      "picPhone",
    ]);
    const isDisabled =
      _.isEmpty(watchAllFields) ||
      _.values(watchAllFields).some(
        (value) => value === "" || value === undefined
      );

    return isDisabled;
  };

  const loadOptionsProvince = (inputValue, callback) => {
    if (!provinceData) return [];
    const data = provinceData
      .filter((province) =>
        province.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((province) => ({
        label: province.name,
        value: province.code,
      }));
    callback(data);
  };

  const loadOptionsCity = (inputValue, callback) => {
    if (!cityData) return [];
    const data = cityData
      .filter((city) =>
        city.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((city) => ({
        label: city.name,
        value: city.code,
      }));
    callback(data);
  };

  const loadOptionsDistrict = (inputValue, callback) => {
    if (!districtData) return [];
    const data = districtData
      .filter((district) =>
        district.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((district) => ({
        label: district.name,
        value: district.code,
      }));
    callback(data);
  };

  return (
    <div className="min-h-screen">
      <div className="border-b-2 flex justify-between px-5">
        <div className="py-5">
          <h1>Pendaftaran Travel Agent Baru</h1>
        </div>
      </div>
      <div className="p-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(3, 1fr)" gap={6}>
            <GridItem w="100%" colSpan={2} h="100%">
              {/* <CakraInput
                      label="Travel Agent Id"
                      value={"TravelAgent - 11233455"}
                      isDisabled
                    /> */}
              <CakraInput
                label="Nama Travel Agent"
                placeholder="Nama travel"
                {...register("travelName", { required: true })}
                isRequired
              />
              <CakraInput
                label="Alamat Email"
                placeholder="email"
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "isi email yang benar",
                  },
                })}
                isRequired
                error={errors.email}
              />
              <CakraInput
                label="No Telepon Kantor"
                placeholder="phone"
                {...register("phone", {
                  required: true,
                  pattern: {
                    value: /^(?:\+62|62|0)8[1-9][0-9]{6,11}$/,
                    message: "tolong isikan nomor hp yang benar",
                  },
                  minLength: {
                    value: 10,
                    message: "nomor hp harus lebih dari 10 digit",
                  },
                })}
                isRequired
                error={errors.phone}
              />
              {/* <CakraInput
                label="Kategori Bisnis"
                placeholder="pilih kategori bisnis"
                {...register("businessCategory")}
              /> */}
              <CakraInput
                label="Nama PIC"
                placeholder="nama pic"
                {...register("picName")}
                isRequired
              />
              <CakraInput
                label="No Telepon PIC"
                placeholder="phone pic"
                {...register("picPhone", {
                  required: true,
                  pattern: {
                    value: /^(?:\+62|62|0)8[1-9][0-9]{6,11}$/,
                    message: "tolong isikan nomor hp yang benar",
                  },
                  minLength: {
                    value: 10,
                    message: "nomor hp harus lebih dari 10 digit",
                  },
                })}
                isRequired
                error={errors.picPhone}
              />
              <CakraInput
                label="Username"
                placeholder="username"
                {...register("username")}
                isRequired
              />
            </GridItem>
            {/* <GridItem w="100%" h="100%">
              <AspectRatio width="64" ratio={1}>
                <Box
                  rounded="md"
                  shadow="sm"
                  role="group"
                  _hover={{
                    shadow: "md",
                  }}
                >
                  <Box position="relative" height="100%" width="100%">
                    <Box
                      position="absolute"
                      top="0"
                      left="0"
                      height="100%"
                      width="100%"
                      display="flex"
                      flexDirection="column"
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        width="100%"
                        position="relative"
                        className="bg-gray-100 mix-blend-multiply"
                      >
                        {imgPreview ? (
                          <Image
                            borderRadius="full"
                            boxSize="150px"
                            src={imgPreview}
                            alt="logo"
                          />
                        ) : (
                          <Image
                            borderRadius="full"
                            boxSize="150px"
                            src={skeletonImage}
                            alt="logo"
                          />
                        )}
                      </Box>
                    </Box>
                    <Input
                      type="file"
                      height="100%"
                      width="100%"
                      position="absolute"
                      top="0"
                      left="0"
                      opacity="0"
                      aria-hidden="true"
                      accept="image/*"
                      //   value={imgFile}
                      onChange={handleFileChange}
                      // onDragEnter={startAnimation}
                      // onDragLeave={stopAnimation}
                    />
                  </Box>
                </Box>
              </AspectRatio>
            </GridItem> */}
          </Grid>
          {/* alamat */}
          <Grid templateColumns="repeat(3, 1fr)" gap={6}>
            <GridItem w="100%" colSpan={2} h="100%">
              <CakraInput
                label="Alamat"
                placeholder="alamat"
                {...register("address")}
              />
              <CakraInput label="Provinsi">
                <Controller
                  name="province"
                  control={control}
                  render={({ field }) => (
                    <AsyncSelect
                      {...field}
                      isClearable
                      defaultOptions={provinceOptions}
                      cacheOptions
                      placeholder={"provinsi"}
                      loadOptions={loadOptionsProvince}
                      chakraStyles={{
                        input: (prev, { selectProps }) => ({
                          ...prev,
                          boxShadow: "none !important",
                        }),
                      }}
                    />
                  )}
                />
              </CakraInput>
              <CakraInput label="Kota">
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <AsyncSelect
                      {...field}
                      isClearable
                      defaultOptions={cityOptions}
                      cacheOptions
                      placeholder="kota"
                      loadOptions={loadOptionsCity}
                      chakraStyles={{
                        input: (prev, { selectProps }) => ({
                          ...prev,
                          boxShadow: "none !important",
                        }),
                      }}
                    />
                  )}
                />
              </CakraInput>
              <CakraInput label="Daerah">
                <Controller
                  name="district"
                  control={control}
                  render={({ field }) => (
                    <AsyncSelect
                      {...field}
                      isClearable
                      defaultOptions={districtOptions}
                      cacheOptions
                      placeholder="daerah"
                      loadOptions={loadOptionsDistrict}
                      chakraStyles={{
                        input: (prev, { selectProps }) => ({
                          ...prev,
                          boxShadow: "none !important",
                        }),
                      }}
                    />
                  )}
                />
              </CakraInput>
              <CakraInput label="Kecamatan">
                <Controller
                  name="subDistrict"
                  control={control}
                  render={({ field }) => (
                    <AsyncSelect
                      {...field}
                      isClearable
                      defaultOptions={subDistrictOptions}
                      cacheOptions
                      placeholder="kecamatan"
                      loadOptions={loadOptionsCity}
                      chakraStyles={{
                        input: (prev, { selectProps }) => ({
                          ...prev,
                          boxShadow: "none !important",
                        }),
                      }}
                    />
                  )}
                />
              </CakraInput>
              {/* <CakraInput
                label="Kode pos"
                placeholder="kode pos"
                {...register("postalCode")}
              /> */}
              <CakraInput
                label="Sales register"
                placeholder="sales yang medaftarkan agent"
                {...register("salesRegister")}
                value={user.name}
                isDisabled
              />
              <CakraInput label="Dokumen Legalitas">
                <Box
                  rounded="md"
                  shadow="sm"
                  role="group"
                  _hover={{
                    shadow: "none",
                  }}
                >
                  <Box position="relative" height="100%" width="100%">
                    <InputGroup className="items-end">
                      <InputLeftAddon className="!bg-blue-500 text-white">
                        pilih document
                      </InputLeftAddon>
                      <Input
                        type="file"
                        height="100%"
                        width="100%"
                        position="absolute"
                        top="0"
                        left="0"
                        opacity="0"
                        className="border "
                        aria-hidden="true"
                        multiple
                        onChange={handleDocumentChange}
                        // onDragEnter={startAnimation}
                        // onDragLeave={stopAnimation}
                      />
                    </InputGroup>
                    {documentFilePreviews.length > 0 && (
                      <Box mt={4} justifyContent={"center"}>
                        {documentFilePreviews.map((doc, index) => (
                          <Box display="flex" key={index}>
                            <a
                              href={URL.createObjectURL(doc)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {doc.name}
                            </a>
                            <CloseButton
                              ml={2}
                              size="sm"
                              colorScheme="red"
                              onClick={() => handleRemoveDocument(index)}
                            />
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
              </CakraInput>
            </GridItem>
          </Grid>

          <Button
            isDisabled={isDisabledButton()}
            type="submit"
            colorScheme="green"
            mt="1.5rem"
          >
            Daftar Sekarang
          </Button>
        </form>
      </div>
    </div>
  );
};

export default RegisterTa;
