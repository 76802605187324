import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import {
  dateFormat,
  numberToIDR,
  defineCheckInStatus,
  defineTaStatus,
  defineRateCardStatus,
  numberWithoutIDR,
  defineActivityStatus,
  defineDepositStatus,
  defineStatus,
} from "../../lib/utils";
import { Link } from "react-router-dom";
import React from "react";
import { EllipsisVertical } from "lucide-react";
import {
  CANCELLED,
  PAYMENT_VERIFIED,
  REFUNDED,
  SETTLEMENT,
  SKU_PREMIUM,
  WAITING_FOR_PAYMENT,
} from "../../lib/constant";

export const ColumnsDashboard = [
  {
    accessorKey: "reservation_id",
    header: "Kode Booking",
    cell: ({ row, table }) => {
      const item = row.original;
      return (
        <>
          <button
            onClick={() => {
              table.options.onToggleModalDetail({ row: item });
            }}
            className="bg-transparent border-none text-left"
          >
            <div className="text-systemAzure">
              {row.getValue("reservation_id")}
            </div>
            <p>{`check in: ${dateFormat(item.last_checkin_at)}`}</p>
          </button>
        </>
      );
    },
  },
  {
    accessorKey: "group_name",
    header: "nama group umroh",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <>
          <div>{row.getValue("group_name")}</div>
          <p>{item.travel_agent_name}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tour_leader_name",
    header: "Tour Leader",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <>
          <div>{item.tour_leader_name}</div>
          <p className="text-sm">{item.phone_number}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tickets",
    header: "Total Tiket",
    cell: ({ row }) => {
      const tiket = row.getValue("tickets");
      return (
        <>
          <div>{tiket.total}</div>
          <div>
            <p className="text-sm">
              {tiket.vip +
                " " +
                SKU_PREMIUM +
                ", " +
                tiket.regular +
                " Regular"}
            </p>
            {/* <p className="text-sm">{}</p> */}
          </div>
        </>
      );
    },
  },
  {
    accessorKey: "status_checkin",
    header: "Status",
    cell: ({ row }) => {
      const items = row.original;
      const status = row.getValue("status_checkin");
      return (
        <div className="flex flex-col gap-1">
          {defineCheckInStatus(status)}
          {defineStatus(items.status)}
        </div>
      );
    },
  },
  {
    accessorKey: "action",
    header: "",
    cell: ({ row, table }) => {
      const items = row.original;
      const menuItems = [];

      if (
        items.payment_midtrans_token &&
        items.payment_status.status !== SETTLEMENT
      ) {
        menuItems.push(
          <MenuItem
            key={`menu-${menuItems.length + 1}`}
            onClick={() => {
              table.options.handleSnapShow(items.payment_midtrans_token);
            }}
            closeOnSelect={true}
          >
            Lanjut Pembayaran
          </MenuItem>
        );
      }

      if (items.status === WAITING_FOR_PAYMENT) {
        menuItems.push(
          <MenuItem
            key={`menu-${menuItems.length + 1}`}
            onClick={() => {
              table.options.onToggleModalChangeStatus({
                reservationId: items.reservation_id,
                typeChangeStatus: CANCELLED,
              });
            }}
            closeOnSelect={true}
          >
            Cancel Transaksi
          </MenuItem>
        );
      }

      if (items.status === PAYMENT_VERIFIED) {
        menuItems.push(
          <MenuItem
            key={`menu-${menuItems.length + 1}`}
            onClick={() => {
              table.options.onToggleModalChangeStatus({
                reservationId: items.reservation_id,
                typeChangeStatus: REFUNDED,
              });
            }}
            closeOnSelect={true}
          >
            Refund Transaksi
          </MenuItem>
        );
      }

      return (
        <Menu>
          <MenuButton
            as={Button}
            size={"xs"}
            bgColor="transparent"
            isDisabled={menuItems.length <= 0}
          >
            <EllipsisVertical size={18} />
          </MenuButton>
          <MenuList fontWeight={500}>{menuItems}</MenuList>
        </Menu>
      );
    },
  },
];

export const ColumnsSalesTravelAgent = [
  {
    accessorKey: "name",
    header: "Nama",
    cell: ({ row, table }) => {
      const item = row.original;
      return (
        <>
          <Link to={`detail/${item.travel_agent_id || "details"}`}>
            <div className="text-systemAzure">{row.getValue("name")}</div>
          </Link>
        </>
      );
    },
  },
  {
    accessorKey: "office_phone_number",
    header: "Kontak",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <>
          <div>{row.getValue("office_phone_number")}</div>
          <p className="normal-case">{item.email}</p>
        </>
      );
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const status = row.getValue("status");
      return defineTaStatus(status);
    },
  },
  {
    accessorKey: "sales_name",
    header: "Registrator",
    cell: ({ row }) => {
      const item = row.getValue("sales_name");
      return (
        <>
          <div>{item}</div>
        </>
      );
    },
  },
  {
    accessorKey: "balance",
    header: "Balance Saldo",
    cell: ({ row }) => {
      const balance = row.getValue("balance");
      return <>{numberToIDR(balance)}</>;
    },
  },
  {
    accessorKey: "action",
    header: "",
    cell: ({ row, table }) => {
      const selectRow = row.original;

      return (
        <Button
          onClick={() => {
            // sendupdate api
            table.options.onToggleModalForm({ row: selectRow });
          }}
          variant="solid"
          size="xs"
          colorScheme={selectRow.status !== "DEACTIVE" ? "green" : "gray"}
          // isDisabled={selectRow.status === "DEACTIVE"}
          isDisabled={true}
        >
          Top up deposit
        </Button>
      );
    },
  },
];

export const ColumnsSalesRateCard = [
  {
    accessorKey: "id",
    header: "ID Rate Code",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <>
          <h3 className="font-normal">{row.getValue("id")}</h3>
          <p className="normal-case">
            {"Dibuat: " + dateFormat(item.created_at)}
          </p>
        </>
      );
    },
  },
  {
    accessorKey: "name",
    header: "Nama",
    cell: ({ row, table }) => {
      const item = row.original;
      return (
        <>
          <button
            onClick={() => {
              table.options.onToggleModalDetail({ row: item });
            }}
            className="bg-transparent border-none"
          >
            <div className="text-systemAzure">{row.getValue("name")}</div>
          </button>
        </>
      );
    },
  },
  {
    accessorKey: "discount_percentage",
    header: "Persentase diskon",
    cell: ({ row }) => {
      const item = row.getValue("discount_percentage");
      return (
        <>
          <div>{item}</div>
          {/* <p className="text-sm">{item.phone_number}</p> */}
        </>
      );
    },
  },
  {
    accessorKey: "registrator",
    header: "Registrator",
    cell: ({ row }) => {
      const item = row.getValue("registrator");
      return (
        <>
          <div>{item}</div>
        </>
      );
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const status = row.getValue("status");
      return defineRateCardStatus(status);
    },
  },
  //   {
  //     accessorKey: "action",
  //     header: "",
  //     cell: ({ row, table }) => {
  //       const selectRow = row.original;

  //       return (
  //         <Button
  //           onClick={() => {
  //             // sendupdate api
  //             table.options.onToggleModalForm({ row: selectRow });
  //           }}
  //           variant="solid"
  //           size="xs"
  //           colorScheme={selectRow.status !== "DEACTIVE" ? "green" : "gray"}
  //           isDisabled={selectRow.status === "DEACTIVE"}
  //         >
  //           Top up deposit
  //         </Button>
  //       );
  //     },
  //   },
];

export const ColumnsLogTransaksi = [
  {
    accessorKey: "reservation_id",
    header: "ID Order",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <>
          <h3 className="font-normal">{row.getValue("reservation_id")}</h3>
          <p className="normal-case">{dateFormat(item.created_at)}</p>
        </>
      );
    },
  },
  {
    accessorKey: "use_date",
    header: "Tanggal penggunaan",
    cell: ({ row }) => {
      const item = row.getValue("use_date");
      return (
        <>
          <div>{dateFormat(item)}</div>
          <p>Terminal 2F</p>
        </>
      );
    },
  },
  {
    accessorKey: "tour_leader_name",
    header: "Tour Leader",
    cell: ({ row }) => {
      const item = row.getValue("tour_leader_name");
      const items = row.original;
      return (
        <>
          <div>{item}</div>
          <p>{items.phone_number || "-"}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tickets",
    header: "Total pax",
    cell: ({ row }) => {
      const item = row.getValue("tickets");
      return (
        <>
          <div>{item?.total || "0"}</div>
        </>
      );
    },
  },
  {
    accessorKey: "discount",
    header: "Discount",
    cell: ({ row }) => {
      const item = row.getValue("discount");
      return (
        <>
          <div className="text-right">{numberToIDR(item || 0)}</div>
        </>
      );
    },
  },
  {
    accessorKey: "total_purchase",
    header: "Total Pembelian",
    cell: ({ row }) => {
      const item = row.getValue("total_purchase");
      return (
        <>
          <div className="text-right">{numberToIDR(item || 0)}</div>
        </>
      );
    },
  },
  {
    accessorKey: "grand_total",
    header: "Harga final",
    cell: ({ row }) => {
      const item = row.getValue("grand_total");
      return (
        <>
          <div className="text-right">{numberToIDR(item || 0)}</div>
        </>
      );
    },
  },
];

export const ColumnsLogLedger = [
  {
    accessorKey: "id",
    header: "ID Transaksi",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <div className="max-w-52">
          <h3 className="font-normal">{row.getValue("id")}</h3>
          <p className="normal-case">{dateFormat(item.created_at)}</p>
        </div>
      );
    },
  },
  {
    accessorKey: "description",
    header: "Deskripsi Transaksi",
    cell: ({ row }) => {
      const desc = row.getValue("description");
      return <div className="max-w-64 text-wrap">{desc}</div>;
    },
  },
  {
    accessorKey: "debit",
    header: "Debit",
    cell: ({ row }) => {
      const debit = row.getValue("debit");
      return (
        <>
          <div className="text-red-600 text-right font-semibold">
            {debit ? numberToIDR(debit) : "-"}
          </div>
        </>
      );
    },
  },
  {
    accessorKey: "credit",
    header: "Kredit",
    cell: ({ row }) => {
      const credit = row.getValue("credit");
      return (
        <>
          <div className="text-green-600 text-right  font-semibold">
            {credit ? numberToIDR(credit) : "-"}
          </div>
        </>
      );
    },
  },
  {
    accessorKey: "amount",
    header: "Saldo",
    cell: ({ row }) => {
      const amount = row.getValue("amount");
      return <div className="text-right">{numberToIDR(amount)}</div>;
    },
  },
  {
    accessorKey: "pembenaran",
    header: "Pembenaran",
    cell: ({ row }) => {
      const item = row.getValue("pembenaran");
      return (
        <>
          <div>{item || 0}</div>
          {/* <p className="text-sm">{item.phone_number}</p> */}
        </>
      );
    },
  },
];

export const ColumnsLogDeposit = [
  {
    accessorKey: "id",
    header: "ID Deposit",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <div className="max-w-52">
          <h3 className="font-normal">{row.getValue("id")}</h3>
          <p className="normal-case">{dateFormat(item.created_at)}</p>
        </div>
      );
    },
  },
  {
    accessorKey: "description",
    header: "Deskripsi Deposit",
    cell: ({ row }) => {
      const desc = row.getValue("description");
      return <div className="max-w-44 text-wrap">{desc || "-"}</div>;
    },
  },
  {
    accessorKey: "amount",
    header: "Jumlah Deposit (IDR)",
    cell: ({ row }) => {
      const amount = row.getValue("amount");
      return <div className="text-right">{numberWithoutIDR(amount)}</div>;
    },
  },
  {
    accessorKey: "deposit_total",
    header: "Total Deposit (IDR)",
    cell: ({ row }) => {
      const item = row.getValue("deposit_total");
      return <div className="text-right">{numberWithoutIDR(item)}</div>;
    },
  },
  {
    accessorKey: "deposit_source",
    header: "Sumber Dana",
    cell: ({ row }) => {
      const item = row.getValue("deposit_source");
      return (
        <>
          <div>{item || "-"}</div>
        </>
      );
    },
  },
  {
    accessorKey: "pembenaran",
    header: "Pembenaran",
    cell: ({ row }) => {
      const item = row.getValue("pembenaran");
      return (
        <>
          <div>{item || "-"}</div>
        </>
      );
    },
  },
  {
    accessorKey: "sales",
    header: "Sales",
    cell: ({ row }) => {
      const item = row.getValue("sales");
      return <div className="normal-case max-w-28">{item || "-"}</div>;
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const item = row.getValue("status");
      return (
        <>
          <div>{defineDepositStatus(item)}</div>
          {/* <p className="text-sm">{item.phone_number}</p> */}
        </>
      );
    },
  },
];

export const ColumnsLogActivity = [
  {
    accessorKey: "id",
    header: "ID Activity",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <>
          <h3 className="font-normal">{row.getValue("id")}</h3>
          <p className="normal-case">{dateFormat(item.created_at)}</p>
        </>
      );
    },
  },
  {
    accessorFn: (row) => row.skus,
    id: "sku_name",
    header: "Nama Paket",
    cell: (info) => {
      const skus = info.getValue();
      return skus.map((sku, idx) => (
        <div className="mb-1" key={idx}>
          {sku.sku_name}
        </div>
      ));
    },
  },
  {
    accessorFn: (row) => row.skus,
    id: "price",
    header: "Price",
    cell: (info) => {
      const skus = info.getValue();
      return skus.map((sku, idx) => (
        <div className="text-right">{numberWithoutIDR(sku.price)}</div>
      ));
    },
  },
  {
    accessorKey: "created_by",
    header: "Registrator",
    cell: ({ row }) => {
      const item = row.getValue("created_by");
      return <div className="normal-case">{item || "-"}</div>;
    },
  },
  {
    accessorKey: "approval",
    header: "Approval",
    cell: ({ row }) => {
      const item = row.getValue("approval");
      return <div className="normal-case">{item || "-"}</div>;
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const item = row.getValue("status");
      return (
        <>
          <div>{defineActivityStatus(item)}</div>
          {/* <p className="text-sm">{item.phone_number}</p> */}
        </>
      );
    },
  },
];

// export const ColumnsLogActivity = [
//   {
//     accessorKey: "id",
//     header: "ID Activity",
//     columns: [
//       {
//         id: "id",
//         cell: ({ row }) => {
//           const item = row.original;
//           return (
//             <>
//               <h3 className="font-normal">{item.id}</h3>
//               <p className="normal-case">{dateFormat(item.created_at)}</p>
//             </>
//           );
//         },
//       },
//     ],
//   },
//   {
//     accessorKey: "skus",
//     header: () => <div className="text-center">Paket</div>,
//     columns: [
//       {
//         accessorFn: (row) => row.skus,
//         id: "sku_name",
//         header: "Nama Paket",
//         cell: (info) => {
//           const skus = info.getValue();
//           return skus.map((sku, idx) => (
//             <div className="mb-1" key={idx}>
//               {sku.sku_name}
//             </div>
//           ));
//         },
//       },
//       {
//         accessorFn: (row) => row.skus,
//         id: "price",
//         header: "Harga",
//         cell: (info) => {
//           const skus = info.getValue();
//           return skus.map((sku, idx) => (
//             <div key={idx} className="text-right mb-1">
//               {numberWithoutIDR(sku.price)}
//             </div>
//           ));
//         },
//       },
//     ],
//   },
//   {
//     accessorKey: "created_by",
//     header: "Registrator",
//     columns: [
//       {
//         id: "registrator",
//         cell: ({ row }) => {
//           const item = row.original;
//           return <div className="normal-case">{item.created_by || "-"}</div>;
//         },
//       },
//     ],
//   },
//   {
//     accessorKey: "status",
//     header: "Status",
//     columns: [
//       {
//         id: "status",
//         cell: ({ row }) => {
//           const item = row.original;
//           return (
//             <>
//               <div>{defineActivityStatus(item.status)}</div>
//             </>
//           );
//         },
//       },
//     ],
//   },
// ];
