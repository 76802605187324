import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React from "react";
import { cn } from "../lib/utils";

const CakraInput = React.forwardRef(
  (
    {
      label,
      labelHelp,
      children = null,
      controlClassName = "",
      labelClassName = "",
      error = null,
      isRequired = false,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl
        isRequired={isRequired}
        isInvalid={error}
        className={cn("", controlClassName)}
      >
        <FormLabel className={cn("", labelClassName)}>{label}</FormLabel>
        {children ? children : <Input ref={ref} {...props} />}
        {/* <FormHelperText>{labelHelp || ""}</FormHelperText> */}
        {error === null ? (
          <FormHelperText>{labelHelp || ""}</FormHelperText>
        ) : (
          <FormErrorMessage>{(error && error.message) || ""}</FormErrorMessage>
        )}
      </FormControl>
    );
  }
);

export default CakraInput;
