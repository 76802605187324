import React, { useEffect, useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import _ from "lodash";

import { CardDetail } from "../sales/CardDetail";
import { dateFormat, numberToIDR } from "../../lib/utils";
import skeletonImage from "../../assets/skeleteon-img.svg";
import LogTable from "../sales/LogTable";
import {
  ColumnsLogActivity,
  ColumnsLogDeposit,
  ColumnsLogLedger,
  ColumnsLogTransaksi,
} from "../sales/Columns";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";
import SearchBar from "../../components/SearchBar";

const LIMIT = 5;

const TaPartnership = () => {
  const [transactionQuery, setTransactionQuery] = useState();
  const [transactionDate, setTransactionDate] = useState({
    startDate: null,
    endDate: null,
  });

  const [transactionParams, setTransactionParams] = useState({
    limit: LIMIT,
    query: transactionQuery,
    start_date: transactionDate.startDate
      ? dateFormat(transactionDate.startDate)
      : "",
    end_date: transactionDate.endDate
      ? dateFormat(transactionDate.endDate)
      : "",
  });

  const [ledgerQuery, setLedgerQuery] = useState();
  const [ledgerDate, setLedgerDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [ledgerParams, setLedgerParams] = useState({
    limit: LIMIT,
    query: ledgerQuery,
    start_date: ledgerDate.startDate ? dateFormat(ledgerDate.startDate) : "",
    end_date: ledgerDate.endDate ? dateFormat(ledgerDate.endDate) : "",
  });

  const [depositQuery, setDepositQuery] = useState();
  const [depositDate, setDepositDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [depositParams, setDepositParams] = useState({
    limit: LIMIT,
    query: depositQuery,
    start_date: depositDate.startDate ? dateFormat(depositDate.startDate) : "",
    end_date: depositDate.endDate ? dateFormat(depositDate.endDate) : "",
  });

  const [activityQuery, setActivityQuery] = useState();
  const [activityDate, setActivityDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [activityParams, setActivityParams] = useState({
    limit: LIMIT,
    query: activityQuery,
    start_date: activityDate.startDate
      ? dateFormat(activityDate.startDate)
      : "",
    end_date: activityDate.endDate ? dateFormat(activityDate.endDate) : "",
  });

  useEffect(() => {
    setTransactionParams({
      limit: LIMIT,
      query: transactionQuery,
      start_date: transactionDate.startDate
        ? dateFormat(transactionDate.startDate)
        : "",
      end_date: transactionDate.endDate
        ? dateFormat(transactionDate.endDate)
        : "",
    });
  }, [transactionQuery, transactionDate]);

  useEffect(() => {
    setLedgerParams({
      limit: LIMIT,
      query: ledgerQuery,
      start_date: ledgerDate.startDate ? dateFormat(ledgerDate.startDate) : "",
      end_date: ledgerDate.endDate ? dateFormat(ledgerDate.endDate) : "",
    });
  }, [ledgerQuery, ledgerDate]);

  useEffect(() => {
    setDepositParams({
      limit: LIMIT,
      query: depositQuery,
      start_date: depositDate.startDate
        ? dateFormat(depositDate.startDate)
        : "",
      end_date: depositDate.endDate ? dateFormat(depositDate.endDate) : "",
    });
  }, [depositQuery, depositDate]);

  useEffect(() => {
    setActivityParams({
      limit: LIMIT,
      query: activityQuery,
      start_date: activityDate.startDate
        ? dateFormat(activityDate.startDate)
        : "",
      end_date: activityDate.endDate ? dateFormat(activityDate.endDate) : "",
    });
  }, [activityQuery, activityDate]);

  const {
    loading: partnershipLoading,
    data: partnershipData,
    error: partnershipError,
    refetch,
  } = useFetchDataDashboard("/api/partnership");

  const handleTransaksiDateChange = (date) => {
    setTransactionDate(date);
  };

  const handleLedgerDateChange = (date) => {
    setLedgerDate(date);
  };

  const handleDepositDateChange = (date) => {
    setDepositDate(date);
  };

  const handleActivityDateChange = (date) => {
    setActivityDate(date);
  };

  return (
    <div className="min-h-screen">
      <div className="border-b-2 flex justify-between px-5">
        <div className="py-5">
          <h1>Partnership</h1>
        </div>
      </div>
      <div className="p-5 px-0">
        <div className="flex flex-row px-5 gap-2 mb-4">
          <CardDetail
            title={!partnershipLoading && partnershipData?.data[0]?.name}
            badge={{
              label: !partnershipLoading && partnershipData?.data[0].rate_card,
              colorScheme: "gray",
            }}
            img={skeletonImage}
          />

          <CardDetail
            title={
              <>
                {`Saldo:`}{" "}
                <span className="text-base font-normal">
                  {!partnershipLoading &&
                    numberToIDR(partnershipData?.data[0].balance || 0)}
                </span>
              </>
            }
          />
          <Alert status="info" rounded={"lg"} size={"md"} className="basis-1/2">
            <AlertIcon />
            Untuk top up saldo deposit dan harga rate code, silahkan hubungi
            sales Anda.
          </Alert>
        </div>
        <Tabs px={0} isLazy>
          <TabList px={5}>
            <Tab>Log Transaksi</Tab>
            <Tab>Log Ledger</Tab>
            <Tab>Log Deposit</Tab>
            <Tab>Rate Code Activity</Tab>
          </TabList>

          <TabPanels>
            <TabPanel p={0} py={2}>
              <div className="flex w-full justify-between my-3 px-5">
                <SearchBar
                  query={transactionQuery}
                  setQuery={setTransactionQuery}
                  paramDate={transactionDate}
                  handleDateChange={handleTransaksiDateChange}
                  isSingleDate={false}
                  outerClassName="basis-4/5"
                  innerClassName="basis-3/5"
                  searchPlaceholder="Cari Transaksi"
                  datepickerPlaceholder="Cari Date Transaksi"
                />
              </div>
              <LogTable
                api="/api/report-log-transactions"
                columns={ColumnsLogTransaksi}
                params={transactionParams}
              />
            </TabPanel>
            <TabPanel p={0} py={2}>
              <div className="flex w-full justify-between my-3 px-5">
                <SearchBar
                  query={ledgerQuery}
                  setQuery={setLedgerQuery}
                  paramDate={ledgerDate}
                  handleDateChange={handleLedgerDateChange}
                  isSingleDate={false}
                  outerClassName="basis-4/5"
                  innerClassName="basis-3/5"
                  searchPlaceholder="Cari Ledger"
                  datepickerPlaceholder="Cari Date Ledger"
                />
              </div>
              <LogTable
                api="/api/log-ledger"
                columns={ColumnsLogLedger}
                params={ledgerParams}
              />
            </TabPanel>
            <TabPanel p={0} py={2}>
              <div className="flex w-full justify-between my-3 px-5">
                <SearchBar
                  query={depositQuery}
                  setQuery={setDepositQuery}
                  paramDate={depositDate}
                  handleDateChange={handleDepositDateChange}
                  isSingleDate={false}
                  outerClassName="basis-4/5"
                  innerClassName="basis-3/5"
                  searchPlaceholder="Cari Deposit"
                  datepickerPlaceholder="Cari Date Deposit"
                />
              </div>
              <LogTable
                api="/api/log-deposit"
                columns={ColumnsLogDeposit}
                params={depositParams}
              />
            </TabPanel>
            <TabPanel p={0} py={2}>
              <div className="flex w-full justify-between my-3 px-5">
                <SearchBar
                  query={activityQuery}
                  setQuery={setActivityQuery}
                  paramDate={activityDate}
                  handleDateChange={handleActivityDateChange}
                  isSingleDate={false}
                  outerClassName="basis-4/5"
                  innerClassName="basis-3/5"
                  searchPlaceholder="Cari Activity"
                  withDate={false}
                />
              </div>
              <LogTable
                api="/api/rate-card-activity"
                columns={ColumnsLogActivity}
                params={depositParams}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default TaPartnership;
