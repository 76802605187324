import PropTypes from "prop-types";
import {
  Badge,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from "@chakra-ui/react";
import { dateFormat, defineNoteStatus } from "../../lib/utils";
import { EllipsisVertical } from "lucide-react";
import React from "react";
import { SETTLEMENT, SKU_PREMIUM } from "../../lib/constant";

const defineCheckInStatus = (status) => {
  let colorStyle = {
    COMPLETED: "green",
    FAILED: "default",
  };

  if (status && status.check_in === 0) {
    return (
      <Badge
        width="fit-content"
        fontWeight={"medium"}
        fontSize={"sm"}
        variant={"subtle"}
        colorScheme={colorStyle["FAILED"]}
      >
        {`Belum check in`}
      </Badge>
    );
  }

  if (status && status.check_in > 0) {
    return (
      <Badge
        width="fit-content"
        fontWeight={"medium"}
        fontSize={"sm"}
        variant={"subtle"}
        colorScheme={colorStyle["COMPLETED"]}
      >
        {`Check in: ${status.check_in}`}
      </Badge>
    );
  }

  return (
    <Badge
      width="fit-content"
      fontStyle={"Inter"}
      fontSize={"sm"}
      fontWeight={"medium"}
      variant={"subtle"}
      colorScheme={"red"}
    >
      Terjadi Kesalahan
    </Badge>
  );
};

const definePaymentBadgeStatus = (status) => {
  let stat = {
    INITIATED: "Menunggu Pembayaran",
    COMPLETED: "Pembayaran Terverifikasi",
    SETTLEMENT: "Pembayaran Terverifikasi",
    PENDING: "Menunggu Pembayaran",
    FAILED: "Dibatalkan",
    REFUNDED: "Selesai dengan Refund",
    ERROR: "Terjadi kesalahan",
  };
  let colorStyle = {
    INITIATED: "purple",
    COMPLETED: "green",
    SETTLEMENT: "blue",
    PENDING: "blue",
    FAILED: "red",
    REFUNDED: "Default",
    ERROR: "red",
  };
  return (
    (
      <Badge
        width="fit-content"
        fontWeight="medium"
        fontSize="sm"
        variant="subtle"
        colorScheme={colorStyle[status]}
      >
        {stat[status]}
      </Badge>
    ) || (
      <Badge
        width="fit-content"
        fontStyle={"Inter"}
        fontSize={"sm"}
        fontWeight={"medium"}
        variant={"subtle"}
        colorScheme={"red"}
      >
        Terjadi Kesalahan
      </Badge>
    )
  );
};

export const ColumnsDashboard = [
  {
    accessorKey: "reservation_id",
    header: "Kode Booking",
    cell: ({ row, table }) => {
      const item = row.original;
      return (
        <>
          <button
            onClick={() => {
              table.options.onToggleModalDetail({ row: item });
            }}
            className="bg-transparent border-none text-left"
          >
            <div className="text-systemAzure">
              {row.getValue("reservation_id")}
            </div>
            <p>{`check in: ${dateFormat(item.last_checkin_at)}`}</p>
          </button>
        </>
      );
    },
  },
  {
    accessorKey: "group_name",
    header: "nama group umroh",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <>
          <div>{row.getValue("group_name")}</div>
          <p>{item.travel_agent_name}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tour_leader_name",
    header: "Tour Leader",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <>
          <div>{item.tour_leader_name}</div>
          <p className="text-sm">{item.phone_number}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tickets",
    header: "Total Tiket",
    cell: ({ row }) => {
      const tiket = row.getValue("tickets");
      return (
        <>
          <div>{tiket.total}</div>
          <div>
            <p className="text-sm">
              {tiket.vip + " " + SKU_PREMIUM + "," + tiket.regular + " Regular"}
            </p>
            {/* <p className="text-sm">{}</p> */}
          </div>
        </>
      );
    },
  },
  {
    accessorKey: "status_checkin",
    header: "Status",
    cell: ({ row }) => {
      const status = row.getValue("status_checkin");
      return (
        <div className="flex flex-col gap-1">
          {defineCheckInStatus(status)}
          <Badge
            width="fit-content"
            fontWeight={"medium"}
            fontSize={"small"}
            variant={"subtle"}
            colorScheme={"red"}
          >
            {`Check out: ${status.check_out}`}
          </Badge>
        </div>
      );
    },
  },
  {
    accessorKey: "action",
    header: "",
    cell: ({ row, table }) => {
      const items = row.original;
      const status = row.getValue("status_checkin");

      // if (
      //   (status && status.completed) ||
      //   selectRow.payment_status.status !== "SETTLEMENT"
      // ) {
      //   return;
      // }

      // const items = row.original;
      const menuItems = [];

      menuItems.push(
        <React.Fragment key={menuItems.length + 1}>
          <MenuItem
            onClick={() => {
              table.options.onToggleModalForm({ row: items });
            }}
            closeOnSelect={true}
            isDisabled={
              (status && status.completed) ||
              items.payment_status.status !== SETTLEMENT
            }
          >
            Check in
          </MenuItem>
        </React.Fragment>
      );

      menuItems.push(
        <React.Fragment key={menuItems.length + 1}>
          <MenuItem
            onClick={() => {
              table.options.onToggleModalPrint({ row: items });
            }}
            closeOnSelect={true}
            isDisabled={items.payment_status.status !== SETTLEMENT}
          >
            Print
          </MenuItem>
        </React.Fragment>
      );

      menuItems.push(
        <React.Fragment key={menuItems.length + 1}>
          <MenuItem
            onClick={() => {
              table.options.onToggleModalPrintInvoice({ row: items });
            }}
            closeOnSelect={true}
          >
            Print Invoice
          </MenuItem>
        </React.Fragment>
      );

      menuItems.push(
        <React.Fragment key={menuItems.length + 1}>
          <MenuItem
            onClick={() => {
              table.options.onToggleModalCheckout({ row: items });
            }}
            closeOnSelect={true}
            isDisabled={
              items.status_checkin.check_in ===
                items.status_checkin.check_out ||
              items.status_checkin.check_in === 0
            }
          >
            Checkout
          </MenuItem>
        </React.Fragment>
      );

      return (
        <Menu>
          <MenuButton
            as={Button}
            size={"xs"}
            bgColor="transparent"
            // isDisabled={menuItems.length <= 0}
          >
            <EllipsisVertical size={18} />
          </MenuButton>
          <Portal>
            <MenuList fontWeight={500}>{menuItems}</MenuList>
          </Portal>
        </Menu>
      );
    },
  },
];

export const ColumnsReservationHd = [
  {
    accessorKey: "reservation_id",
    header: "Kode Booking",
    cell: ({ row, table }) => {
      const item = row.original;
      return (
        <>
          <button
            onClick={() => {
              table.options.onToggleModalDetail({ row: item });
            }}
            className="bg-transparent border-none text-left"
          >
            <div className="text-systemAzure">
              {row.getValue("reservation_id")}
            </div>
            <p>{`check in: ${dateFormat(item.last_checkin_at)}`}</p>
          </button>
        </>
      );
    },
  },
  {
    accessorKey: "group_name",
    header: "nama group umroh",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <>
          <div>{row.getValue("group_name")}</div>
          <p>{item.travel_agent_name}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tour_leader_name",
    header: "Tour Leader",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <>
          <div>{item.tour_leader_name}</div>
          <p className="text-sm">{item.phone_number}</p>
        </>
      );
    },
  },
  {
    accessorKey: "tickets",
    header: "Total Tiket",
    cell: ({ row }) => {
      const tiket = row.getValue("tickets");
      return (
        <>
          <div>{tiket.total}</div>
          <div>
            <p className="text-sm">
              {tiket.vip +
                " " +
                SKU_PREMIUM +
                ", " +
                tiket.regular +
                " Regular"}
            </p>
            {/* <p className="text-sm">{}</p> */}
          </div>
        </>
      );
    },
  },
  {
    accessorKey: "status_checkin",
    header: "Status",
    cell: ({ row }) => {
      const status = row.getValue("status_checkin");
      const items = row.original;
      return (
        <div className="flex flex-col gap-1">
          {definePaymentBadgeStatus(items.payment_status.status)}
          {defineCheckInStatus(status)}
        </div>
      );
    },
  },
];

export const ColumnsNotes = [
  {
    accessorKey: "created_by",
    header: "Dibuat oleh",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <>
          <div>{row.getValue("created_by")}</div>
          <p>{dateFormat(item.created_at)}</p>
        </>
      );
    },
  },
  {
    accessorKey: "category",
    header: "Kategori",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <>
          <div>{row.getValue("category")}</div>
          <p>{item.travel_agent_name}</p>
        </>
      );
    },
  },
  {
    accessorKey: "notes",
    header: "Keterangan lengkap",
    cell: ({ row }) => {
      const item = row.original;
      return <div>{item.notes}</div>;
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const status = row.getValue("status");
      return <div>{defineNoteStatus(status)}</div>;
    },
  },
  {
    accessorKey: "action",
    header: "",
    cell: ({ row, table }) => {
      const selectRow = row.original;

      return (
        <Button
          onClick={() => {
            // sendupdate api
            table.options.onToggleModalNote(selectRow);
          }}
          variant="solid"
          size="xs"
          colorScheme={selectRow.status !== "CLOSED" ? "green" : "gray"}
        >
          Buka
        </Button>
      );
    },
  },
];

// PropTypes for the columns array
ColumnsDashboard.propTypes = PropTypes.arrayOf(
  PropTypes.shape({
    accessorKey: PropTypes.string,
    header: PropTypes.string,
  })
).isRequired;
