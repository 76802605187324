import React, { Fragment, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  useToast,
} from "@chakra-ui/react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/Card";
import LabelWrapper from "../../components/ui/LabelWrapper";
import { useFetchDataDashboard } from "../../hooks/useFetchDataDashboard";
import { useApiPost } from "../../hooks/useApiPost";
import toastHandler from "../../components/toastHandler";
import CakraInput from "../../components/CakraInput";
import CakrauploadDocument from "../../components/CakrauploadDocument";
import useFileUpload from "../../hooks/useFileUpload";
import { SKU } from "../../lib/constant";
import { useForm } from "react-hook-form";
import { capitalizeWords, convertToBase64 } from "../../lib/utils";
import LoadingOverlay from "../../components/ui/LoadingOverlay";

const AssignRateCardModal = ({ onToggleModal, isOpen, data, refetch }) => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const toast = useToast();

  const {
    handlePost,
    loading: submitLoading,
    data: submitData,
    error: submitError,
  } = useApiPost();

  const {
    files: documentContract,
    previews: documentContractPreview,
    handleFileChange: handleDocumentChange,
    handleRemoveFile: handleRemoveDocument,
    resetFileChange,
  } = useFileUpload();

  useEffect(() => {
    if (data) {
      data.skus.map((sku) => {
        setValue(sku.sku_name.toLowerCase(), sku.price);
      });
    }
  }, []);

  useEffect(() => {
    if (!submitLoading && submitData) {
      onToggleModal();
      refetch();
      resetFileChange();
    }

    toastHandler({
      data: submitData,
      error: submitError,
      loading: submitLoading,
      toast: toast,
      title: "Assign Rate Code berhasil, menunggu Approval",
    });
  }, [submitData, submitLoading, submitError]);

  const onSubmit = async (dataForm) => {
    const skus = _.keys(dataForm).map((item) => {
      return {
        sku_name: capitalizeWords(item),
        price: parseInt(dataForm[item]),
      };
    });

    const base64Contracts = await Promise.all(
      documentContract.map(convertToBase64)
    );

    const payload = {
      travel_agent_id: data.travel_agent_id,
      skus: skus,
      contracts: base64Contracts,
    };

    handlePost("/api/register-rate", payload);
  };

  return (
    <>
      <Modal isOpen={isOpen} size={"xl"} onClose={onToggleModal}>
        <ModalOverlay />
        <ModalContent>
          <LoadingOverlay />
          <ModalCloseButton left={0} />
          <ModalHeader className="border-b-2 my-2"></ModalHeader>

          <ModalBody p={0}>
            <div className="flex flex-row justify-between px-2">
              <Card x-chunk="dashboard-07-chunk-3" className="basis-1/2">
                <CardHeader className="p-4 pb-3 text-lg font-bold">
                  <CardTitle className="flex items-center gap-2 text-lg">
                    Detail rate code sekarang
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-0">
                  <div className="flex flex-col sm:gap-3 p-4 pt-0 text-gray-500 ">
                    <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Travel Agent
                      <h3 className="font-normal text-gray-700 capitalize">
                        {data?.name || "-"}
                      </h3>
                    </LabelWrapper>
                    {/* <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Level Rate Code Sekarang
                      <h3 className="font-normal text-gray-700 capitalize">
                        {data?.rate_card}
                      </h3>
                    </LabelWrapper> */}
                    <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Sales yang Menangani
                      <h3 className="font-normal text-gray-700 capitalize">
                        {data?.sales_name}
                      </h3>
                    </LabelWrapper>
                  </div>
                  <div></div>
                </CardContent>
              </Card>
              {/* side content */}
              <Card
                x-chunk="dashboard-07-chunk-3"
                className="border-none shadow-none basis-1/2"
              >
                <CardHeader className="p-4 pb-3 text-lg font-bold">
                  <CardTitle className="flex items-center gap-2 text-lg">
                    Persetujuan rate code baru
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-0">
                  <div className="flex flex-col sm:gap-3 p-4 pt-0 text-gray-500 ">
                    <LabelWrapper className="flex-col uppercase text-sm font-bold">
                      Sales yang menangani
                      <h3 className="font-normal text-gray-700 capitalize">
                        {data?.sales_name || "-"}
                      </h3>
                    </LabelWrapper>
                    <LabelWrapper className="flex-col uppercase text-sm font-bold gap-1">
                      Harga Baru
                      {SKU.map((value, idx) => {
                        return (
                          <Fragment key={`input-${idx}`}>
                            <CakraInput
                              labelClassName="!text-xs !font-bold"
                              label={value.label}
                              error={errors[`${value.key}`]}
                            >
                              <NumberInput
                                min={0}
                                // value={numberWithoutIDR(
                                //   parseInt(watch(`${value.key}`))
                                // )}
                              >
                                <NumberInputField
                                  {...register(value.key, {
                                    required: true,
                                  })}
                                  onKeyDown={(e) => {
                                    if (e.key === "-" || e.key === "e") {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </NumberInput>
                            </CakraInput>
                          </Fragment>
                        );
                      })}
                    </LabelWrapper>
                    <CakrauploadDocument
                      label="Dokumen Kontrak"
                      buttonLabel="Upload file"
                      preview={documentContractPreview}
                      file={documentContract}
                      handleDocumentChange={handleDocumentChange}
                      handleRemoveDocument={handleRemoveDocument}
                      // accept=".pdf"
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
          </ModalBody>

          <ModalFooter justifyContent={"right"} px={"1rem"}>
            {/* <Button
              colorScheme="red"
              variant={"outline"}
              mr={3}
              size={"sm"}
              onClick={() => {}}
              isDisabled
            >
              Laporkan
            </Button> */}
            <Button
              colorScheme="green"
              variant="solid"
              size={"sm"}
              gap={"0.25rem"}
              onClick={handleSubmit(onSubmit)}
              isDisabled={submitLoading}
            >
              Ajukan rate code baru
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AssignRateCardModal;
